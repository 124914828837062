import _ from 'lodash';
import { DateTime } from 'luxon';
import { IProcess, IThingie } from 'src/app/model/thingie/thingie';
import { ITask } from '../model/thingie/task';
import { isNotUndefined } from './types';


/**
 * Information about the status of a thingie, process or task.
 *
 * Note: the `status` property does not contain the status keys
 * in ALL-CAPS!! (see {@link ThingieStatusHelper.getTasksStatus})
 */
export interface ThingieStatusInfo {
  hasError: boolean;
  errorMessages: string[];
  status?: string;
  color: string;
  description?: string;
}

/**
 * Helper class to determine the status of a thingie, process or task.
 */
export abstract class ThingieStatusHelper {
  static getThingieStatus(thingie: IThingie): ThingieStatusInfo {
    const tasks = thingie.processes
      .flatMap(process => process.tasks);
    return ThingieStatusHelper.getTasksStatus(tasks);
  }

  static getProcessStatus(process: IProcess): ThingieStatusInfo {
    return ThingieStatusHelper.getTasksStatus(process.tasks);
  }

  static getTaskStatus(task: ITask): ThingieStatusInfo {
    return ThingieStatusHelper.getTasksStatus([task]);
  }

  static getTasksStatus(tasks: ITask[]): ThingieStatusInfo {
    tasks = tasks.filter(task => !task.removed); // filtering out the removed ones

    const taskStates = _.uniq(tasks
      .filter(task => task.stateType !== 'offlineData')
      .map(task => task.currentState ?? 'NEW'));

    const errorMessages = _.uniq(tasks
      .filter(task => task.currentState === 'ERROR')
      .map(task => task.stateDescription)
      .filter(isNotUndefined));
    const errorIndex = taskStates.findIndex(state => state === 'ERROR');
    const hasError = errorIndex !== -1;
    if (hasError) {
      // Error tasks behave as finished
      taskStates.splice(errorIndex, 1);
      if (!taskStates.includes('STOPPED')) {
        taskStates.push('STOPPED');
      }
    }

    const triggerMessages = tasks
      .filter(task => task.currentState === 'RUNNING')
      .map(task => task.stateDescription)
      .filter(isNotUndefined);
    const triggerMessageSet = _.uniq(triggerMessages);

    const _getStatus = (): [string | undefined, string] => {
      if (taskStates.length === 0) {
        return ['No task', 'gray'];
      }
      if (taskStates.includes('BLINKING')) {
        if (triggerMessageSet.length === 1) {
          // condition reached, so keep lightred color
          return ['Blinking', 'lightred'];
        }
        return ['Blinking', 'orange'];
      }
      if (taskStates.includes('PAUSED')) {
        if (triggerMessageSet.length === 1) {
          // condition reached, so keep lightred color
          return ['Paused', 'lightred'];
        }
        return ['Paused', 'orange'];
      }
      if (taskStates.includes('STOPPED') && taskStates.length === 1) {
        return ['Finished', 'green'];
      }
      if (taskStates.includes('RUNNING')) {
        if (triggerMessageSet.length === 1) {
          // condition reached, so replace label
          return [triggerMessageSet[0], 'lightred'];
        }
        return ['Running', 'blue'];
      }
      if (taskStates.includes('UPLOAD_DONE')) {
        return ['Uploaded', 'indigo'];
      }
      if (taskStates.includes('PREPARE_DONE')) {
        return ['Prepared', 'pink'];
      }
      if (taskStates.includes('STOPPED')) {
        return ['Idle', 'gray'];
      }
      if (taskStates.includes('NEW') && taskStates.length === 1) {
        return ['Planned', 'azure'];
      }
      return ['Configuring', 'yellow'];
    };

    const [status, color] = _getStatus();

    let description: string | undefined;
    if (status === 'No task') {
      description = 'No tasks have been added.';
    }

    // maybe the custom status label is too long, so we show it as description to avoid loosing data
    if (taskStates.includes('RUNNING') || taskStates.includes('BLINKING') || taskStates.includes('PAUSED')) {
      if (triggerMessageSet.length === 1) {
        description = status;
      }
    }

    if (triggerMessageSet.length > 1) {
      description = _(triggerMessages)
        .countBy()
        .map((count, label) => `${label} (${count}x)`)
        .join('\n');
    }
    if (hasError === true) {
      description = `This object has encountered errors`;
      if (errorMessages.length > 0) {
        description += `. Click here to see details.`;
      }
    }

    return { hasError, status, color, description, errorMessages };
  }

  static hasRunningProcess(obj: IThingie) {
    return obj.processes.some(process => this.isRunningProcess(process));
  }

  static isRunningProcess(process: IProcess): boolean {
    // find out whether a started task is not yet stopped
    return process.tasks
      .filter(task => task.started !== undefined)
      .some(task => task.stopped === undefined || DateTime.fromISO(task.stopped) > DateTime.now());
  }

  /**
   * Check if a thingie, process or task has been started.
   *
   * @param entity
   * @returns `true` if the entity has been started, `false` otherwise.
   */
  static hasBeenStarted(entity: IThingie | IProcess | ITask): boolean {
    if ('processes' in entity) {
      const thingie = entity as IThingie;
      return thingie.processes.some(process => this.hasBeenStarted(process));
    } else if ('tasks' in  entity) {
      const process = entity as IProcess;

      return process.tasks.some(task => this.hasBeenStarted(task));
    } else {
      const task = entity as ITask;

      return !!task.targetState && task.targetState !== 'NEW'
        // Leaving this for defense
        || task.started !== undefined;

      // Note: Null-ish or empty string means 'NEW', too.
    }
  }
}
