<div id="menu-bar-notifications">
  <button
    mat-icon-button
    [matMenuTriggerFor]="notificationMenu"
    (menuOpened)="notificationMenuOpened()"
    (menuClosed)="notificationMenuClosed()"
  >
    <div
      [matBadgeHidden]="numberOfUnreadNotificationsCount === 0"
      [matBadge]="notificationsUnavailable ? '?' : numberOfUnreadNotificationsLabel"
      [matBadgeOverlap]="true"
      matBadgeColor="warn"
      [ngClass]="{'focused-button': isNotificationMenuOpened}"
      class="inline-block flex my-auto custom-badge align-text-bottom!"
    >
      <ion-icon
        name="notifications-outline"
        class="w-8 h-8 m-auto"
      ></ion-icon>
    </div>
  </button>

  <mat-menu #notificationMenu class="notification-list-menu">
    <ng-template matMenuContent>
      <div class="notification-list-menu__content" (click)="$event.stopPropagation()" #scrollingBlock>
        <div class="heading search-container">
          <span class="title">Notifications</span>
        </div>


        <div class="flex flex-row-wrap justify-between mt-4">
          <mat-slide-toggle
            [checked]="notificationFilterControl.value === 'UNREAD'"
            (change)="changeNotificationsFilter($event)">
            Only show unread
          </mat-slide-toggle>
          
          <a
            *ngIf="numberOfUnreadNotificationsCount !== 0"
            (click)="dismissAllNotifications()" class="dismiss-all-notifications ml-auto">
            Mark all as read
          </a>
        </div>

        <!-- Notification display -->
        <div *ngIf="notificationsUnavailable" class="notifications-unavailable">
          <i>Notifications could not be loaded.<br/><a href="#" (click)="reload($event)">Click to reload the page</a></i>
        </div>

        <!-- Notification card -->
        <div class="flex flex-col mb-1">
          <ng-container *ngIf="notificationsToDisplay$ | async as notifications">
            <ng-container *ngIf="notifications.length !== 0">
              <virtual-scroller #scroll 
              [items]="notifications"
              [enableUnequalChildrenSizes]="true"
              [bufferAmount]="20"
              (vsEnd)="fetchMoreNotifications($event)"
              [parentScroll]="scrollingBlock"
              >
                <div class="pt-2" *ngFor="let entry of scroll.viewPortItems">
                  <div #notificationCard class="notification-card" [ngClass]="entry.dismissed ? 'read' : 'unread'">
                    <div class="header">

                      <div class="title">
                        <span class="flex mr-2" [matTooltip]="entry.severity">
                          <ion-icon
                            name="{{entry.icon}}"
                            [style]="entry.iconStyle"
                            class="mt-auto mb-auto"
                          ></ion-icon>

                          <span class="hidden screen-reader-accessible">
                            Notification severity: {{ entry.severity }}
                          </span>
                        </span>

                        <span
                          class="notification-header"
                          [matTooltip]="entry.heading"
                          [innerHTML]="entry.headingHTML"
                        ></span>
                      </div>

                      <span class="date">
                        <!-- lastOccurrence -->
                        <div [matTooltip]="entry.lastOccurrenceTooltip">
                          <span>{{ entry.lastOccurrence | date: 'yyyy/MM/dd HH:mm:ss' }}</span>
                        </div>
                      </span>
                    </div>

                    <div class="body">
                      <!-- message -->
                      <p [innerHTML]="entry.message">
                      </p>
                    </div>

                    <div class="footer">
                      <div class="occurrence" [matTooltip]="'Number of occurrences'">
                        <span>
                          Occurrences: {{ entry.numberOfOccurrences }}
                        </span>
                      </div>

                      <a (click)="toggleDismissNotification(entry)" class="dismiss-notification">
                        {{entry.dismissed ? 'Mark as unread' : 'Mark as read'}}
                      </a>
                    </div>
                  </div>
                </div>
              </virtual-scroller>
            </ng-container>
          </ng-container>

          <div class="no-notifications-img" *ngIf="(notificationsToDisplay$ | async)?.length === 0">
            <img src="/assets/svg/no-notifications-illustration.svg" />
          </div>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</div>
