import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { MaterialImportsModule } from 'src/app/material-imports.module';
import { CustomPipesModule } from 'src/app/utility/pipes/custom-pipes.module';
import { extractTemplateChipsFromName } from 'src/app/utility/templates/extract-template-name-chips';

@Component({
  selector: 'app-title-with-chips',
  standalone: true,
  imports: [
    CustomPipesModule,
    MaterialImportsModule,
    MtxTooltipModule,
    CommonModule,
  ],
  templateUrl: './title-with-chips.component.html',
  styleUrl: './title-with-chips.component.scss'
})
export class TitleWithChipsComponent {
  @Input()
  title?: string;

  @Input()
  showChipsInTooltip = false;

  extractChips = extractTemplateChipsFromName;
}
