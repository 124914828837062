import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskKey } from 'src/app/model/backend';
import { IDataRequest } from 'src/app/model/thingie-data-request';
import { IDevice, ITask } from 'src/app/model/thingie/task';
import { IDataPoints, IOfflineDataKey } from '../common/websocket-connection.service';
import { ThingieDataServiceImpl } from './implementation/thingie-data.service';


export interface IDataItem {
  time: string;
  data: any;
}

export interface IDataItemWithId extends IDataItem {
  _id: string;
  key: any;
}

@Injectable({
  providedIn: 'root',
  useClass: ThingieDataServiceImpl
})
export abstract class ThingieDataService {
  abstract requestData(opts: IDataRequest): Promise<any>;
  abstract requestDataTask(opts: IDataRequest): Observable<IDataPoints[]>;
  abstract subscribeData(opts: IDataRequest): Observable<IDataPoints[]>;
  abstract oneTimeEvaluation(task: ITask, taskKey?: TaskKey): Observable<any>;

  /**
   * Will evaluate a batch of tasks.
   *
   * @returns Observable, containing the results in the same
   * order as the tasks provided
   */
  abstract oneTimeBatchEvaluation(tasks: { key?: TaskKey; task: ITask }[]): Observable<any[]>;
  abstract oneTimeEvaluationExcel(thingieId: string, task: ITask): Observable<Blob>;
  abstract uploadTestdata(res: IDevice): Observable<void>;
  abstract getOfflineData(key: IOfflineDataKey): Observable<any>;
  abstract putOfflineData(key: IOfflineDataKey, data: IDataItem[]): Observable<any>;
  abstract editOfflineData(id: string, data: IDataItem): Observable<any>;
  abstract deleteOfflineData(id: string): Observable<any>;
}
