import { IDataSeries } from './data-series';

export interface IAnnotationSeriesIdentification {
  type: 'annotationSeries';
  thingieId?: string;
  systemAnnotation: boolean;
}

export type AnnotationSeries = Omit<IDataSeries, 'identification'> & { identification: IAnnotationSeriesIdentification } ;

export function isAnnotationSeries(x: IDataSeries): x is AnnotationSeries {
  return x.identification.type === 'annotationSeries';
}

export function isSameAnnotationSeriesIdentification(
  ID1: IAnnotationSeriesIdentification,
  ID2: IAnnotationSeriesIdentification
): boolean {
  return ID1.thingieId === ID2.thingieId &&
    ID1.systemAnnotation === ID2.systemAnnotation
  ;
}
