import { Injectable } from '@angular/core';
import { compare, validate } from 'compare-versions';
import { DateTime, Interval } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { DefaultConfig } from 'src/assets/default.config';
import { AuthService } from '../../auth/auth.service';
import { LogService } from '../../loggers/logger.service';
import { NotificationService } from '../../notifications/notification.service';
import { IUpdateInfo, UpdatesService } from '../../updates/updates.service';
import { UserDataService } from '../../user-data/user-data.service';
import { VersionUpdateCheckService } from '../version-update-check.service';

@Injectable()
export class VersionUpdateCheckServiceImpl implements VersionUpdateCheckService {
  constructor(
    private userData: UserDataService,
    private updateService: UpdatesService,
    private notificationService: NotificationService,
    private auth: AuthService,
    private log: LogService
  ) {
  }

  async runSoftwareUpdateCheck() {
    try {
      const userData = (await lastValueFrom(this.userData.getAllUserData('VERSION_UPDATE')))!;
      const updateData = await this.updateService.checkForSoftwareUpdate();
      const isSoftwareUpdateOn = await this.notificationService.checkSoftwareUpdate();

      // assume no update
      let showNewSoftwareUpdateInfo = false;

      // if there are entries to version check timestamps already, check last updated value
      if (userData.length > 0) {
        const latestTimeStampData = userData[userData.length - 1].value.timestamp;
        const skippedVersion = userData[userData.length - 1].value.skippedVersion;
        const lastCheck = DateTime.fromISO(latestTimeStampData);
        const dateNow = DateTime.now();
        const diff = Interval.fromDateTimes(lastCheck, dateNow);
        const diffHours = diff.length('hours');

        // check for version update if the difference of hour is more than 24
        // and software update is configured to be available
        if (diffHours >= DefaultConfig.uiNotifications.defaultVersionSkipHour && isSoftwareUpdateOn) {
          if (updateData.newVersion) {
            showNewSoftwareUpdateInfo = this.compareVersions(updateData);
            // show popup only if the new version has not been skipped
            if (skippedVersion !== '' && updateData.newVersion) {
              showNewSoftwareUpdateInfo = updateData.newVersion !== skippedVersion;
            }
            // then add the current timestamp to the log, so that on next page load we won't be bothered by the popup
            void this.updateVersionUpdateUserData(skippedVersion);
          }
        }
      } else {
        // check if there is an update
        showNewSoftwareUpdateInfo = this.compareVersions(updateData);
        // and log the current timestamp
        void this.updateVersionUpdateUserData();
      }

      return showNewSoftwareUpdateInfo;
    } catch {
      return false;
    }
  }

  compareVersions(updateData: IUpdateInfo) {
    // show update if the version is greater than current one after validation
    if (validate(updateData.newVersion as string) && validate(updateData.currentVersion)) {
      if (compare(updateData.newVersion as string, updateData.currentVersion, '>')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private async updateVersionUpdateUserData(skippedVersion: string = '') {
    // first get version update data to retrieve the id
    const versionUpdateData = (await lastValueFrom(this.userData.getAllUserData('VERSION_UPDATE')))!;
    // since there is only one VERSION_UPDATE, take first
    const versionUpdateId = versionUpdateData[versionUpdateData.length - 1]?._id;
    if (versionUpdateId === undefined) {
      return this.createVersionUpdateUserData(skippedVersion);
    }
    const uid = await this.auth.getCurrentUserId();

    (await lastValueFrom(this.userData.updateUserData(
      'VERSION_UPDATE',
      uid,
      versionUpdateId ?? '',
      {
        timestamp: DateTime.now().toISO(),
        skippedVersion
      }
    )))!;
  }

  private async createVersionUpdateUserData(skippedVersion: string = '') {
    // save the current time as timestamp to track when the version was last checked
    const uid = await this.auth.getCurrentUserId();
    (await lastValueFrom(this.userData.createUserData(
      'VERSION_UPDATE',
      uid,
      {
        timestamp: DateTime.now().toISO(),
        skippedVersion
      },
      'PRIVATE'
    )))!;
  }

  openChangeLogs() {
    window.open(DefaultConfig.uris.versionUpdateChangelogs);
    return false;
  }

  async skipUpdate() {
    const updateData = await this.updateService.checkForSoftwareUpdate();

    void this.updateVersionUpdateUserData(updateData.newVersion);
    return false;
  }

  remindUpdateLater() {
    void this.updateVersionUpdateUserData();
    return false;
  }
}
