import { PlotDataRequest } from './backend';

export interface IThingieDataRequest_Task {
  idx: number;
  outputs: string[];
}

export interface IThingieDataRequest_Process {
  idx: number;
  tasks: IThingieDataRequest_Task[];
}

export interface IDataRequest_Range {
  from?: string;
  to?: string;
}

export interface IDataRequest {
  _type: 'THINGIE' | 'DEVICE' | 'ANNOTATION' | 'OFFLINE';
  forecasting: boolean;
  forecastDuration?: number;
  range: IDataRequest_Range;
  denoising: 'OFF' | 'LOW' | 'MEDIUM' | 'HIGH';

  outputName: string;

  // Number of data points to request
  nDataPoints?: any;
  denoisingStartValue?: number;

  calibrationDefaultData?: PlotDataRequest['calibrationDefaultData'];
  calibrationOfflineDataInputKey?: PlotDataRequest['calibrationOfflineDataInputKey'];
}

export interface IThingieDataRequest extends IDataRequest {
  _type: 'THINGIE';

  thingieId: string;
  processIdx: number;
  taskIdx: number;
}

export interface IOfflineDataRequest extends IDataRequest {
  _type: 'OFFLINE';

  thingieId: string;
  processIdx: number;
  taskIdx: number;
  inputName: string;
}

export interface IDeviceDataRequest extends IDataRequest {
  _type: 'DEVICE';

  deviceAddress: string;
  packetType: string;
  property?: string;
  config?: { [key: string]: any };
}
export interface IAnnotationDataRequest extends IDataRequest {
  _type: 'ANNOTATION';

  thingieId: string;
}
export function isThingieDataRequest(request: IDataRequest): request is IThingieDataRequest {
  return request._type === 'THINGIE';
}

export function isDeviceDataRequest(request: IDataRequest): request is IDeviceDataRequest {
  return request._type === 'DEVICE';
}

export function isOfflineDataRequest(request: IDataRequest): request is IOfflineDataRequest {
  return request._type === 'OFFLINE';
}

export function isAnnotationDataRequest(request: IDataRequest): request is IAnnotationDataRequest {
  return request._type === 'ANNOTATION';
}
