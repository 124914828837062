import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { lastValueFrom } from 'rxjs';
import { AnnotationViewModule } from 'src/app/components/annotation-view/annotation-view.module';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { IProject } from 'src/app/model/user-management/project';
import { ITeam } from 'src/app/model/user-management/team';
import { ProjectService } from 'src/app/services/project/project.service';
import { TeamService } from 'src/app/services/team/team.service';
import { ThingieService } from 'src/app/services/thingie/thingie-service.service';
import { UINotificationService } from 'src/app/services/ui-notification/uinotification.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-thingie-import-dialog',
  standalone: true,
  imports: [
    AnnotationViewModule,
    MaterialImportsModule,
    CommonModule,
    IonicImports,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  templateUrl: './thingie-import-dialog.component.html',
})
export class ThingieImportDialogComponent implements OnInit, OnDestroy {

  /**
   * project service
   */
  private projectService = inject(ProjectService);


  /**
   * team service
   */
  private teamService = inject(TeamService);


  /**
   * thingie service
   */
  private thingieService = inject(ThingieService);


  /**
   * ui notification service
   */
  private uiNotification = inject(UINotificationService);


  /**
   * reference to the dialog instance
   */
  private self = inject(MatDialogRef<ThingieImportDialogComponent, undefined>);


  /**
   * @private
   */
  readonly _projectId = new FormControl('');


  /**
   * @private
   */
  readonly _teamId = new FormControl('');


  /**
   * @private
   */
  _teamList: ITeam[] = [];


  /**
   * @private
   */
  _projectList: IProject[] = [];


  /**
   * Used to to show progress while importing
   *
   * @private
   */
  _importInProgress = false;


  /**
   * The files to import
   */
  private files: File[] = [];

  private subscriptions = new SubSink();
  userCanImportFiles = false;

  /**
   * @private
   */
  async ngOnInit() {
    this._teamList    = await this.teamService.getTeamList();
    this._projectList = await this.projectService.getAllProjects();

    if (this._teamList.length > 0) {
      this._teamId.setValue(this._teamList[0]._id);
    }

    if (this._projectList.length > 0) {
      this._projectId.setValue(this._projectList[0]._id);
    }

    this.subscriptions.sink = this._teamId.valueChanges.subscribe(() => {
      this._onProjectsTeamsUpdate();
    });

    this.subscriptions.sink = this._projectId.valueChanges.subscribe(() => {
      this._onProjectsTeamsUpdate();
    });

    this._onProjectsTeamsUpdate();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Cache file objects to be imported
   *
   * @private
   * @param files
   */
  _setFiles(files: File[]) {
    this.files = files;
  }


  // check teams and projects to give permission for importing
  private _onProjectsTeamsUpdate() {
    if (this._projectId.value && this._projectId.value.length > 0 && this._teamId.value && this._teamId.value.length > 0) {
      this.userCanImportFiles = true;
    } else {
      this.userCanImportFiles = false;
    }
  }


  /**
   * Start the import
   *
   * @private
   */
  async _import() {
    this._importInProgress = true;

    let pid: string | undefined;
    let tid: string | undefined;

    if (this._projectId.value && this._projectId.value.length > 0) {
      pid = this._projectId.value;
    }

    if (this._teamId.value && this._teamId.value.length > 0) {
      tid = this._teamId.value;
    }

    try {
      await this.thingieService.importThingie(this.files, pid, tid);

      this.uiNotification.displaySnackbar({
        message: 'Import request submitted successfully. You will be notified via a notification once it has completed.'
      });

      this.self.close();
    } catch (e: unknown) {
      if (e instanceof HttpErrorResponse && e.status === 400) {
        const error = e as HttpErrorResponse;
        this.uiNotification.displayErrorAlert({
          title: 'Error while importing objects',
          message: error.error?.message ?? 'No info',
          details: ''
        }, e);
        return;
      }

      // Re-throw unhandled exception
      throw e;
    } finally {
      this._importInProgress = false;
    }
  }
}


/**
 * Show's the experiment import dialog.
 */
@Injectable({
  providedIn: 'root'
})
export class ExperimentImportDialogService {
  private dialog = inject(MatDialog);

  /**
   * Open dialog
   *
   * @returns After the dialog has been closed
   */
  async open() {
    return (await lastValueFrom(this.dialog.open<
      ThingieImportDialogComponent,
      undefined,
      undefined>(ThingieImportDialogComponent)
      .afterClosed()))!;
  }
}
