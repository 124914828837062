import _ from 'lodash';
import { isNotUndefined } from './types';

/**
 * Performs a deep comparison of the two given objects. However, in contrast to `_.isEqual`,
 * this function considers `{a: undefined}` and `{}` to be equal, by skipping properties
 * that have value `undefined`.
 *
 * @param a left hand side of comparison
 * @param b right hand side of comparison
 * @returns whether the two parameters are deeply equal, when ignoring properties that are undefined
 */
export function isEqualWithIgnoringUndefinedProperties(a: any, b: any): boolean {
  function customizer(a: any, b: any) {
    if (_.isObject(a) && _.isObject(b)) {
      // if no values are undefined, return undefined in order to use default comparison
      if (!(_.includes(_.values(a), undefined) || _.includes(_.values(b), undefined))) {
        return undefined;
      }
      // recursively check equality while removing undefined values
      return _.isEqualWith(_.pickBy(a, isNotUndefined), _.pickBy(b, isNotUndefined), customizer);
    } else {
      // use default deep comparison by returning undefined
      return undefined;
    }
  }
  return _.isEqualWith(a, b, customizer);
}
