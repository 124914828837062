import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { SplitButtonComponent } from './split-button/split-button.component';

@NgModule({
  declarations: [
    SplitButtonComponent
  ],
  imports: [
    CommonModule,
    IonicImports,
    MaterialImportsModule
  ],
  exports: [
    SplitButtonComponent
  ]
})
export class ButtonsModule {}
