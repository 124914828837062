import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import DataLoader from 'dataloader';
import { DateTime } from 'luxon';
import { EMPTY, Observable, from, lastValueFrom, of, throwError } from 'rxjs';
import { map, filter as rxjsFilter, switchMap, tap } from 'rxjs/operators';
import { TaskKey } from 'src/app/model/backend';
import { IDataRequest, isDeviceDataRequest, isOfflineDataRequest, isThingieDataRequest } from 'src/app/model/thingie-data-request';
import { IDevice, ITask } from 'src/app/model/thingie/task';
import {
  IDataPoints,
  IFilter,
  IOfflineDataKey, WebsocketConnectionService
} from 'src/app/services/common/websocket-connection.service';
import { DefaultConfig } from 'src/assets/default.config';
import { IDataItem, ThingieDataService } from '../thingie-data.service';

@Injectable()
export class ThingieDataServiceImpl implements ThingieDataService {
  tsvImportUri = DefaultConfig.uris.tsvImport;
  websocketUri = DefaultConfig.uris.dataWebsocket;

  constructor(private httpClient: HttpClient, private websocketConnection: WebsocketConnectionService) {
  }

  requestData(opts: IDataRequest): Promise<any> {
    return lastValueFrom(this.requestDataTask(opts));
  }

  private requestDataTaskLoader = new DataLoader<IDataRequest, IDataPoints[] | undefined>(keys => {
    const uri = DefaultConfig.uris.seriesDataMultiple;
    return lastValueFrom(this.httpClient.post<{ results: IDataPoints[][]; errors: string[] }>(uri, keys).pipe(
      map(({ results, errors }) => results.map((result, i) => errors[i] ? new HttpErrorResponse({ error: errors[i] }) : result))
    ));
  }, { cache: false, batchScheduleFn: (cb) => setTimeout(() => cb(), 100), maxBatchSize: 48, });

  requestDataTask(opts: IDataRequest): Observable<IDataPoints[]> {
    return from(this.requestDataTaskLoader.load(opts)).pipe(switchMap(res =>
      res !== undefined ? (res instanceof HttpErrorResponse ? throwError(() => res) : of(res)) : EMPTY));
  }

  subscribeData(opts: IDataRequest): Observable<IDataPoints[]> {
    let filter: IFilter;

    if (isDeviceDataRequest(opts)) {
      filter = {
        filterType: 'DeviceDataFilter',
        deviceAddress: opts.deviceAddress,
        packetType: opts.packetType,
        property: opts.property,
        config: opts.config,
        dataRequest: {
          range: opts.range,
          forecasting: opts.forecasting,
          forecastDuration: opts.forecastDuration,
          denoising: opts.denoising,
          offset: 0.0,
          outputName: opts.outputName,
          calibrationDefaultData: opts.calibrationDefaultData,
          calibrationOfflineDataInputKey: opts.calibrationOfflineDataInputKey
        },
        from: opts.range.from,
        to: opts.range.to
      };
    } else if (isOfflineDataRequest(opts)) {
      filter = {
        filterType: 'OfflineDataFilter',
        thingie: opts.thingieId,
        processIdx: opts.processIdx,
        taskIdx: opts.taskIdx,
        inputName: opts.outputName,
        from: opts.range.from,
        to: opts.range.to
      };
    } else if (isThingieDataRequest(opts)) {
      filter = {
        filterType: 'TaskOutputFilter',
        thingie: opts.thingieId,
        processIdx: opts.processIdx,
        taskIdx: opts.taskIdx,
        dataRequest: {
          range: opts.range,
          forecasting: opts.forecasting,
          forecastDuration: opts.forecastDuration,
          denoising: opts.denoising,
          offset: 0.0,
          outputName: opts.outputName,
          calibrationDefaultData: opts.calibrationDefaultData,
          calibrationOfflineDataInputKey: opts.calibrationOfflineDataInputKey
        },
        from: opts.range.from,
        to: opts.range.to
      };
    } else {
      throw new Error('Unsupported request type');
    }


    return this.websocketConnection.subscribeWithFilter<{ time: string; data: any }>(
      this.websocketUri,
      filter
    ).pipe(
      map(dataEvent => ({ dataEvent, name: dataEvent.key?.name ?? dataEvent.key?.input })),
      rxjsFilter((data): data is { dataEvent: typeof data['dataEvent']; name: string } => data.name !== undefined),
      map(({ dataEvent, name }) => ([
        {
          output: name,
          points: [
            {
              time: DateTime.fromISO(dataEvent.data.time).toMillis(),
              data: dataEvent.data.data
            }
          ]
        }
      ]))
    );
  }

  oneTimeEvaluation(task: ITask, taskKey?: TaskKey): Observable<any> {
    const uri = DefaultConfig.uris.taskOneTime;
    return this.httpClient.post<any>(uri, { key: taskKey, task });
  }

  oneTimeBatchEvaluation(tasks: { key?: TaskKey; task: ITask }[]): Observable<any[]> {
    const uri = DefaultConfig.uris.taskOneTime_Batch;
    return this.httpClient.post<any>(uri, tasks);
  }

  oneTimeEvaluationExcel(thingieId: string, task: ITask): Observable<Blob> {
    const uri = DefaultConfig.uris.taskOneTime;
    const acceptHeader = new HttpHeaders({
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    return this.httpClient.post(uri,
      { task },
      { headers: acceptHeader, observe: 'response', responseType: 'blob' })
      .pipe(map(res => {
        if (res.body === null) {
          throw new Error('No file was produced');
        }
        return res.body;
      }));
  }

  getOfflineData(key: IOfflineDataKey): Observable<any> {
    const uri = DefaultConfig.uris.offlineDataQuery;
    return this.httpClient.post<any>(uri, key);
  }

  putOfflineData(key: IOfflineDataKey, data: IDataItem[]): Observable<any> {
    const uri = DefaultConfig.uris.offlineData;
    const payload = { key, data };
    return this.httpClient.post<any>(uri, payload);
  }

  editOfflineData(id: string, data: IDataItem): Observable<any> {
    const uri = DefaultConfig.uris.offlineDataItem.replace('{id}', id);
    return this.httpClient.put<any>(uri, data);
  }

  deleteOfflineData(id: string): Observable<any> {
    const uri = DefaultConfig.uris.offlineDataItem.replace('{id}', id);
    return this.httpClient.delete<any>(uri);
  }

  uploadTestdata(res: IDevice): Observable<void> {
    if (res.deviceAddress === undefined) {
      throw new Error('Cannot upload if no device address is selected');
    }
    const address = res.deviceAddress;

    return from(navigator.clipboard.read()).pipe(
      map(clipboardItems => clipboardItems[0]),
      tap(clipboardContent => {
        if (!clipboardContent.types.includes('text/plain') || !clipboardContent.types.includes('text/html')) {
          throw new Error('The clipboard does not contain a table copied from excel.');
        }
      }),
      switchMap(clipboardContent => clipboardContent.getType('text/plain')),
      switchMap(blob => blob.text()),
      switchMap(data => {
        const uri = this.tsvImportUri
          .replace('{deviceAddress}', encodeURIComponent(address.split('/').join('--')))
          .replace('{packetType}', res.packetType)
          .replace('{config}', encodeURIComponent(JSON.stringify(res.config)))
          .replace('{property}', res.property ?? '');
        return this.httpClient.post<void>(uri,
          data,
          {
            headers: new HttpHeaders({
              'Content-Type': 'text/tab-separated-values'
            })
          });
      })
    );
  }
}
