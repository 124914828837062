import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { ThingieStateChipComponent } from './thingie-state-chip.component';



@NgModule({
  declarations: [
    ThingieStateChipComponent
  ],
  exports: [
    ThingieStateChipComponent
  ],
  imports: [
    CommonModule,
    IonicImports,
    MaterialImportsModule
  ]
})
export class ThingieStateChipModule { }
