<ng-container *ngIf="!data.updateAvailable; else Outdated">
  <div class="title-close-container-flex">
    <h1 mat-dialog-title>Your version is up-to-date</h1>
  </div>
</ng-container>

<ng-template #Outdated>
  <div class="title-close-container-flex">
    <h1 mat-dialog-title>New version available</h1>
    <div mat-dialog-close></div>
  </div>
  <div class="inline-grid justify-center grid-cols-[1fr_1fr]">
    Current version: <strong class="text-end">{{ data.currentVersion }}</strong> 
    New Version: <strong class="text-end">{{ data.newVersion }}</strong> 
  </div>
</ng-template>

<div mat-dialog-actions [align]="'end'">
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="null"
  >
    Close
  </button>
</div>
