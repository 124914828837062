import { NgModule } from '@angular/core';
import { ContextTypeDirective } from './context-type/context-type.directive';
import { FileDragAndDropDirective } from './file-drag-drop/file-drag-drop.directive';
import { KeyboardNavigatorDirective } from './keyboard-navigator/keyboard-navigator.directive';
import { MatTooltipNoninteractiveDirective } from './mat-tooltip-noninteractive.directive';
import { NoTabsPaginationDirective } from './no-tabs-pagination/no-tabs-pagination.directive';
import { SelectorDirective } from './query-helper/selector.directive';

@NgModule({
  declarations: [
    NoTabsPaginationDirective,
    FileDragAndDropDirective,
    SelectorDirective,
    ContextTypeDirective,
    KeyboardNavigatorDirective,
    MatTooltipNoninteractiveDirective
  ],
  exports: [
    NoTabsPaginationDirective,
    FileDragAndDropDirective,
    SelectorDirective,
    ContextTypeDirective,
    KeyboardNavigatorDirective,
    MatTooltipNoninteractiveDirective,
  ]
})
export class DirectiveModule {
}
