import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { CustomPipesModule } from 'src/app/utility/pipes/custom-pipes.module';
import { FilterByProjectComponent } from './filter-by-project.component';



@NgModule({
  declarations: [FilterByProjectComponent],
  imports: [
    CommonModule,
    MaterialImportsModule,
    IonicImports,
    CustomPipesModule
  ],
  exports: [FilterByProjectComponent]
})
export class FilterByProjectModule { }
