import { Pipe, PipeTransform } from '@angular/core';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { resolveShortcut } from '../device-shortcut';


@Pipe({ name: 'shortcut' })
export class ShortcutPipe implements PipeTransform {
  constructor() {}
  transform(value: IPojoDevice, shortcut: string, defaultValue?: any) {
    return resolveShortcut(value, shortcut, defaultValue);
  }
}
