import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { DirectiveModule } from 'src/app/directives/directive.module';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { NgxTranslateModule } from 'src/app/translate.module';
import { CustomPipesModule } from 'src/app/utility/pipes/custom-pipes.module';
import { AccordionModule } from '../accordion/accordion.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { ChippedAutocompleteModule } from '../chipped-autocomplete/chipped-autocomplete.module';
import { FilterByProjectModule } from '../filter-by-project/filter-by-project.module';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { ThingieStateChipModule } from '../thingie-state-chip/thingie-state-chip.module';
import { TitleWithChipsComponent } from '../title-with-chips/title-with-chips.component';
import { MenuBarComponent } from './menu-bar.component';
import { NotificationComponent } from './notification/notification.component';


@NgModule({
  declarations: [ MenuBarComponent, NotificationComponent ],
  imports: [
    VirtualScrollerModule,
    CommonModule,
    MaterialImportsModule,
    SkeletonLoaderModule,
    RouterModule,
    AccordionModule,
    CustomPipesModule,
    IonicImports,
    ReactiveFormsModule,
    ChippedAutocompleteModule,
    FormsModule,
    ButtonsModule,
    FilterByProjectModule,
    NgxTranslateModule,
    DirectiveModule,
    ThingieStateChipModule,
    TitleWithChipsComponent,
  ],
  exports: [ MenuBarComponent ]
})
export class MenuBarModule { }
