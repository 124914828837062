import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, Observable, of } from 'rxjs';
import { CalibrationFilePostProcessing } from 'src/app/model/backend';
import {
  IApplicationTemplate,
  IMetaApplicationTemplate,
  IMetaTemplate,
  IApplicationTemplateMetadata,
  ITaskTemplate
} from 'src/app/model/thingie/templates';
import { IUserData } from 'src/app/model/userdata';
import { deepFreeze } from 'src/app/utility/deep-freeze';
import { DefaultConfig } from 'src/assets/default.config';
import { UserDataService } from '../user-data/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(
    private http: HttpClient,
    private userData: UserDataService
  ) { }

  async getApplicationTemplateList(): Promise<IApplicationTemplate[]> {
    const uri = DefaultConfig.uris.applicationTemplateList;

    const metaTemplates = (await lastValueFrom(this.http.get<IMetaApplicationTemplate[]>(uri)))!;
    const templates: IApplicationTemplate[] = metaTemplates.map(template => ({
      ...template.template,
      _id: template._id
    }));

    return deepFreeze(templates);
  }

  async getApplicationTemplateMetadataList(): Promise<IApplicationTemplateMetadata[]> {
    const uri = DefaultConfig.uris.applicationTemplateMetadataList;
    return await lastValueFrom(this.http.get<IApplicationTemplateMetadata[]>(uri));
  }

  async getApplicationTemplateById(templateId: string): Promise<IApplicationTemplate | undefined> {
    const uri = DefaultConfig.uris.applicationTemplateById.replace('{id}', templateId);
    return await lastValueFrom(this.http.get<IMetaApplicationTemplate>(uri)
      .pipe(map(tpl => tpl.template), catchError((_: unknown) => of(undefined))));
  }

  async getTaskTemplateList(): Promise<ITaskTemplate[]> {
    const uri = DefaultConfig.uris.taskTemplateList;
    return deepFreeze((await lastValueFrom(this.http.get<ITaskTemplate[]>(uri)))!);
  }

  async getCalibrationTemplateList(): Promise<IMetaTemplate<CalibrationFilePostProcessing>[]> {
    const uri = DefaultConfig.uris.calibrationTemplateList;

    return deepFreeze((await lastValueFrom(this.http.get<IMetaTemplate<CalibrationFilePostProcessing>[]>(uri)))!);
  }

  /**
   * Convenience method to load all Turbo Templates
   * set by the current user in their user settings
   *
   * @returns Stream of Turbo Template lists
   */
  loadTurboTemplates(): Observable<IUserData<IApplicationTemplate>[]> {
    return this.userData
      .getAllUserData('TURBO_TEMPLATE');
  }
}
