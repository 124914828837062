import { NgModule } from '@angular/core';
import { IonicImports } from 'src/app/material-imports.module';
import { AccordionGroupComponent } from './accordion-group/accordion-group.component';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';

@NgModule({
  declarations: [
    AccordionGroupComponent,
    AccordionItemComponent
  ],
  imports: [
    IonicImports,
  ],
  exports: [
    AccordionGroupComponent,
    AccordionItemComponent
  ]
})
export class AccordionModule {}
