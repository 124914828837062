import { Injectable } from '@angular/core';
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import * as jsonPatch from 'fast-json-patch';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { isAnnotationSeries } from 'src/app/model/annotation-series';
import { LogicCondition, TaskKey } from 'src/app/model/backend';
import { IDataSeries, isSameDataSeries } from 'src/app/model/data-series';
import { IGraphConfig } from 'src/app/model/graph-configs/graph-config';
import { IThingieCreationContext } from 'src/app/model/thingie-creation-wizard/thingie-creation-context';
import { isThingieSeries } from 'src/app/model/thingie-series';
import { IDevice, IDynamicInputList, IReassignable, ITask, ITaskInputType, ITaskOutput, IUserSetting } from 'src/app/model/thingie/task';
import { IProcess, IThingie } from 'src/app/model/thingie/thingie';
import { isScatterSeries } from 'src/app/model/values-config';
import { deepCopySync } from 'src/app/utility/deep-copy';
import { isEqualWithIgnoringUndefinedProperties } from 'src/app/utility/equality';
import { purifyTask } from 'src/app/utility/thingie/pure-copy-task';
import { generateNewTabUUID } from 'src/app/utility/uuid-generator';
import { ThingieDataService } from '../data/thingie-data.service';
import { LogService } from '../loggers/logger.service';
import { ThingieService } from '../thingie/thingie-service.service';

type EvaluationCallbackData = {
  callbacks: { [key: string]: ((value: any) => void)[] };
  task: ITask;
  key: TaskKey;
};

@Injectable({
  providedIn: 'root'
})
export class ThingieCreationHelperService {
  constructor(
    private dataService: ThingieDataService,
    private log: LogService,
    private thingieService: ThingieService,
  ) { }

  /**
   * Configures the device inputs of thingies
   *
   * @param thingieList The thingie list to process
   */
  async configureDeviceInputs(taskList: { key: TaskKey; task: ITask }[]): Promise<void> {
    // only reconfigure tasks that have not been started yet
    taskList = taskList.filter(taskInfo => taskInfo.task.currentState === 'NEW' || taskInfo.task.currentState === undefined);

    const callbackData = this.preProcessTasksForEvaluation(taskList);

    const responseList = (await firstValueFrom(this.dataService
      .oneTimeBatchEvaluation(callbackData.map(({ key, task }) => ({ key, task })))));

    this.applyDeviceConfiguration(responseList, callbackData);
  }

  private applyDeviceConfiguration(responseList: any[], callbackData: EvaluationCallbackData[]) {
    responseList.forEach((response, idx) => {
      const responseData = response as { output: string; points: { time: string; data: any }[] }[];
      for (const data of responseData) {
        let value = data.points[data.points.length - 1].data;

        // extract the result if necessary
        if (typeof (value) === 'object' && value.value !== undefined) {
          value = value.value;
        }

        // get callbacks associated with this task output
        const callbacks = callbackData[idx].callbacks[data.output];
        if (callbacks === undefined) {
          continue;
        }

        this.log.debug(`Applying dynamic device config value '${value}' for references to '${data.output}'`);
        for (const cb of callbacks) {
          cb.call(null, value);
        }
      }
    });
  }

  private preProcessTasksForEvaluation(taskList: { key: TaskKey; task: ITask }[]) {
    const evaluationData: EvaluationCallbackData[] = [];

    taskList.forEach(
      taskInfo => {
        const { key, task } = taskInfo;
        const valueCallbacks: { [key: string]: ((value: any) => void)[] } = {};
        const taskToEvaluate = deepCopySync(task);
        taskToEvaluate.outputs = {};
        taskToEvaluate.started = DateTime.local().minus(100000)
          .toISO();
        taskToEvaluate.stopped = DateTime.local().plus(100000)
          .toISO();

        // look for task inputs of type device that have configTemplate set
        Object.values(task.inputs)
          .filter((input): input is IDevice | IDynamicInputList => (input.source === 'device' || input.source === 'dynamicinputlist')
            && input.configTemplate !== undefined)
          .forEach(input => input.configTemplate!.forEach((template) => {
            // request the specified id using a onetime task
            taskToEvaluate.outputs[template.id] = { items: {} };

            // once the data arrives, put it into the config of the input
            valueCallbacks[template.id] = valueCallbacks[template.id] ?? [];
            valueCallbacks[template.id].push((value) => {
              input.config = input.config ?? {};
              const calculatedValue = template.property ? value[template.property] : value;

              // ignore `undefined`, because it never makes sense as a device config value
              if (calculatedValue !== undefined) {
                input.config[template.as] = calculatedValue;
              }
            });
          }));

        if (Object.keys(taskToEvaluate.outputs).length !== 0) {
          evaluationData.push({
            key,
            task: taskToEvaluate,
            callbacks: valueCallbacks
          });
        }
      }
    );

    return evaluationData;
  }

  /**
   * Set the reassignable inputs thingie's, if not set
   *
   * @param thingie thingie to be modified
   * @param oldId   thingie ID to be replaced with newId
   * @param newId   replacement thingie ID
   */
  preProcessReassignableInputs(thingieOrTasks: IThingie | { task: ITask; key: TaskKey }[], oldId: Partial<TaskKey>, newId: Partial<TaskKey>): void {
    const tasks = _.isArray(thingieOrTasks) ? thingieOrTasks : thingieOrTasks.processes.flatMap((process, i) =>
      process.tasks.map((task, j) =>
        ({ task, key: { thingie: thingieOrTasks._id, processIdx: i, taskIdx: j } })));
    tasks.forEach(({ task, key }) => {
      const specificNewId: Partial<TaskKey> = {
        thingie: newId.thingie,
        processIdx: newId.processIdx ?? key.processIdx,
        taskIdx: newId.taskIdx ?? key.taskIdx,
      };
      Object.values(task.inputs)
        .filter((input: ITaskInputType): input is IReassignable =>
          input.source === 'reassignable' && input.value !== undefined && input.value.task.thingie === oldId.thingie)
        .forEach(input => {
          this.adjustTaskKey(input.value!.task, oldId, specificNewId);
          input.value!.task.thingie = newId.thingie!;
          // also set in options, if options are set
          if (input.item.valueInfo[0].options !== undefined) {
            input.item.valueInfo[0].options.forEach(option => {
              this.adjustTaskKey(option[0].task, oldId, specificNewId);
            });
          }
        });
      Object.values(task.inputs)
        .filter((input: ITaskInputType): input is IDynamicInputList =>
          input.source === 'dynamicinputlist' && input.config !== undefined)
        .forEach(input => {
          Object.values(input.config!).forEach(config =>
            Object.values(config)
              .forEach(nestedInput => {
                if (nestedInput.value !== undefined) {
                  this.adjustTaskKey(nestedInput.value.task, oldId, specificNewId);
                }
              }));
        });
      const preprocessLogicArgument = (arg: LogicCondition['arguments'][0]) => {
        switch (arg.type) {
          case 'expression':
            (arg.expression as LogicCondition).arguments.forEach(argument => preprocessLogicArgument(argument));
            return;
          case 'calculation':
            arg.arguments.forEach(argument => preprocessLogicArgument(argument));
            return;
          case 'logicTransform':
            preprocessLogicArgument(arg.condition);
            return;
          case 'transform':
            preprocessLogicArgument(arg.argument);
            return;
          case 'series':
            if (arg.taskOutput !== undefined) {
              this.adjustTaskKey(arg.taskOutput.task, oldId, specificNewId);
            }
            return;
          case 'constant':
            return;
          default:
            // assert that the switch is exhaustive and arg is of type `never`
            ((_: never) => { /**/ })(arg);
        }
      };
      Object.values(task.inputs)
        .filter((input: ITaskInputType): input is IUserSetting => input.source === 'usersetting' && input.inputElementType === 'logic-builder')
        .forEach(input => {
          if (input.value !== undefined) {
            (input.value as LogicCondition).arguments.forEach(arg => preprocessLogicArgument(arg));
          }
        });
    });
  }

  adjustTaskKey(task: { thingie: string; processIdx: number; taskIdx: number }, oldId: Partial<TaskKey>, newId: Partial<TaskKey>) {
    if (task.thingie === oldId.thingie) {
      task.thingie = newId.thingie!;
      if (task.processIdx === oldId.processIdx && newId.processIdx !== undefined) {
        task.processIdx = newId.processIdx;
        if (task.taskIdx === oldId.taskIdx && newId.taskIdx !== undefined) {
          task.taskIdx = newId.taskIdx;
        }
      }
    }
  }

  /**
   * Throw out all task outputs that have a condition specified that is not fulfilled.
   * Conditions like `{a: "b"}` mean that equality of the usersetting input
   * `task.inputs["a"].value` and "b" is evaluated. If a condition is a mongodb query
   * filter-like object, all specified filters like `{a: {$geq: 5}, b: {$geq: 1, $leq: 3}}`
   * need to be fulfilled for the output to stay in.
   *
   * Also remove disabled thingie series from default graph configs.
   *
   * @param thingie Thingie whose outputs should be checked
   */
  preProcessConditionalOutputsAndInputs(thingieOrTasks: IThingie | ITask[]): void {
    const tasks = _.isArray(thingieOrTasks) ? thingieOrTasks : thingieOrTasks.processes.flatMap(process => process.tasks);
    tasks.forEach(task => {
      const processCondition = (_inputOrOutput: IDevice | ITaskOutput) => {
        const output = _inputOrOutput;
        if (output.condition === undefined) {
          return;
        }
        let outputDisabled = false;
        for (const [conditionKey, condition] of Object.entries(output.condition)) {
          if (conditionKey.startsWith('! ')) {
            // this is instructions for the rendering
            continue;
          }
          const conditionInput = task.inputs[conditionKey];
          if (conditionInput.source !== 'usersetting' && conditionInput.source !== 'reassignable') {
            throw new Error('invalid condition for non-usersetting input');
          }
          const configuredValue = conditionInput.value as any;
          let conditionFulfilled = isEqualWithIgnoringUndefinedProperties(configuredValue, condition);
          if (conditionFulfilled === false && _.isObject(condition)) {
            conditionFulfilled = true;
            for (const [filterKey, filterValue] of Object.entries(condition)) {
              if (filterKey === '$leq') {
                conditionFulfilled = conditionFulfilled && configuredValue <= filterValue;
              } else if (filterKey === '$geq') {
                conditionFulfilled = conditionFulfilled && configuredValue >= filterValue;
              } else if (filterKey === '$contains') {
                conditionFulfilled = conditionFulfilled && _.isArray(configuredValue) && configuredValue.includes(filterValue);
              } else if (filterKey === '$anyElementTrue') {
                // support queries like `{ $anyElementTrue: { idx0: 6 } }` and `{ $anyElementTrue: { idx1: 4 } }`, looking into a multi-dimensional slice of the input matrix
                const multiDimensionalSlice = (obj: any, slice: any) => {
                  obj = [obj];
                  let idxRequests = Object.keys(slice)
                    .filter(key => key.startsWith('idx'))
                    .map(key => +key.substring(3))
                    .sort();
                  if (idxRequests.length > 0) {
                    const maxIdx = Math.max(...idxRequests);
                    for (let i = 0; i < maxIdx + 1; i++) {
                      if (idxRequests[0] === i) {
                        obj = obj.map((row: any) => [row[slice['idx' + idxRequests[0]]]]);
                        idxRequests = idxRequests.slice(1);
                      }
                      obj = obj.flat();
                    }
                  }
                  return obj;
                };
                if (configuredValue === undefined) {
                  continue;
                }
                const slice = multiDimensionalSlice(configuredValue, filterValue);
                const thisConditionFulfilled = _.flattenDeep(slice).includes(true);
                conditionFulfilled = conditionFulfilled && thisConditionFulfilled;
              } else {
                // compare equality
                conditionFulfilled = conditionFulfilled && isEqualWithIgnoringUndefinedProperties(configuredValue[filterKey], filterValue);
              }
            }
          }

          if (!conditionFulfilled) {
            outputDisabled = true;
          }
        }

        if (outputDisabled) {
          output.disabled = true;
        } else {
          delete output.disabled;
        }
      };

      for (const output of Object.values(task.outputs)) {
        processCondition(output);
      }
      for (const input of Object.values(task.inputs)) {
        if (input.source !== 'device') {
          continue;
        }
        processCondition(input);
      }
    });
  }

  /**
   * Remove the disabled series from the default graphconfigs
   *
   * @param thingie
   */
  preProcessRemoveDisabledSeries(thingie: IThingie) {
    thingie.processes.forEach((process, processIndex) =>
      process.tasks.forEach((task, taskIndex) => {
        for (const [outputKey, output] of Object.entries(task.outputs)) {
          if (output.disabled !== true) {
            // this function only affects disabled series
            continue;
          }
          // remove disabled series from default graph configs
          for (const configs of [thingie.defaultDashboardCardGraphConfigs ?? [], thingie.defaultGraphConfigs]) {
            for (const config of configs) {
              config.internalSeriesList = config.internalSeriesList.filter(series => {
                if (!isThingieSeries(series)) {
                  return true;
                }
                const isThisOutputSeries = series.identification.thingieId === thingie._id &&
                  series.identification.processIndex === processIndex &&
                  series.identification.taskIndex === taskIndex &&
                  series.identification.output === outputKey;
                return !isThisOutputSeries;
              });
            }
          }
        }
      }));
  }

  /**
   * Adjusts the default graph configurations
   * to their containing thingie
   *
   * - Copy graph configs from templateThingie
   * - Change ids in the defaultGraphConfigs to match the newly created thingie
   * - Remove graph configs with non-existent series
   *
   * @param thingie thingie to be modified
   */
  customizeDefaultGraphConfigs(thingie: IThingie, templateThingie: IThingie): void {
    if (thingie._id === undefined) {
      throw new Error('Thingie ID missing but is required to customize default graph configs.');
    }

    thingie.defaultDashboardCardGraphConfigs = deepCopySync(templateThingie.defaultDashboardCardGraphConfigs);
    thingie.defaultGraphConfigs = deepCopySync(templateThingie.defaultGraphConfigs);

    const thingieSeriesList = _.concat(
      this.thingieService.getAvailableDataSeriesFromThingie(thingie),
      this.thingieService.getAnnotationSeriesFromThingie(thingie)
    );
    this._customizeDefaultGraphConfigs(thingie.defaultGraphConfigs, thingie._id ?? '');
    this._customizeDefaultGraphConfigs(thingie.defaultDashboardCardGraphConfigs ?? [], thingie._id ?? '');
    this.preProcessRemoveDisabledSeries(thingie);

    this._defaultGraphConfigConsistencyCheck(thingie.defaultGraphConfigs, thingieSeriesList);
    this._defaultGraphConfigConsistencyCheck(thingie.defaultDashboardCardGraphConfigs ?? [], thingieSeriesList);
  }

  /**
   * Change ids in the defaultGraphConfigs to match the newly created thingie
   *
   * @param list list of graph configs to modify
   * @param thingieId id of the thingie, to be inserted into the graph config
   * @param allowedSeries existing series for this thingie
   */
  private _customizeDefaultGraphConfigs(list: IGraphConfig[], thingieId: string) {
    list.forEach(config => {
      const scatterConfig = config;
      const originalThingieId = scatterConfig.thingie;
      scatterConfig.uuid = generateNewTabUUID();
      scatterConfig.thingie = thingieId;
      scatterConfig.internalSeriesList.forEach((series: IDataSeries) => {
        if (isThingieSeries(series) || isAnnotationSeries(series)) {
          if (series.identification.thingieId === undefined || series.identification.thingieId === originalThingieId) {
            series.identification.thingieId = thingieId;
          }
        }

        if (isScatterSeries(series)) {
          // Selected calibration offline data series shall reference the current thingie
          const calibrationOfflineDataKey = series.valueConfig.calibration?.offlineDataKey;
          if (
            calibrationOfflineDataKey !== undefined && (
              calibrationOfflineDataKey.task.thingie === undefined ||
              calibrationOfflineDataKey.task.thingie === originalThingieId
            )
          ) {
            calibrationOfflineDataKey.task.thingie = thingieId;
          }
        }
      });
    });
  }

  /**
   * Remove graph configs with non-existent thingie series as a consistency check.
   * It is less noticeable and therefore more confusing for a user when parts of a graph vanish,
   * compared to a graph not being copied at all
   *
   * @param list list of graph configs to check
   * @param allowedSeries series that actually exist on the thingie
   */
  private _defaultGraphConfigConsistencyCheck(list: IGraphConfig[], allowedSeries: IDataSeries[]) {
    const invalidGraphConfigIndices: number[] = [];
    list.forEach((config, idx: number) => {
      config.internalSeriesList.forEach((series: IDataSeries) => {
        if (isThingieSeries(series)) {
          const thingieSeries = allowedSeries.find(thingieSeries => isSameDataSeries(thingieSeries, series));
          if (thingieSeries === undefined) {
            invalidGraphConfigIndices.push(idx);
          }
        }
      });
    });

    _.uniq(invalidGraphConfigIndices)
      .reverse()
      .forEach(idx => list.splice(idx, 1));
  }

  /**
   * Handles the pre-processing and creation of
   * the provided thingies of the same experiment.
   *
   * Pr-Processing includes
   * - {@link configureDeviceInputs}
   * - {@link preProcessReassignableInputs}
   * - {@link customizeDefaultGraphConfigs}
   *
   * @param thingies List of thingies of the same experiment
   */
  async preProcessAndCreate(thingies: IThingie[], templateThingie: IThingie) {
    this.createIdsAndRelateThingies(thingies);

    await this.preProcess(thingies, templateThingie);

    return await this.thingieService.batchCreateThingies(thingies);
  }

  async preProcess(thingies: IThingie[], templateThingie: IThingie) {
    await this.configureDeviceInputs(thingies.flatMap(thingie => thingie.processes.flatMap((process, processIdx) => process.tasks.map((task, taskIdx) => ({ key: { thingie: thingie._id!, processIdx, taskIdx }, task })))));

    thingies.forEach(t => {
      this.preProcessReassignableInputs(t, { thingie: undefined }, { thingie: t._id });
      this.preProcessConditionalOutputsAndInputs(t);
      this.customizeDefaultGraphConfigs(t, templateThingie);
    });
  }

  /**
   * Add IDs to thingies and relates the thingies
   * of the same experiment group
   *
   * @param thingies Thingies which need IDs
   */
  private createIdsAndRelateThingies(thingies: IThingie[]): void {
    thingies.forEach(current => {
      current._id = (new ObjectID()).toHexString();

      thingies.forEach(other => {
        if (other === current) {
          return;
        }

        other.relations.push({
          deleted: false,
          type: 'experimentGroup',
          relatedThingie: current._id!
        });
      });
    });
  }

  /**
   * Reset the states and relations of the thingies, such that they are ready
   * to be edited and created as new thingies. This also means to strip the id
   * of these thingies from them and replace it with `undefined`, because
   * later during creation `undefined` will be replaced with the new thingie's id.
   *
   * @param thingieList List of Thingies which should be reset
   */
  resetThingies(thingieList: IThingie[]) {
    this.resetTaskProcessesStates(thingieList);
    for (const thingie of thingieList) {
      // handle reassignable inputs
      // replace the original thingie ids with `undefined` in value and options
      this.removeOwnIdFromInputs(thingie);

      // reset thingie-level properties
      thingie._id = undefined;
      thingie.archived = false;
    }
  }

  removeOwnIdFromInputs(thingie: IThingie) {
    this.preProcessReassignableInputs(thingie, { thingie: thingie._id }, { thingie: undefined });
  }


  /**
   * Reset the states and relations of the thingies
   *
   * @param thingies Thingies which need IDs
   */
  private resetTaskProcessesStates(thingies: IThingie[]): IThingie[] {
    // reset start and stop of tasks and processes for other thingies
    thingies.forEach((t) => {
      t.processes.forEach(process => {
        process.started = undefined;
        process.stopped = undefined;
        process.annotations = [];
        process.tasks.forEach(task => purifyTask(task));
      });

      // don't copy relations
      t.relations = [];
    });

    return thingies;
  }

  /**
   * remove connected devices from the thingies
   *
   * @param thingies Thingies which need IDs
   */
  resetConnectedDevices(thingies: IThingie[]): IThingie[] {
    // reset start and stop of tasks and processes for other thingies
    thingies.forEach((t) => {
      t.processes.forEach(process => {
        process.tasks.forEach(task => {
          for (const input of Object.values(task.inputs)) {
            if (input.source !== 'device') {
              continue;
            }
            input.deviceAddress = undefined;
          }
        });
      });
    });

    return thingies;
  }

  /**
   * Extracts the tasks which were added in the detail page of thingie, and not included in the thingieTemplate.
   *
   * @param context
   * @returns a map object which represent the whole experiment added tasks
   */
  extractCustomStructureFromExperiment(context: IThingieCreationContext) {
    const processesMap: { [index: number]: IProcess } = {};
    context.thingies.forEach(thingie => {
      thingie.processes.forEach((process, pIndex) => {
        const newProcess = this.getCustomTasksFromProcess(process, pIndex, processesMap);
        if (newProcess !== null) {
          processesMap[pIndex] = newProcess;
        }
      });
    });

    return processesMap;
  }


  /**
   * Extracts the tasks which from a single thingie were added in the detail page of thingie, and not included in the thingieTemplate.
   *
   * @param context
   * @returns a map object which represent a thingie added tasks
   */
  extractCustomStructureFromThingie(thingie: IThingie) {
    const processesMap: { [index: number]: IProcess } = {};

    thingie.processes.forEach((process, pIndex) => {
      const newProcess = this.getCustomTasksFromProcess(process, pIndex, processesMap);
      if (newProcess !== null) {
        processesMap[pIndex] = newProcess;
      }
    });

    return processesMap;
  }


  /**
   * Extracts the tasks from each process which were added in the detail page of thingie, and not included in the thingieTemplate.
   *
   * @param context
   * @param process
   * @param pIndex
   * @param processesMap
   * @returns a new process which contains the extra tasks Or null which means no custom/added tasks were found
   */
  getCustomTasksFromProcess(process: IProcess, pIndex: number, processesMap: { [index: number]: IProcess }): IProcess | null {
    // check if there is a difference between the taskTemplates in templateThingie and currentThingie
    if (process.tasks.some(task => task.duplicated === true)) { // if there is a difference
      const newProcess = deepCopySync(process);

      if (pIndex in processesMap) {
      // update the existing process with same pIndex
        newProcess.tasks = processesMap[pIndex].tasks;
        newProcess.taskTemplates = processesMap[pIndex].taskTemplates;
      } else {
        newProcess.tasks = []; // empty the tasks
        // empty the taskTemplates
        newProcess.taskTemplates = [];
      }

      // extract the duplicated tasks
      process.tasks.forEach(t => {
        if (t.duplicated === true) {
          newProcess.tasks.push(t);
        }
      });

      return newProcess;
    }

    return null;
  }

  /**
   * Update the patch to have added tasks at the end of the list.
   *
   * @param patch
   */
  appendAddedTasksToPatch(patch: jsonPatch.Operation[]): void {

    /**
     * Using "-" instead of the index of the task to add the task to the end of the list.
     * Adjust the paths of the patch for the new tasks
     */
    patch.forEach((operation: any) => {
      if (operation.op === 'add' && /\/processes\/.*\/(taskTemplates|tasks)\/\d+$/.test(operation.path)) {
        operation.path = operation.path.replace(/\/\d+$/, '/-');
      }
    });
  }


  /**
   * Update the patch with the correct indices of the tasks for replicates that contain duplicated tasks.
   * For example, if the second task in a replicate is duplicated, the third position in the list
   * will no longer correspond to the third task in the template.
   * In other words, duplicated tasks shift the list positions where they are added.
   *
   * @param patch
   * @param thingie
   * @returns Updated the patch with the correct indices for each individual replicate
   */
  updatePatchWithCorrectIndices(patch: jsonPatch.Operation[], thingie: IThingie): jsonPatch.Operation[] {
    // Create a deep copy of the patch because of different indices for different replicates
    const patchCopy = deepCopySync(patch);

    // Create a map of wrong indices to correct indices
    const indexMap = new Map<string, string>();

    // Check the indices to find different ones which should be same. This shows, that a task is duplicated.
    thingie.processes.forEach((process, processIdx) => {
      const originalIndexesWithoutDuplicatedTasks = process.tasks
        .map((task, index) => ({ task, index }))
        .filter(({ task }) => !task.duplicated)
        .map(({ index }) => index);

      originalIndexesWithoutDuplicatedTasks.forEach((originalIndex, templateIndex) => {
        // check if the Index from the templates is different from the index in the replicate.
        if (originalIndex !== templateIndex) {
          // create a map of the wrong path to the correct path
          const wrongPath = `/processes/${processIdx}/tasks/${templateIndex}/`;
          const correctPath = `/processes/${processIdx}/tasks/${originalIndex}/`;
          indexMap.set(wrongPath, correctPath);
        }
      });
    });

    // If there are any wrong indices
    if (indexMap.size > 0) {
      patchCopy.forEach((operation: any) => {
        // Get the correct path from the map
        for (const [wrongPath, correctPath] of indexMap.entries()) {
          // Check if the operation path includes the wrong path
          if (operation.path.includes(wrongPath)) {
            // Replace the wrong path with the correct path in the operation path
            operation.path = operation.path.replace(wrongPath, correctPath);
            break;
          }
        }
      });
    }

    return patchCopy;
  }

}
