@if (title | pureFnCall: extractChips; as info) {
  @if (!showChipsInTooltip) {
    <ng-container *ngTemplateOutlet="chips"></ng-container>
  } @else {
    <span
      [mtxTooltip]="chips"
      mtxTooltipClass="tooltip-asd"
    >
      {{ info.name }}
    </span>
  }
  <!-- <div
    class="flex gap-x-1 gap-y-0.5 my-0.5 items-baseline flex-1 justify-end flex-wrap"
    [mtxTooltip]="showChipsInTooltip ? chips : ''"
    mtxTooltipClass="tooltip-asd"
  >
      <span
        [matTooltip]="info.name"
      >
        {{ info.name }}
      </span>
      <span class="flex-1"></span>
      @if (!showChipsInTooltip) {
        <ng-container *ngTemplateOutlet="chips"></ng-container>
      }
  </div> -->

  <ng-template #chips>
    <div
      class="flex gap-x-1 gap-y-0.5 my-0.5 items-baseline flex-1 justify-end flex-wrap"
      >
    <!-- content project additional chips -->
      <span>
        {{ info.name }}
      </span>
      <span class="flex-1"></span>
      <ng-content></ng-content>
      @for (chip of info.chips; track chip.name) {
        <div
          class="
            h-5 px-2
            rounded
            text-[12px] leading-5 font-semibold whitespace-nowrap
          "
          [attr.color]="chip.color"
          [matTooltip]="chip.name"
          [class.bg-orange-100]="chip.color === 'orange'"
          [class.text-orange-600]="chip.color === 'orange'"
          [class.bg-blue-100]="chip.color === 'blue'"
          [class.text-blue-600]="chip.color === 'blue'"
          [class.bg-indigo-100]="chip.color === 'indigo'"
          [class.text-indigo-600]="chip.color === 'indigo'"
          [class.bg-azure-100]="chip.color === 'azure'"
          [class.text-azure-600]="chip.color === 'azure'"
        >
          {{chip.name}}
        </div>
      }
    </div>
  </ng-template>
}