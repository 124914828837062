import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSelector]'
})
export class SelectorDirective {
  constructor(private el: ElementRef) {}

  get element() {
    return this.el.nativeElement;
  }
}
