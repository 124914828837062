import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { INotification } from 'src/app/model/notification/notification';
import { DefaultConfig } from 'src/assets/default.config';
import { IDataEvent, INotificationFilter, WebsocketConnectionService } from '../../common/websocket-connection.service';
import { NotificationService } from '../notification.service';

@Injectable()
export class NotificationServiceImpl implements NotificationService {
  notificationUrl = DefaultConfig.uris.notification;
  notificationListUrl = DefaultConfig.uris.notificationList;
  unreadNotificationsUrl = DefaultConfig.uris.unreadNotifications;
  unreadNotificationsCountUrl = DefaultConfig.uris.unreadNotificationsCount;
  notificationDismissUrl = DefaultConfig.uris.notificationDismiss;
  websocketUri = DefaultConfig.uris.notificationsWebsocket;
  checkVersionUpdateUrl = DefaultConfig.uris.notificationCheckVersionUpdate;

  constructor(private httpClient: HttpClient, private websocketConnection: WebsocketConnectionService) {
  }

  getUnreadNotificationsCount(): Observable<number> {
    return this.httpClient.get<number>(this.unreadNotificationsCountUrl);
  }

  getNotification(id: string): Observable<INotification> {
    return this.httpClient.get<INotification>(this.notificationUrl.replace('{id}', encodeURIComponent(id)));
  }

  getNotifications(id: string, pageSize: number): Observable<INotification[]> {
    return this.httpClient.get<INotification[]>(
      this.notificationListUrl.replace('{id}', encodeURIComponent(id)).replace('{limit}', encodeURIComponent(pageSize))
    );
  }

  getUnreadNotifications(id: string, pageSize: number): Observable<INotification[]> {
    return this.httpClient.get<INotification[]>(
      this.unreadNotificationsUrl.replace('{id}', encodeURIComponent(id)).replace('{limit}', encodeURIComponent(pageSize))
    );
  }

  subscribeUpdates(): Observable<any> {
    const requestFilter: INotificationFilter = {
      filterType: 'NotificationFilter'
    };

    return this.websocketConnection.subscribeWithFilter<INotification>(this.websocketUri, requestFilter)
      .pipe(map((event: IDataEvent<INotification>) => event.data));
  }

  checkSoftwareUpdate(): Promise<boolean> {
    return lastValueFrom(this.httpClient.get<boolean>(DefaultConfig.uris.notificationCheckVersionUpdate));
  }

  delete(id: string): Promise<void> {
    return lastValueFrom(this.httpClient.delete<void>(this.notificationUrl.replace('{id}', encodeURIComponent(id)), {}));
  }

  dismiss(id: string): Promise<void> {
    return lastValueFrom(this.httpClient.post<void>(this.notificationDismissUrl.replace('{id}', encodeURIComponent(id)), {}));
  }

  undismiss(id: string): Promise<void> {
    return lastValueFrom(this.httpClient.delete<void>(this.notificationDismissUrl.replace('{id}', encodeURIComponent(id)), {}));
  }
}
