<div >
  <!-- Wrapping required for automatic line breaks -->
  <mat-chip-list #chipList>
    <mat-chip
      class="custom-chip"
      selected
      *ngFor="let item of selectedItems"
      [removable]="true"
      (removed)="onChipRemoval($event)"
      [value]="item"
      [matTooltip]="item.itemDisplayDescription ? item.itemDisplayDescription : ''"
      [matTooltipDisabled]="item.itemDisplayDescription === undefined">
      {{ item.itemDisplayText }}
      <span matChipRemove>
        <ion-icon name="close-circle-outline"></ion-icon>
      </span>
    </mat-chip>
  </mat-chip-list>
</div>


<div>
  <mat-form-field>
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      (optionSelected)="onOptionSelected($event)"
      [displayWith]="autoCompleteDisplayFn">
      <mat-option
        *ngFor="let item of possibleSelections | async" [value]="item"
        [matTooltip]="item.itemDisplayDescription ? item.itemDisplayDescription : item.itemDisplayText">
        <small>{{ item.itemDisplayText }}</small>
      </mat-option>
    </mat-autocomplete>
    <input
      (keyup.enter)="onEnterKeyUp()"
      #searchInput
      placeholder="Type to search"
      matInput
      [matAutocomplete]="autocomplete"
      [formControl]="searchFormControl" />
      <ion-icon name="chevron-down-outline"></ion-icon>
  </mat-form-field>
</div>
