import { IThingie } from '../thingie/thingie';

export enum SearchResultType {
  Thingie = 'Thingie',
  Device = 'Device',
  Other = 'Other'
}

interface SearchResultMetaInfo {
  type: SearchResultType;
  metaSearch: {
    rematch: any;
    baseScore: number;
    fieldWeight: number;
    fieldpath: string;
    fieldvalue: any;
    searchTerm: string;
  }[];
  matchScore: number;
}

export interface SearchResult {
  // Search result items have an additional meta field.
  items: (IThingie & { meta: SearchResultMetaInfo })[];
  totalmatched: number;
}
