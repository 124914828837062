import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IUpdateInfo } from 'src/app/services/updates/updates.service';

@Component({
  selector: 'app-update-alert',
  templateUrl: './update-alert.component.html',
  styleUrls: ['./update-alert.component.scss']
})
export class UpdateAlertComponent implements OnInit {
  public currentVersion?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUpdateInfo,
  ) {
  }

  ngOnInit(): void {
    this.currentVersion = this.data.currentVersion;
  }
}
