import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNoTabsPagination]'
})
export class NoTabsPaginationDirective implements AfterViewInit {
  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    const tabHeader = this.el.nativeElement.children[0];
    tabHeader.children[0].style.display = 'none'; // Backward pagination
    tabHeader.children[2].style.display = 'none'; // Forward pagination
  }
}
