<div
  mat-dialog-title
  class="!flex items-center"
>
  <h3 class="!m-0">Create from Turbo template</h3>
  <div class="flex-grow"></div>
  <button
    mat-dialog-close
    mat-icon-button
  >
    <ion-icon name="close-outline"></ion-icon>
  </button>
</div>

<ng-container *ngIf="_status$ | async as status">

  <ng-container *ngIf="status.type === 'loading'; else contentView">
    <mat-dialog-content class="justify-center items-center !p-0">
      <ion-skeleton-text [animated]="true" class="h-6"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="h-10"></ion-skeleton-text>
      <div class="mb-4"></div>
      <ion-skeleton-text [animated]="true" class="h-3"></ion-skeleton-text>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        mat-stroked-button
        [mat-dialog-close]="null"
      >
        Cancel
      </button>
    </mat-dialog-actions>
  </ng-container>


  <ng-template #contentView>

    <mat-dialog-content class="!p-0 relative">
      <ng-container *ngIf="status.type !== 'noThingies'; else hasNoThingiesMessage">
        <mat-form-field class="w-full relative">
          <mat-label
            class="inline-flex items-center pointer-events-auto"
            [matTooltip]="_nameTooltip"
            matTooltipClass="fdecedc0-39b079e04796-turbo-multiline-tooltip"
          >
            <span>
              Experiment name
            </span>

            <ion-icon
              name="information-circle-outline"
              class="ml-0.5 info-icon"
            ></ion-icon>
          </mat-label>
          <input
            matInput
            [formControl]="_experimentName"
            type="text"
          />
      
          <mat-error *ngIf="(_experimentName.statusChanges | async) === 'INVALID'">
            {{ _errorDescription }}
          </mat-error>

          <ion-spinner
            *ngIf="(_experimentName.statusChanges | async) === 'PENDING'"
            name="lines-small"
            class="absolute validator-running-spinner-position"
          ></ion-spinner>
        </mat-form-field>
      
        <div
          class="flex items-center mb-6"
        >

          <ion-icon src="/assets/svg/replicates-cards.svg"></ion-icon>
          <span class="ml-2">
            {{ _numberOfObjects }} {{_numberOfObjects === 1 ? 'object' : 'replicates'}} will be created in project&nbsp;
          </span>

          <!-- project selector -->
          <div class="inline-block">
            <mat-form-field
              class="removed-wrapper-padding placeholder-border"
              [class.border-color-red]="
                _selectedProjectId === null
                && status.projectSelectionRequired
              "
            >
              <!-- 
                class 'placeholder-border' => takes up space, so "adding" the 
                real 'required-input'-border does not make the layout jump
               -->
              <mat-select
                [required]="status.projectSelectionRequired"
                [(ngModel)]="_selectedProjectId"
                placeholder="Select project"
                class="placeholder-border"
              >
                <mat-option
                  *ngFor="let project of status.projects"
                  [value]="project._id"
                  [matTooltip]="project.description"
                >
                  {{ project.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </ng-container>
    
      <ng-template #hasNoThingiesMessage>
        <div class="w-full flex justify-center items-center">
          <span>The template contains no objects</span>
        </div>
      </ng-template>

      <div
        *ngIf="_tryingCreation"
        class="absolute top-0 left-0 w-full h-full
         flex items-center justify-center z-10
         backdrop-blur-sm"
      >
        <mat-spinner></mat-spinner>
      </div>
    </mat-dialog-content>
    
    
    <mat-dialog-actions class="gap-2">
      <button
        mat-stroked-button
        [mat-dialog-close]="null"
        [disabled]="_tryingCreation"
      >
        Cancel
      </button>
    
      <div
        matTooltip="Unavailable: because of the template's configuration device assignment, 
        the setup cannot be started from this dialog"
        [matTooltipDisabled]="status.type === 'creatableWithAction'"
      >
        <button 
          mat-stroked-button
          color="primary"
          (click)="_create('runAfterCreation', status)"
          [disabled]="
            (_experimentName.statusChanges | async) !== 'VALID'
            || _tryingCreation
            || status.type === 'creatable'
            || _selectedProjectId === null
          "
        >
          Create &amp; {{ status.possibleAction }}
        </button>
      </div>
    
      <button 
        mat-flat-button
        color="primary"
        (click)="_create()"
        [disabled]="
          (_experimentName.statusChanges | async) !== 'VALID' || _tryingCreation
          || _selectedProjectId === null
        "
      >
        Create
      </button>
    </mat-dialog-actions>

  </ng-template>


</ng-container>

