import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { deepCopySync } from 'src/app/utility/deep-copy';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';

export interface ITime {
  hour: number;
  minute: number;
  second: number;
}

export { ITime as ITimePickerResult };

function num2TimeStr(val: number): string {
  return val.toString()
    .slice(-2)
    .padStart(2, '0');
}

@Component({
  selector: 'app-time-picker',
  template: `
    <div class="wrapper">
      <input
        type="number"
        min="0"
        max="23"
        [(ngModel)]="hour"
        (input)="onInputChange('hour', $event)"
        (blur)="onBlur($event)"
        [disabled]="disabled"
      />
      <span class="separator">:</span>
      <input
        type="number"
        min="0"
        max="59"
        [(ngModel)]="minute"
        (input)="onInputChange('minute', $event)"
        (blur)="onBlur($event)"
        [disabled]="disabled"
      />
      <span class="separator">:</span>
      <input
        type="number"
        [min]="minSecond > 59 ? 59 : minSecond"
        max="59"
        [(ngModel)]="second"
        (input)="onInputChange('second', $event)"
        (blur)="onBlur($event)"
        [disabled]="disabled"
      />

      <span>Hour</span>
      <span></span>
      <span>Min.</span>
      <span></span>
      <span>Sec.</span>
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-block;
        width: 108px;
        max-width: 108px;
        height: 47.75px;

        box-sizing: border-box;
      }

      :host * {
        box-sizing: inherit;
      }

      input {
        width: 27px;
        height: 32px;
        border: 1px solid #B3B3B3;
        border-radius: 2px;
        text-align: center;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .separator {
        width: 5px;

        margin: 0 auto;
        align-self: center;
      }

      .wrapper {
        display: grid;
        grid-template: 32px 16px / repeat(2, 27px 13px) 27px;
      }

      .wrapper > span:not(.separator) {
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        color: #B3B3B3;
        width: unset;
        margin: 0;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimePickerComponent)
    }
  ]
})
export class TimePickerComponent implements ControlValueAccessor {
  @HostListener('focusout')
  hostBlur() {
    this.touchFn();
  }

  private changeFn = (val: ITime) => {
    UNUSED_ARGS(val);
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- Noop by default
  private touchFn  = () => {};

  private notified = false;

  private previous: ITime = {
    hour: 0,
    minute: 0,
    second: 0
  };

  current: ITime = {
    hour: 0,
    minute: 0,
    second: 0
  };

  hour   = '00';
  minute = '00';
  second = '00';

  @Input()
  minSecond = 0;

  disabled = false;

  constructor() { }

  writeValue(obj?: ITime): void {
    if (obj) {
      this.previous = deepCopySync(this.current);
      this.current  = deepCopySync(obj);

      this.hour   = num2TimeStr(this.current.hour);
      this.minute = num2TimeStr(this.current.minute);
      this.second = num2TimeStr(this.current.second);
    }
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onInputChange(
    type: 'hour' | 'minute' | 'second',
    ev: Event
  ) {
    this.previous = deepCopySync(this.current);

    const elem: HTMLInputElement = ev.target as HTMLInputElement;

    const newValue = +elem.value;
    if (newValue < +elem.min) {
      elem.value = '00';
    } else if (newValue > +elem.max) {
      elem.value = elem.max;
    }

    if (type === 'hour') {
      this.current.hour = +elem.value;
    } else if (type === 'minute') {
      this.current.minute = +elem.value;
    } else {
      this.current.second = +elem.value;
    }

    this.signalChangeIfDifferent();
    this.previous = deepCopySync(this.current);
  }

  onBlur(ev: Event) {
    const elem: HTMLInputElement = ev.target as HTMLInputElement;

    // Enforce 2 digits
    if (elem.value.length < 2) {
      elem.value = '0'.repeat(2 - elem.value.length) + elem.value;
    } else if (elem.value.length > 2) {
      elem.value = elem.value.slice(-2);
    }

    if (this.current.hour * 3600 + this.current.minute * 60 + this.current.second < this.minSecond) {
      this.current.second = this.minSecond;
      this.second = num2TimeStr(this.minSecond);
    }

    this.signalChangeIfDifferent();
    this.previous = deepCopySync(this.current);
  }

  /**
   * Compares previous and current values
   * and - if different - signal the new value
   */
  private signalChangeIfDifferent() {
    const prev = this.previous;
    const curr = this.current;

    if (
      !this.notified &&
      (curr.hour !== prev.hour
      || curr.minute !== prev.minute
      || curr.second !== prev.second)
    ) {
      this.changeFn(deepCopySync(curr));
    }
  }
}
