import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IValueConfigCommonConfiguration } from 'src/app/model/values-config';

export type Value = [
  IValueConfigCommonConfiguration['displayRangeYAxis']['min'],
  IValueConfigCommonConfiguration['displayRangeYAxis']['max']
];

@Component({
  selector: 'app-yaxis-limits-input',
  templateUrl: './yaxis-limits-input.component.html',
  styleUrls: ['./yaxis-limits-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YAxisLimitsInputComponent {
  @Input()
  set value(val: Value | undefined) {
    if (!val) {
      this.clear();
    } else {
      this._min = val[0];
      this._max = val[1];
    }
  }

  @Input()
  disabled = false; // TODO: propagate

  @Output()
  valueChange = new EventEmitter<Value>();

  _max: Value[0] = null;
  _min: Value[1] = null;
  _hasValues = true;

  constructor() { }

  // TODO: ensure the first value is lower than the second

  clear() {
    this._min = this._max = null;
    this._emit();
  }

  _emit() {
    this.valueChange.emit([
      this._min,
      this._max
    ]);
  }
}
