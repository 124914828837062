import { assertInInjectionContext, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ThingieService } from 'src/app/services/thingie/thingie-service.service';
import { DefaultConfig } from 'src/assets/default.config';

/**
 * Return validator function which runs a check against
 * the experiment name.
 *
 * If `idOfValue` is provided and an existing entry
 * with that ID is encountered, the latter is ignored
 * in the comparison.
 *
 * @param idOfValue
 * @param thingieService (optional) ThingieService instance to use for the check
 *    (if not provided, it will be injected).
 * @returns AsyncValidatorFn
 */
export function createExperimentNameTakenValidator(
  idOfValue: string | undefined,
  thingieService?: ThingieService
): AsyncValidatorFn {
  let service = thingieService;
  if (!service) {
    assertInInjectionContext(createExperimentNameTakenValidator);
    service = inject(ThingieService);
  }

  return (control: AbstractControl) => of(control.value).pipe(
    debounceTime(DefaultConfig.general.userInputDefaultDebounce_Milliseconds),
    distinctUntilChanged(),
    switchMap(value => service!.checkForTakenNames([{
      name: value,
      id: idOfValue
    }])),
    map(takenValues => {
      if (takenValues.length === 0) {
        return null;
      }

      return {
        experimentNameTaken: `Name ${control.value} is taken`
      };
    })
  );
}
