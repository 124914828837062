import { CdkConnectedOverlay, ConnectedPosition, Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { FlatpickrDirective } from 'angularx-flatpickr';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { LogService } from 'src/app/services/loggers/logger.service';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';
import { SubSink } from 'subsink';
import { ITimePickerResult } from '../input-controls/time-picker/time-picker.component';


type FlatPickrOutputOptions = NonNullable<Parameters<FlatpickrDirective['flatpickrChange']['emit']>[0]>;


/**
 * The result type of the range-date-time picker.
 *
 * 'to' will be `undefined` for 'single'-mode.
 */
export interface IRangeDateTimePickerResult {
  // Prefferably use -Infinity(NegativeInfinity) and Infinity(PositiveInfinity)
  // once availbale as types
  // (see https://github.com/microsoft/TypeScript/issues/32277)

  from: DateTime | 'INFINITY';

  /**
   * Will be 'undefined' in 'single'-mode
   */
  to?: DateTime | 'INFINITY';
}

export type IRangeDateTimePickerOptions = Partial<IRangeDateTimePickerResult>;

@Directive({
  selector: '[appRangeDateTimePickerLabel]'
})
export class RangeDateTimePickerLabelRefDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

type PickrResult = { from: DateTime; to?: DateTime };

/**
 * Range-enabled date-time picker
 *
 * To change the label add an <ng-template>
 * with attribute 'appRangeDateTimePickerLabel'
 * as a child to the picker element.
 *
 * Example:
 * ````
 * <app-range-date-time-picker>
 *   <ng-template appRangeDateTimePickerLabel>...</ng-template>
 * </app-range-date-time-picker>
 * ````
 *
 * To change the labels for start and end time
 * add the replacement element as a child and use the selector
 * attributes 'from' & 'to'
 *
 * Example:
 * ````
 * <app-range-date-time-picker>
 *   <span from>...</span>
 *   <span to>...</span>
 * </app-range-date-time-picker>
 * ````
 */
@Component({
  selector: 'app-range-date-time-picker',
  templateUrl: './range-date-time-picker.component.html',
  styleUrls: ['./range-date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RangeDateTimePickerComponent)
    }
  ]
})
export class RangeDateTimePickerComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @HostListener('document:click', ['$event'])
  onDocumentClick($event: MouseEvent) {
    if (
      !($event.target as Element).closest('button:not(.rdtp8860-ab04d5dd0414-icon)')
      &&
      (
        ($event.target as Element).closest(`#${this._formFieldID}`)
        || ($event.target as Element).closest(`#${this._wrapperID}`)
      )
    ) {
      this.showing = true;
      this.touchFn();
      return;
    }

    this.showing = false;
    this.touchFn();
  }

  private changeFn = (val: IRangeDateTimePickerResult) => {
    UNUSED_ARGS(val);
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- noop by default
  touchFn  = () => {};

  private _mode: 'single' | 'range' = 'range';
  @Input()
  set mode(val: 'single' | 'range') {
    this._mode = val;

    if (this.firstInitializationDone) {
      this.initialize();
    }
  }

  get mode() {
    return this._mode;
  }

  private _initValue: IRangeDateTimePickerOptions | undefined = undefined;

  @Input()
  set value(val: IRangeDateTimePickerOptions) {
    if (this.firstInitializationDone) {
      this.writeValue(val);
    } else {
      this._initValue = val;
    }
  }

  @Output()
  valueChange = new EventEmitter<IRangeDateTimePickerResult>();

  /**
   * Notify change listeners when values change.
   *
   * In contrast, if set to false, notify listeners
   * only when the save button is pressed.
   */
  @Input()
  notifyOnChanges = false;

  @Input()
  set disabled(nowDisabled: boolean) {
    if (this._userInputControl) {
      if (!nowDisabled && this._disabled) {
        this._userInputControl.enable({ emitEvent: false });
      } else if (nowDisabled && !this._disabled) {
        this._userInputControl.disable({ emitEvent: false });
      }
    }

    this._disabled = nowDisabled;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  allowAutoEnd = false;

  @Input()
  autoEndTooltip: string | undefined;

  @Input()
  allowAutoStart = false;

  @Input()
  autoStartTooltip: string | undefined;

  @ViewChild(FlatpickrDirective)
  pickr!: FlatpickrDirective;

  @ViewChild(CdkConnectedOverlay)
  overlay!: CdkConnectedOverlay;

  readonly overlayScrollStrategy!: ScrollStrategy;

  overlayPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    }
  ];

  @ContentChild(RangeDateTimePickerLabelRefDirective)
  labelContent!: RangeDateTimePickerLabelRefDirective;

  readonly flatpickrControl = new FormControl();

  private readonly dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';

  get formatHint(): string {
    if (this.mode === 'single') {
      return `Required format is ${this.dateTimeFormat}`;
    } else {
      return `Required format is ${this.dateTimeFormat} to ${this.dateTimeFormat}`;
    }
  }

  private readonly flatpickrParsingFormat = 'dd-MM-yyyy';

  private readonly _patternPart = String.raw`(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4} ([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])`;
  private autoStartWordsRegex   = /^[aA]uto(?:matic)?(?: (?:[sS]tart|[bB]eginning))?$/;
  private autoEndWordsRegex     = /^[aA]uto(?:matic)?(?: (?:[eE]nd|[sS]top))?$/;

  get pattern() {
    const autoStartPattern = this.autoStartWordsRegex.source.slice(1, -1);
    const autoEndPattern   = this.autoEndWordsRegex.source.slice(1, -1);
    if (this.mode === 'single') {
      return `^${autoStartPattern}|${this._patternPart}$`;
    } else {
      return `^(?:${autoStartPattern}|${this._patternPart}) (-|to) (?:${autoEndPattern}|${this._patternPart})$`;
    }
  }

  private _userInputControl!: FormControl;
  get userInputControl() {
    return this._userInputControl;
  }

  autoStartControl!: FormControl;
  autoEndControl!: FormControl;

  readonly fromTime = new FormControl<ITimePickerResult | null>(null);
  readonly toTime   = new FormControl<ITimePickerResult | null>(null);

  private subs = new SubSink();

  private firstInitializationDone = false;

  showing = false;

  private resizeObserver: ResizeObserver | undefined;

  private readonly hintElementBaseHeight = 14;

  private readonly autoStartDateTimeRepresentation = DateTime.fromMillis(0);
  private readonly autoEndDateTimeRepresentation   = DateTime.utc().plus({ years: 300 });

  private calendarValueUpdates$ = new Subject<FlatPickrOutputOptions['selectedDates']>();

  private _disabled = false;

  _formFieldID: string | undefined = undefined;
  _wrapperID: string | undefined = undefined;

  constructor(
    private log: LogService,
    private ref: ElementRef,
    overlaySvc: Overlay,
  ) {
    this.generateUniqueIDs();
    this.overlayScrollStrategy = overlaySvc.scrollStrategies.reposition();
  }

  private generateUniqueIDs(): void {
    this._formFieldID = uniqueId('rangedatetimepickerfield-');
    this._wrapperID = uniqueId('rangedatetimepickerwrapper-');
  }

  writeValue(obj?: IRangeDateTimePickerOptions): void {
    if (!obj) {
      return;
    }

    let changed = false;

    let from: DateTime | 'INFINITY' | undefined;
    let to: DateTime | 'INFINITY' | undefined;

    // Only check and make changes while the user does not
    if (this._userInputControl.valid && !this.showing) {
      const dates = this.convertUserInputStringToDatesObject(this._userInputControl.value);
      from = dates.from;
      to = dates.to;

      //// Compare

      if (obj.from) {
        if (typeof obj.from !== typeof dates.from) {
          from = obj.from;
          changed = true;
        } else {
          if (obj.from instanceof DateTime) {
            if (!this.equalDateTimes(obj.from, dates.from as DateTime)) {
              from = obj.from;
              changed = true;
            }
          } else if (obj.from !== dates.from) {
            from = obj.from;
            changed = true;
          }
        }
      }

      if (obj.to) {
        if (!dates.to) {
          to = obj.to;
          changed = true;
        } else {
          if (typeof obj.to !== typeof dates.to) {
            to = obj.to;
            changed = true;
          } else {
            if (obj.to instanceof DateTime) {
              if (!this.equalDateTimes(obj.to, dates.to as DateTime)) {
                to = obj.to;
                changed = true;
              }
            } else if (obj.to !== dates.to) {
              to = obj.to;
              changed = true;
            }
          }
        }
      }

      // Update if changed
      if (changed) {
        const str = this.createUserInputStringFromOptions({ from, to });
        this._userInputControl.setValue(str);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
    if (this.valueChange.observers.length) {
      this.log.warn(`
        Both, (valueChange) and (ngModelChange) are used.
        You should use only one.
      `);
    }
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.initialize();
    this.firstInitializationDone = true;
  }

  ngAfterViewInit(): void {
    if (!this.resizeObserver) {
      this.resizeObserver = new ResizeObserver((entries => this.onElementResize(entries)));
      this.resizeObserver.observe(this.ref.nativeElement.querySelector('.user-input-hint'));
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.ref.nativeElement.querySelector('.user-input-hint'));
    }
    this.subs.unsubscribe();
  }

  onSave() {
    if (this._userInputControl.valid) {
      const obj = this.convertUserInputStringToDatesObject(this._userInputControl.value);
      this.changeFn(obj);
      this.valueChange.emit(obj);
      this.showing = false;
      this.touchFn();
    }
  }

  /**
   * Event forwarder method, because there could be multiple flatpickr-instances open
   * which makes attaching event handlers to their elements a nightmare
   */
  onFlatpickrValueUpdate($event: FlatPickrOutputOptions) {
    this.calendarValueUpdates$.next($event.selectedDates);
  }

  setToNow(which: 'start' | 'end'): void {
    const now = DateTime.now().toFormat(this.dateTimeFormat);

    const parts = this._userInputControl.value.split(/ (?:to|-) /);

    if (which === 'start') {
      parts[0] = now;
    } else if (this._mode === 'range' && parts.length === 2) {
      parts[1] = now;
    }

    this._userInputControl.setValue(parts.join(' to '));
  }

  private initialize() {
    this.subs.unsubscribe();

    this.initializeCheckboxes();
    this.initializeUserStringInput();
    this.intializeTimePickers();
    this.initializeFlatpickr();

    this.handlePickerInputChanges();
  }

  private initializeUserStringInput() {
    const value = this.createUserInputStringFromOptions(this._initValue);

    this._userInputControl = new FormControl(
      { value, disabled: this.disabled },
      [Validators.pattern(new RegExp(this.pattern)), Validators.required]
    );

    // Handle changes done by manipulating the
    // content of the user string input field

    this.subs.sink = this._userInputControl.valueChanges.pipe(
      filter(_ => this._userInputControl.valid),
      map((value: string) => this.convertUserInputStringToDatesObject(value))
    ).subscribe(dates => {
      // Update flatpickr & time pickers
      //
      // Emitting the change events is not required
      // and would lead to the change picked up, being emitted
      // so we would end up here again, going into a theoretically-
      // infinite loop.

      const dontEmit = { emitEvent: false };

      let fromHour = 0;
      let fromMinute = 0;
      let fromSecond = 0;

      // String to be populated and passed to the flatpickr-
      // calendar instance to update it
      let flatpickrString: string;

      if (dates.from instanceof DateTime) {
        flatpickrString = dates.from.toFormat(this.flatpickrParsingFormat);

        fromHour   = dates.from.hour;
        fromMinute = dates.from.minute;
        fromSecond = dates.from.second;

        this.autoStartControl.setValue(false, dontEmit);
      } else {
        flatpickrString = this.autoStartDateTimeRepresentation.toFormat(this.flatpickrParsingFormat);
        this.autoStartControl.setValue(true, dontEmit);
      }

      if (dates.to) {
        let hour   = 23;
        let minute = 59;
        let second = 59;

        if (dates.to instanceof DateTime) {
          flatpickrString += ` to ${dates.to.toFormat(this.flatpickrParsingFormat)}`;

          hour   = dates.to.hour;
          minute = dates.to.minute;
          second = dates.to.second;

          this.autoEndControl.setValue(false, dontEmit);
        } else {
          flatpickrString += ` to ${this.autoEndDateTimeRepresentation.toFormat(this.flatpickrParsingFormat)}`;
          this.autoEndControl.setValue(true, dontEmit);
        }

        this.toTime.setValue({
          hour,
          minute,
          second
        } as ITimePickerResult, dontEmit);
      }

      // Tell the calendar what to show now
      this.flatpickrControl.setValue(flatpickrString, dontEmit);

      this.fromTime.setValue({
        hour: fromHour,
        minute: fromMinute,
        second: fromSecond
      } as ITimePickerResult, dontEmit);
    });
  }

  private createUserInputStringFromOptions(arg?: IRangeDateTimePickerOptions) {
    let value = DateTime.now().toFormat(this.dateTimeFormat);

    if (arg) {
      if (arg.from) {
        if (arg.from instanceof DateTime) {
          value = arg.from.toFormat(this.dateTimeFormat);
        } else {
          value = 'auto beginning';
        }
      }
    }

    if (this._mode === 'range') {
      value += ' to ';
      if (arg && arg.to) {
        if (arg.to instanceof DateTime) {
          value += arg.to.toFormat(this.dateTimeFormat);
        } else {
          value += 'auto end';
        }
      } else {
        value += DateTime.now().toFormat(this.dateTimeFormat);
      }
    }
    return value;
  }

  private initializeCheckboxes() {
    // Creating form controls in here to
    // enforece initialization order,
    // which might otherwise introduce sublte bugs.

    this.autoStartControl = new FormControl(false);
    this.autoEndControl   = new FormControl(false);

    this.updateCheckboxValues();

    this.setTimeFieldDisabledStates();
  }

  private updateCheckboxValues() {
    if (this._initValue) {
      if (this._initValue.from && this._initValue.from === 'INFINITY') {
        this.autoStartControl.setValue(true, { emitEvent: false });
      }

      if (this._initValue.to && this._initValue.to === 'INFINITY') {
        this.autoEndControl.setValue(true, { emitEvent: false });
      }
    }
  }

  private intializeTimePickers() {
    let value: ITimePickerResult;
    if (this._initValue?.from) {
      if (this._initValue.from instanceof DateTime) {
        value = {
          hour: this._initValue.from.hour,
          minute: this._initValue.from.minute,
          second: this._initValue.from.second
        };
      } else {
        value = {
          hour: this.autoStartDateTimeRepresentation.hour,
          minute: this.autoStartDateTimeRepresentation.minute,
          second: this.autoStartDateTimeRepresentation.second
        };
      }
    } else {
      const now = DateTime.now();
      value = {
        hour: now.hour,
        minute: now.minute,
        second: now.second
      };
    }

    this.fromTime.setValue(value);

    if (this._mode === 'range') {
      if (this._initValue?.to) {
        if (this._initValue.to instanceof DateTime) {
          value = {
            hour: this._initValue.to.hour,
            minute: this._initValue.to.minute,
            second: this._initValue.to.second
          };
        } else {
          value = {
            hour: this.autoEndDateTimeRepresentation.hour,
            minute: this.autoEndDateTimeRepresentation.minute,
            second: this.autoEndDateTimeRepresentation.second
          };
        }
      } else {
        const now = DateTime.now();
        value = {
          hour: now.hour,
          minute: now.minute,
          second: now.second
        };
      }

      this.toTime.setValue(value);
    }
  }

  private initializeFlatpickr() {
    let value = DateTime.now().toFormat(this.flatpickrParsingFormat);

    if (this._initValue) {
      if (this._initValue.from) {
        if (this._initValue.from instanceof DateTime) {
          value = this._initValue.from.toFormat(this.flatpickrParsingFormat);
        } else {
          value = this.autoStartDateTimeRepresentation.toFormat(this.flatpickrParsingFormat);
        }
      }
    }

    if (this._mode === 'range') {
      if (this._initValue && this._initValue.to) {
        if (this._initValue.to instanceof DateTime) {
          value += ` to ${this._initValue.to.toFormat(this.flatpickrParsingFormat)}`;
        } else {
          value += ` to ${this.autoEndDateTimeRepresentation.toFormat(this.flatpickrParsingFormat)}`;
        }
      } else {
        value += ` to ${value}`;
      }
    }

    this.flatpickrControl.setValue(value, { emitEvent: false });
  }

  /**
   * Set up handling of changes done to
   * the flatpickr instance and the
   * time pickers
   */
  private handlePickerInputChanges() {
    const timeFrom$  = this.fromTime.valueChanges.pipe(startWith(this.fromTime.value));
    const timeTo$    = this.toTime.valueChanges.pipe(startWith(this.toTime.value));
    const autoStart$ = this.autoStartControl.valueChanges.pipe(startWith(this.autoStartControl.value));
    const autoEnd$   = this.autoEndControl.valueChanges.pipe(startWith(this.autoEndControl.value));

    const pickr$ = this.prepareCalendarPickrUpdateObservable();

    type SubResult = [ITimePickerResult | null, ITimePickerResult | null, PickrResult, boolean, boolean];
    this.subs.sink = combineLatest([ timeFrom$, timeTo$, pickr$, autoStart$, autoEnd$])
      .pipe(pairwise())
      .subscribe(
        (
          [
            [,,, previousAutoStartEnabled, previousAutoEndEnabled],
            [timeFrom, timeTo, pickr, autoStartEnabled, autoEndEnabled]
          ]: [SubResult, SubResult]
        ) => {
          this.setTimeFieldDisabledStates();
          //// Update user input with picked values

          let start: DateTime = DateTime.now();
          let value: string;

          if (autoStartEnabled) {
            value = 'auto beginning';
            start = this.autoStartDateTimeRepresentation;
          } else if (timeFrom && pickr.from) {
            if (previousAutoStartEnabled) {
              start = DateTime.now();
            } else {
              start = pickr.from.set({
                second: timeFrom.second,
                minute: timeFrom.minute,
                hour: timeFrom.hour
              });
            }
            value = start.toFormat(this.dateTimeFormat);
          } else {
            value = '';
          }

          let end: DateTime | undefined;
          if (this.mode === 'range') {
            if (autoEndEnabled) {
              value += ' to auto end';
              end = this.autoEndDateTimeRepresentation;
            } else if (timeTo && pickr.to) {
              if (previousAutoEndEnabled) {
                end = DateTime.now();
              } else {
                end = pickr.to.set({
                  second: timeTo.second,
                  minute: timeTo.minute,
                  hour: timeTo.hour
                });
              }

              value += ' to ' + end.toFormat(this.dateTimeFormat);
            }
          }

          this._userInputControl.setValue(value, { emitEvent: false });
          this.flatpickrControl.setValue(
            this.buildFlatpickrString(start, end),
            { emitEvent: false }
          );

          if (this.notifyOnChanges) {
            //// Notify change listeners
            this.changeFn({
              from: start,
              to: end
            });

            this.valueChange.emit({
              from: start,
              to: end
            });
          }
        }
      );
  }

  private prepareCalendarPickrUpdateObservable() {
    const { initialStart, initialEnd } = this.determineInitialPickrObservableValues();

    const pickrUpdates$ = this.calendarValueUpdates$.pipe(
      startWith([initialStart, initialEnd])
    );

    const pickr$ = pickrUpdates$.pipe(
      filter((updates) => {
        if (this._mode === 'single' || this._mode === 'range' && updates.length === 2) {
          return true;
        }

        return false;
      }),
      map((dates: Date[]) => {
        const result: PickrResult = {
          from: DateTime.fromJSDate(dates[0])
        };

        if (this.mode === 'range') {
          result.to = DateTime.fromJSDate(dates[1]);
        }

        return result;
      })
    );
    return pickr$;
  }

  private determineInitialPickrObservableValues() {
    let initialStart: Date;
    if (this._initValue?.from instanceof DateTime) {
      initialStart = this._initValue.from.toJSDate();
    } else if (this._initValue?.from === 'INFINITY') {
      initialStart = this.autoStartDateTimeRepresentation.toJSDate();
    } else {
      initialStart = new Date();
    }

    let initialEnd: Date;
    if (this._initValue?.to instanceof DateTime) {
      initialEnd = this._initValue.to.toJSDate();
    } else if (this._initValue?.to === 'INFINITY') {
      initialEnd = this.autoEndDateTimeRepresentation.toJSDate();
    } else {
      initialEnd = new Date();
    }
    return { initialStart, initialEnd };
  }

  private onElementResize(entries: ResizeObserverEntry[]) {
    if (entries[0].borderBoxSize[0].blockSize > this.hintElementBaseHeight) {
      const offset = Math.ceil(this.hintElementBaseHeight);
      this.overlayPositions[0].offsetY = offset;
    } else {
      this.overlayPositions[0].offsetY = 0;
    }
  }

  private convertUserInputStringToDatesObject(
    value: string
  ): { from: DateTime | 'INFINITY'; to?: DateTime | 'INFINITY' } {
    const token = value.split(/ (?:-|to) /);
    let from: DateTime | 'INFINITY';

    if (this.autoStartWordsRegex.test(token[0])) {
      from = 'INFINITY';
    } else {
      from = DateTime.fromFormat(token[0], this.dateTimeFormat);
    }

    let to: typeof from | undefined;
    if (token.length === 2) {
      if (this.autoEndWordsRegex.test(token[1])) {
        to = 'INFINITY';
      } else {
        to = DateTime.fromFormat(token[1], this.dateTimeFormat);
      }
    }

    return { from, to };
  }

  private buildFlatpickrString(from: DateTime, to: DateTime | undefined): string {
    let result = from.toFormat(this.flatpickrParsingFormat);
    if (to) {
      result += ` to ${to.toFormat(this.flatpickrParsingFormat)}`;
    }

    return result;
  }

  private setTimeFieldDisabledStates(): void {
    const dontEmit = { emitEvent: false };

    if (this.autoEndControl.value) {
      this.toTime.disable(dontEmit);
    } else {
      this.toTime.enable(dontEmit);
    }

    if (this.autoStartControl.value) {
      this.fromTime.disable(dontEmit);
    } else {
      this.fromTime.enable(dontEmit);
    }
  }

  private equalDateTimes(dt1: DateTime, dt2: DateTime) {
    return  dt1.year ===  dt2.year
      && dt1.month === dt2.month
      && dt1.day === dt2.day
      && dt1.hour === dt2.hour
      && dt1.minute === dt2.minute
      && dt1.second === dt2.second;
  }
}
