import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ObjectID from 'bson-objectid';
import { concat } from 'lodash';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApplicationTemplate, IUserTemplate } from 'src/app/model/thingie/templates';
import { IThingie } from 'src/app/model/thingie/thingie';
import { IUserData } from 'src/app/model/userdata';
import { DefaultConfig } from 'src/assets/default.config';
import { IncludeTemplates, UserDataService, UserDataTypeMapping } from '../user-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataServiceImpl implements UserDataService {
  private urls = DefaultConfig.uris.userdata;
  private metaUrl = DefaultConfig.uris.usersettingTemplates;

  constructor(private http: HttpClient) { }

  getAllUserData<T extends keyof UserDataTypeMapping>(type: T): Observable<IUserData<UserDataTypeMapping[T]>[]> {
    return this.http.get<IUserData<UserDataTypeMapping[T]>[]>(this.urls.getUserData
      .replace('{type}', type));
  }

  getAllThingieUserData<T extends keyof UserDataTypeMapping>(
    type: T, userId: string, thingieId: string
  ): Observable<IUserData<UserDataTypeMapping[T]>[]> {
    return this.http.get<IUserData<UserDataTypeMapping[T]>[]>(this.urls.getThingieUserData
      .replace('{type}', type)
      .replace('{userid}', userId)
      .replace('{thingieid}', thingieId));
  }

  getUserData<T extends keyof UserDataTypeMapping>(type: T, userId: string, id: string): Observable<IUserData<UserDataTypeMapping[T]>> {
    return this.http.get<IUserData<UserDataTypeMapping[T]>>(this.urls.specificUserData
      .replace('{type}', type)
      .replace('{userid}', userId)
      .replace('{backendid}', id));
  }

  createUserData<T extends keyof UserDataTypeMapping>(
    type: T,
    userId: string,
    data: UserDataTypeMapping[T],
    visibility?: IUserData<any>['visibility']
  ): Observable<IUserData<UserDataTypeMapping[T]>> {
    const createUrl = this.urls.newUserData
      .replace('{userid}', userId)
      .replace('{type}', type);
    const body: IUserData<UserDataTypeMapping[T]> = {
      type,
      value: data,
      visibility,
      userId
    };
    return this.http.post<IUserData<UserDataTypeMapping[T]>>(createUrl, body);
  }

  updateUserData<T extends keyof UserDataTypeMapping>(
    type: T,
    userId: string,
    id: string,
    data: UserDataTypeMapping[T]
  ): Observable<IUserData<UserDataTypeMapping[T]>> {
    const body: IUserData<UserDataTypeMapping[T]> = {
      type,
      _id: id,
      value: data,
      userId
    };
    const updateUrl = this.urls.specificUserData
      .replace('{userid}', userId)
      .replace('{type}', type)
      .replace('{backendid}', id);
    return this.http.put<IUserData<UserDataTypeMapping[T]>>(updateUrl, body);
  }

  deleteUserData(type: keyof UserDataTypeMapping, userId: string, id: string): Observable<void> {
    const deleteUrl = this.urls.specificUserData
      .replace('{userid}', userId)
      .replace('{backendid}', id)
      .replace('{type}', type);
    return this.http.delete<void>(deleteUrl);
  }

  async getUserApplicationTemplates(): Promise<IApplicationTemplate[]> {
    const userAppTemps = (await lastValueFrom(this.getAllUserData('APPLICATION_TEMPLATE')))!;
    const userApplicationTemplates = userAppTemps.map(template => ({
      ...template.value,
      _id: template._id!
    }));

    return userApplicationTemplates;
  }

  // return all templates from the meta and user-data collection
  async getAllTemplates(include: IncludeTemplates = {}): Promise<IUserTemplate[]> {
    const userTemplateListMeta =  await this.getAllTemplateMeta(include);
    const userdataTemplateProfileList = (await lastValueFrom(this.getAllUserData('LIS_PROFILE').pipe(
      map(elements => elements.map(element => {
        const res: IUserTemplate =  element.value;
        return res;
      }))
    )))!;
    // // get p-values from user
    const userdataTemplatePValuesList = (await lastValueFrom(this.getAllUserData('LIS_P_VALUES')
      .pipe(
        map(elements => elements.map(element => {
          const res: IUserTemplate = element.value;
          return res;
        }))
      )))!;

    return concat(userTemplateListMeta, userdataTemplateProfileList, userdataTemplatePValuesList);
  }

  /**
   * Query the meta collection for user templates
   *
   * @param include Options to include different templates which are not returned
   *    by default (e.g. disabled).
   * @returns Promise, containing a list of {@link IUserTemplate}
   */
  private getAllTemplateMeta(include: IncludeTemplates): Promise<IUserTemplate[]> {
    let url = this.metaUrl;
    const queryParams: {
      includeFromPreviousVersion?: boolean;
      includeDisabled?: boolean;
    } = {};

    if (include.fromPreviousVersion) {
      queryParams.includeFromPreviousVersion = true;
    }
    if (include.disabled) {
      queryParams.includeDisabled = true;
    }

    return firstValueFrom(this.http.get<IUserTemplate[]>(url, { params: queryParams }));
  }

  getTemplate(userTemplateName: string): Observable<IUserTemplate> {
    return this.http.get<IUserTemplate>(this.metaUrl + `/${userTemplateName}`);
  }

  saveTemplate(userTemplate: IUserTemplate): Observable<IUserTemplate> {
    return this.http.post<IUserTemplate>(
      this.metaUrl,
      userTemplate
    );
  }

  deleteTemplate(userTemplateName: string): Observable<void> {
    return this.http.delete<void>(
      this.metaUrl + `/${userTemplateName}`
    );
  }

  async createUserApplicationTemplate(
    userId: string, thingies: IThingie[],
    templateThingie: IThingie,
    templateName: string
  ): Promise<IUserData<IApplicationTemplate>> {
    const appTemp: IApplicationTemplate = {
      _id: new ObjectID().toHexString(),
      name: templateName,
      thingies,
      templateThingie,
      descriptionTitle: templateName,
      descriptionBodyHTML: `<p>No description</p>`,
      vesselType: []
    };

    const res = (await lastValueFrom(this.createUserData('APPLICATION_TEMPLATE', userId, appTemp, 'PUBLIC')))!;
    return res;
  }

  createFakeTemplate(thingie: IThingie): IApplicationTemplate {
    return {
      _id: UserDataService.customTemplateIndicator,
      name: '',
      templateThingie: thingie,
      descriptionTitle: 'Unknown',
      descriptionBodyHTML: '<p>No description</p>',
      vesselType: [],
    };
  }
}
