import { IDataSeries } from './data-series';

/** Identification are used to give a better ...??? */
export interface IThingieSeriesIdentification {
  type: 'thingieSeries';
  thingieId?: string;
  processIndex: number;
  taskIndex: number;
  output: string;
  dimensionName: string;
  offlineData?: boolean;
}

export type GraphRowType = 'TOP' | 'CENTER' | 'BOTTOM';

export interface ISeriesRowLayout {

  /**
   * 0,1,2 (top, center, bottom)
   **/
  rowIndex: number;

  yAxisSide: 'LEFT' | 'RIGHT';
}

export type ThingieSeries = Omit<IDataSeries, 'identification'> & { identification: IThingieSeriesIdentification };

export function isThingieSeries(x: IDataSeries): x is ThingieSeries {
  return x.identification.type === 'thingieSeries';
}

export function isSameThingieSeriesIdentification(
  ID1: IThingieSeriesIdentification,
  ID2: IThingieSeriesIdentification
): boolean {
  return ID1.thingieId === ID2.thingieId
    && ID1.processIndex === ID2.processIndex
    && ID1.taskIndex === ID2.taskIndex
    && ID1.output === ID2.output;
}
