import { Component, Input } from '@angular/core';
import { ITask } from 'src/app/model/thingie/task';
import { IProcess, IThingie } from 'src/app/model/thingie/thingie';
import { UINotificationService } from 'src/app/services/ui-notification/uinotification.service';
import { ThingieStatusHelper, ThingieStatusInfo } from 'src/app/utility/thingie-status-helper';

@Component({
  selector: 'app-thingie-state-chip',
  templateUrl: './thingie-state-chip.component.html',
  styleUrls: ['./thingie-state-chip.component.scss']
})
export class ThingieStateChipComponent {
  @Input()
  set thingies(newThingies: IThingie[]) {
    const tasks = newThingies
      .flatMap(thingies => thingies.processes.flatMap(process => process.tasks));
    const state = ThingieStatusHelper.getTasksStatus(tasks);
    this.setState(state);
  }

  @Input()
  set thingie(newThingie: IThingie) {
    const state = ThingieStatusHelper.getThingieStatus(newThingie);
    this.setState(state);
  }

  @Input()
  set process(newProcess: IProcess) {
    const state = ThingieStatusHelper.getProcessStatus(newProcess);
    this.setState(state);
  }

  @Input()
  set task(newTask: ITask) {
    const state = ThingieStatusHelper.getTaskStatus(newTask);
    this.setState(state);
  }

  setState(state: ThingieStatusInfo) {
    this.info = state;
  }

  @Input()
  set state(state: ThingieStatusInfo) {
    this.setState(state);
  }

  info: Partial<ThingieStatusInfo> = {};

  constructor(private nt: UINotificationService) { }

  showErrors() {
    if (this.info.errorMessages !== undefined && this.info.errorMessages.length > 0) {
      void this.nt.showMessageDialog({
        title: 'Errors',
        message: this.info.errorMessages.join(' '),
        buttons: [{
          text: 'Close',
          color: 'primary',
          dialogResult: null,
          matButtonType: 'flat'
        }]
      });
    }
  }
}
