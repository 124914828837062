import { IDataSeries } from '../data-series';

export interface IDeviceSeriesIdentification {
  type: 'deviceSeries';
  deviceAddress: string;
  packetType: string;
  property: string;
  config?: { [key: string]: any };
  thingieId?: string;
}

export type DeviceSeries = Omit<IDataSeries, 'identification'> & { identification: IDeviceSeriesIdentification };

export function isDeviceSeries(x: IDataSeries): x is DeviceSeries {
  return x.identification.type === 'deviceSeries';
}

export function isSameDeviceSeriesIdentification(
  ID1: IDeviceSeriesIdentification,
  ID2: IDeviceSeriesIdentification
): boolean {
  return ID1.deviceAddress === ID2.deviceAddress
    && ID1.packetType === ID2.packetType
    && ID1.property === ID2.property;
}
