import { IAnnotationSeriesIdentification, isSameAnnotationSeriesIdentification } from './annotation-series';
import { IConfigLoadedSeriesData } from './config-loaded-series-data';
import { IDeviceSeriesIdentification, isSameDeviceSeriesIdentification } from './device/device-series';
import { IPacketContentDescription } from './device/IPacketContentDescription';
import { isSameThingieSeriesIdentification, IThingieSeriesIdentification } from './thingie-series';
import { IValuesConfiguration } from './values-config';

/**
 * Represents the base structure for more
 * specialized series "types".
 *
 * Note: properties starting with an underscore (_) are
 * frontend-only properties!
 */
export interface IDataSeries {
  identification: ISeriesIdentification;

  description?: string;

  alias: string;

  observed: boolean;

  dataItemDescription: IPacketContentDescription['items'];

  valueConfig: IValuesConfiguration;

  // @frontend-only
  _meta?: {
    appliedOffsets?: {
      [key: string]: boolean;
    };
  };
}

// create new type of series for future series.
export type ISeriesIdentification = IThingieSeriesIdentification | IDeviceSeriesIdentification | IAnnotationSeriesIdentification;

export function isSameDataSeries(s1: { identification: ISeriesIdentification }, s2: { identification: ISeriesIdentification }): boolean {
  return isSameIdentification(s1.identification, s2.identification);
}
export function isSameIdentification(s1: ISeriesIdentification, s2: ISeriesIdentification) {
  if (s1.type !== s2.type) {
    return false;
  }

  switch (s1.type) {
    case 'deviceSeries':
      return isSameDeviceSeriesIdentification(s1, s2 as IDeviceSeriesIdentification);
    case 'thingieSeries':
      return isSameThingieSeriesIdentification(s1, s2 as IThingieSeriesIdentification);
    case 'annotationSeries':
      return isSameAnnotationSeriesIdentification(s1, s2 as IAnnotationSeriesIdentification);
  }
}

type SubSeriesIdentification = { series: { identification: ISeriesIdentification }; isForecast: boolean };

export function isSameSubSeries(a: SubSeriesIdentification, b: SubSeriesIdentification) {
  return isSameDataSeries(a.series, b.series) && a.isForecast === b.isForecast;
}

export type IDataSeriesWithConfig<T extends IDataSeries> = (T & IConfigLoadedSeriesData & { name: string; nameShort: string });
