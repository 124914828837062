import { DatePipe, registerLocaleData } from '@angular/common';
import { deepCopySync } from 'src/app/utility/deep-copy';
import { IDeviceNotification, INotification, IThingieNotification, Severity } from './notification';

// Import all locales that we want to support.
// If one is added here, its codes need to be added to the importedLocales array as well.
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const importedLocales = ['en-us', 'de', 'de-DE'];


interface IEntryOptions {
  notification: INotification;
  icon: string;
  iconStyle: string;
  heading: string;
  headingHTML: string;
  message: string;
  source: string;
  severity: Severity;
  dismissed: boolean;
  firstOccurrence: string;
  lastOccurrence: string;
  lastOccurrenceTooltip: string;
  firstOccurrenceTooltip: string;
  numberOfOccurrences: number;
  uid: string;
}

export class NotificationEntry {
  private _notification: INotification;
  get notification() {
    return this._notification;
  }

  /**
   * Icon name
   */
  readonly icon: string;

  /**
   * inline CSS style for the icon
   */
  readonly iconStyle: string;

  readonly heading: string;
  readonly headingHTML: string;
  readonly message: string;
  readonly source: string;
  readonly severity: string;

  readonly firstOccurrence: string;
  readonly firstOccurrenceTooltip: string;

  readonly lastOccurrence: string;
  readonly lastOccurrenceTooltip: string;

  readonly numberOfOccurrences: number;

  readonly dismissed: boolean;

  /**
   * Unique vlaue by which the entry can be identified.
   *
   * e.g. to be used in ngFor as key
   */
  readonly uid: string;

  private constructor(opts: IEntryOptions) {
    this._notification          = deepCopySync(opts.notification);
    this.icon                   = opts.icon;
    this.iconStyle              = opts.iconStyle;
    this.heading                = opts.heading;
    this.headingHTML            = opts.headingHTML;
    this.message                = opts.message;
    this.source                 = opts.source;
    this.severity               = opts.severity;
    this.dismissed              = opts.dismissed;
    this.firstOccurrence        = opts.firstOccurrence;
    this.lastOccurrence         = opts.lastOccurrence;
    this.lastOccurrenceTooltip  = opts.lastOccurrenceTooltip;
    this.firstOccurrenceTooltip = opts.firstOccurrenceTooltip;
    this.numberOfOccurrences    = opts.numberOfOccurrences;
    this.uid                    = opts.uid;
  }

  static fromNotification(notification: INotification) {
    switch (notification.type) {
      case 'DeviceNotification':
        return NotificationEntry.fromDeviceTypeNotification(notification as IDeviceNotification);
      case 'ThingieNotification':
        return NotificationEntry.fromThingieTypeNotification(notification as IThingieNotification);
      default:
        return NotificationEntry.fromGenericNotification(notification);
    }
  }

  private static fromGenericNotification(notification: INotification) {
    const { name: icon, style: iconStyle } = NotificationEntry.determineIcon(notification);
    const { key, dismissed, firstOccurrence, _id: uid,
      lastOccurrence, message, numberOfOccurrences } = notification;
    const { heading, source, severity } = key;

    return new NotificationEntry({
      heading,
      headingHTML: heading,
      icon,
      iconStyle,
      message,
      notification,
      source,
      dismissed,
      firstOccurrence,
      firstOccurrenceTooltip: NotificationEntry.getNotificationTimeTooltip('first', notification),
      lastOccurrence,
      lastOccurrenceTooltip: NotificationEntry.getNotificationTimeTooltip('last', notification),
      severity,
      numberOfOccurrences,
      uid
    });
  }

  private static fromDeviceTypeNotification(notification: IDeviceNotification) {
    const replaceWithDeviceLink = (str: string) => str.replace(
      new RegExp(notification.deviceAddress, 'g'),
      `<a href="/devices/deviceAddress/${encodeURIComponent(notification.deviceAddress)}" class="routerLink">
        ${notification.deviceName}
      </a>`
    );

    const { name: icon, style: iconStyle } = NotificationEntry.determineIcon(notification);
    const { key, dismissed, firstOccurrence, _id: uid,
      lastOccurrence, message, numberOfOccurrences } = notification;
    const { heading, source, severity } = key;

    return new NotificationEntry({
      heading,
      headingHTML: replaceWithDeviceLink(heading),
      icon,
      iconStyle,
      message: replaceWithDeviceLink(message),
      notification,
      source: replaceWithDeviceLink(source),
      dismissed,
      firstOccurrence,
      firstOccurrenceTooltip: NotificationEntry.getNotificationTimeTooltip('first', notification),
      lastOccurrence,
      lastOccurrenceTooltip: NotificationEntry.getNotificationTimeTooltip('last', notification),
      severity,
      numberOfOccurrences,
      uid
    });
  }

  private static fromThingieTypeNotification(notification: IThingieNotification) {
    const replaceWithThingieLink = (str: string) => {
      const replacementStr = str.includes(notification.thingieId) ? notification.thingieId : notification.thingieName;

      return str.replace(
        new RegExp(replacementStr, 'g'),
        `<a href="/thingies/thingie/${encodeURIComponent(notification.thingieId)}" class="routerLink">
          ${notification.thingieName}
        </a>`
      );
    };

    const { name: icon, style: iconStyle } = NotificationEntry.determineIcon(notification);
    const { key, dismissed, firstOccurrence, _id: uid,
      lastOccurrence, message, numberOfOccurrences } = notification;
    const { heading, source, severity } = key;

    return new NotificationEntry({
      heading,
      headingHTML: replaceWithThingieLink(heading),
      icon,
      iconStyle,
      message: replaceWithThingieLink(message),
      notification,
      source: replaceWithThingieLink(source),
      dismissed,
      firstOccurrence,
      firstOccurrenceTooltip: NotificationEntry.getNotificationTimeTooltip('first', notification),
      lastOccurrence,
      lastOccurrenceTooltip: NotificationEntry.getNotificationTimeTooltip('last', notification),
      severity,
      numberOfOccurrences,
      uid
    });
  }

  /**
   * Determine icon to display and the inline icon style
   * for a given notification.
   *
   * @param entry - The notification entry
   * @returns The notification entry with the icon set to match
   * it's severity level
   */
  private static determineIcon(notification: INotification): { name: string; style: string } {
    const name = (() => {
      switch (notification.key.severity) {
        case 'WARNING':
          return 'warning-outline';
        case 'CRITICAL':
          return 'alert-circle-outline';
        case 'INFO':
        default:
          return 'information-circle-outline';
      }
    })();

    const style = 'color: ' + (() => {
      switch (notification.key.severity) {
        case 'WARNING':
          return 'orange';
        case 'CRITICAL':
          return '#D80E0E'; // red
        case 'INFO':
        default:
          return '#008BDC'; // blue
      }
    })() + ';';

    return {
      name,
      style
    };
  }

  /**
   * Create occurrence date tooltip.
   *
   * @param which - flag to determine if a last- or first-occurrence tooltip is to be created.
   * @param notification - The notification for which to create the tooltip
   * @returns The tooltip message
   */
  private static getNotificationTimeTooltip(which: 'first' | 'last', notification: INotification) {
    let toParse = '';
    let message = '';

    if (which === 'first') {
      toParse = notification.firstOccurrence;
      message = 'First occurrence';
    } else {
      toParse = notification.lastOccurrence;
      message = 'Most recent occurrence';
    }

    let locale = navigator.language ?? 'en-us';
    if (!importedLocales.includes(locale)) {
      locale = 'en-us';
    }
    const parsed = (new DatePipe(locale)).transform(toParse, 'long');

    return `${message} (${parsed})`;
  }
}
