import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { IProject } from 'src/app/model/user-management/project';
import { ProjectService } from 'src/app/services/project/project.service';
import { IGroupedThingies } from '../list-thingies/list-thingies.component';


interface IProjectInfo {
  projectName: string;
  projectId: string;
  projectNotEmpty: boolean;
}

@Component({
  selector: 'app-filter-by-project',
  templateUrl: './filter-by-project.component.html',
  styleUrls: ['./filter-by-project.component.scss']
})
export class FilterByProjectComponent implements OnInit {
  @Input()
  projectFilter = 'all';

  @Input()
  projectFilterLabel = '';


  @Output() filterByProjectCallbackFunction: EventEmitter<any> = new EventEmitter();
  projectInfo: IProjectInfo[] = [];
  private projectList: IProject[] = [];

  @Input()
  thingieGroups: IGroupedThingies[] = [];

  constructor(
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    void this.loadProjects();
  }

  filterThingieByProject(event: MatSelectChange) {
    this.projectFilter = event.value;

    this.updateProjectUI(this.projectFilter);

    // passiong the value to parent, then parent will save it with any type
    this.projectChangeCallbackFunction(this.projectFilter);
  }

  projectChangeCallbackFunction(projectFilter: string) {
    this.filterByProjectCallbackFunction.emit(projectFilter);
  }

  private async loadProjects() {
    this.projectList = await this.projectService.getAllProjects();
    this.updateProjectsInfo(this.thingieGroups);
    this.updateProjectUI(this.projectFilter);
  }

  private updateProjectsInfo(thingieGroups: IGroupedThingies[]) {
    this.projectInfo = this.projectList.map(project => ({
      projectName: project.title,
      projectId: project._id,
      projectNotEmpty: thingieGroups.some(thingieGroup => {
        thingieGroup.thingies.some(thingie => thingie.project === project._id);
      })
    }));
  }

  private updateProjectUI(id: string) {
    const selectedFilter = this.projectInfo.find(p => p.projectId === id);
    this.projectFilterLabel = selectedFilter?.projectName || 'All projects';
  }
}
