<div class="wrapper rounded" [class.has-data]="files.length > 0" id="drag-container" appFileDragAndDrop (fileDropped)="onFileDropped($event)">
  <input type="file" #fileDropRef id="fileDropRef" multiple [accept]="fileExtensions" (change)="fileBrowseHandler($event)" />  
  <span class="heading">Drop files here to upload or</span>
  <label for="fileDropRef">Select Files</label>
  <small *ngIf="showFilesizeLimitHint">Maximum file size: {{ filesizeLimitMB }}MB</small>
  <div class="files-list" *ngIf="isFileDropped && showDropState" [ngClass]="{'mt-5': files.length > 0}">
    <span
      *ngIf="isFileLoading && files.length > 0"
      class="heading bold">
        Files loading, please wait.
    </span>
    <span
      *ngIf="fileUploadError"
      class="heading bold">
        File upload finished with errors
    </span>
    <span
      class="heading bold"
      *ngIf="!isFileLoading && !fileUploadError">
        Files added successfully
    </span>
    <div *ngIf="!showAllFileList">
      <div class="link" *ngIf="files.length > 3">
        <ion-spinner *ngIf="allFilesUploading" name="crescent"></ion-spinner>
        <span (click)="showAllFiles()" class="file-count-label">+{{files.length - 3}} Files uploading</span>
      </div>
      <div *ngIf="fileErrorCount > 0">
        <ion-icon class="warning" name="alert-outline"></ion-icon>
        <span class="warning icon-align">Could not upload</span>
        <span (click)="showAllFiles()" class="link icon-align">{{fileErrorCount}}
          <span> File<ng-container *ngIf="fileErrorCount > 1">s</ng-container></span>
        </span>
      </div>
    </div>
    <div class="single-file" *ngFor="let file of files; let i = index">
      <div *ngIf="showAllFileList && i <=3">
        <ion-spinner *ngIf="file.progress < 100" name="crescent"></ion-spinner>
        <ion-icon class="checkmark" *ngIf="!file.warning && file.progress === 100" name="checkmark-outline"></ion-icon>
        <ion-icon class="warning" *ngIf="file.warning && file.progress === 100" name="alert-outline"></ion-icon>
        <ion-label *ngIf="!file.warning" class="file-label">{{file.file.name}}</ion-label>
        <ion-label *ngIf="file.warning && file?.progress === 100" class="file-label">
        <span class="warning">Could not upload</span> {{file.file.name}}. Check document size.</ion-label>
        <span class="delete" [matTooltip]="'delete file'" (click)="deleteFile(i)">
          <ion-icon name="trash-outline"></ion-icon>
        </span>
      </div>
    </div>
  </div>
</div>
