import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { CustomInputControlsModule } from '../input-controls/custom-input-controls.module';
import {
  RangeDateTimePickerComponent,
  RangeDateTimePickerLabelRefDirective
} from './range-date-time-picker.component';

@NgModule({
  declarations: [
    RangeDateTimePickerComponent,
    RangeDateTimePickerLabelRefDirective
  ],
  exports: [
    RangeDateTimePickerComponent,
    RangeDateTimePickerLabelRefDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule,
    IonicImports,
    MaterialImportsModule,
    CustomInputControlsModule
  ],
  providers: []
})
export class RangeDateTimePickerModule {}
