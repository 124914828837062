import { PlotData } from 'plotly.js';
import { IOfflineDataKey } from '../services/common/websocket-connection.service';
import { AnnotationSeries, isAnnotationSeries } from './annotation-series';
import { CalibrationFilePostProcessing } from './backend';
import { IDataSeries } from './data-series';
import { ISeriesRowLayout } from './thingie-series';

export interface IValueConfigCommonConfiguration {
  mode: 'MARKERS' | 'LINES' | 'MARKERS_LINES';
  color: string;
  positioning: ISeriesRowLayout;
  rangeToDisplay: {
    from?: string;
    to?: string;
  };
  relativeRangeToDisplay?: {

    /** in minutes */
    from?: number;

    /** in minutes */
    to?: number;
  };
  yAxisOffset: number;
  displayRangeYAxis: {
    enabled: boolean;
    min: number | null;
    max: number | null;
  };

  // Relative offset in minutes
  timeAxisOffset: number;
  // target date for auto offset
  timeAxisOffsetTarget?: number;

  // used to configurate plotlayout
  line?: PlotData['line'];
  marker?: PlotData['marker'];
}

export interface IScatterValuesConfiguration extends IValueConfigCommonConfiguration {
  type: 'scatterValues';

  unit: string;

  forecasting: {
    enabled: boolean;

    fittingModel: 'POLYNOMIAL_ORDER_1'
    | 'POLYNOMIAL_ORDER_2'
    | 'POLYNOMIAL_ORDER_3'
    | 'WEIGHTED_MULTIPLIER';

    /**
     * Hours
     */
    periodLength: number;
  };

  denoising: {
    enabled: boolean;

    intensity: 'LOW' | 'MEDIUM' | 'HIGH';

    interval: number[];
  };

  calibration?: {
    enabled: boolean;
    defaultDataId?: string;
    defaultData?: CalibrationFilePostProcessing;
    offlineDataKey?: IOfflineDataKey;
  };
}


export interface IAnnotationValuesConfiguration extends Omit<IValueConfigCommonConfiguration, 'type' |
'mode' |
'yAxisOffset' |
'displayRangeYAxis'
> {
  type: 'annotationValues';
}

export interface IArrayValuesConfiguration extends Omit<IScatterValuesConfiguration, 'type'> {
  type: 'arrayValues';
  mode3D: 'SURFACE' | 'SCATTER' | 'CONTOUR';
}

export type IValuesConfiguration = IAnnotationValuesConfiguration | IScatterValuesConfiguration | IArrayValuesConfiguration;

export type ScatterSeries = Omit<IDataSeries, 'valueConfig'> & { valueConfig: IScatterValuesConfiguration };

export type AnnotationValuesSeries = Omit<IDataSeries, 'valueConfig'> & { valueConfig: IAnnotationValuesConfiguration };

export function isScatterSeries(series: IDataSeries): series is ScatterSeries {
  return series.valueConfig.type === 'scatterValues';
}

export function isAnnotationValueSeries(series: IDataSeries): series is AnnotationValuesSeries {
  return series.valueConfig.type === 'annotationValues';
}

export function filterScatterSeries(series: IDataSeries[]) {
  return series.filter(isScatterSeries);
}

export function filterScatterOrAnnotationValueSeries(series: IDataSeries[]) {
  return series.filter(
    (s): s is ScatterSeries | AnnotationValuesSeries => isAnnotationValueSeries(s) || isScatterSeries(s)
  );
}

export function filterAnnotationSeries(series: IDataSeries[]) {
  return series.filter(
    (s): s is AnnotationSeries => isAnnotationSeries(s)
  );
}

export type ArraySeries = Omit<IDataSeries, 'valueConfig'> & { valueConfig: IArrayValuesConfiguration };

export function isArraySeries(series: IDataSeries): series is ArraySeries {
  return series.valueConfig.type === 'arrayValues';
}

export function filterArraySeries(series: IDataSeries[]) {
  return series
    .filter(isArraySeries);
}


