import { Injectable } from '@angular/core';
import { IProject, ProjectRole } from 'src/app/model/user-management/project';
import { IUser } from 'src/app/model/user-management/user';
import { ProjectServiceImpl } from './implementation/project.service-impl';

export type IProjectChanges = Omit<IProject, '_id'>;

export interface IGroupedMembers {
  viewers: IUser[];
  editors: IUser[];
}

export type IProjectCreationDTO = Omit<
  IProject,
'_id' | 'createdAt' | 'creator'
>;

/**
 * ProjectService - used to manage projects and members of projects.
 *
 * Notes
 *
 * - operations can only be performed on
 * entitites the acting user has access to with
 * the required permissions for that operation.
 * - only data that the acting user has access
 * permission to can be acquired.
 */
@Injectable({
  providedIn: 'root',
  useClass: ProjectServiceImpl
})
export abstract class ProjectService {

  /**
   * Create new project
   *
   * @param project The project to create. 'id' will be ignored.
   * @throws {ExistsAlreadyException} - A project with the same title exists already.
   */
  abstract createProject(project: IProjectCreationDTO): Promise<IProject>;

  /**
   * Obtain project by ID.
   *
   * @param projectId
   * @throws - Project could not be found.
   */
  abstract getProjectById(opts: { projectId: string }): Promise<IProject>;

  /**
   * Get all projects that belong to a team
   *
   * @param teamId
   */
  abstract getProjectsByTeamId(opts: { teamId: string }): Promise<IProject[]>;

  /**
   * Returns a list of all projects the logged in user is allowed to access
   */
  abstract getAllProjects(): Promise<IProject[]>;

  /**
   * Acquire a list of requested project objects
   */
  abstract getProjectListByIds(ids: string): Promise<IProject[]>;

  /**
   * Update an existing project
   *
   * @param project
   * @throws {NotFoundException} - The project does not exist.
   */
  abstract updateProject(opts: {
    projectId: string;
    changes: IProjectChanges;
  }): Promise<void>;

  /**
   * Make specified user become part of the project in the specified role.
   *
   * Changes the role if the user is part of the project already.
   *
   * @param opts
   * @throws {NotAMemberException} - The user is not a member of the team
   * @throws - The user could not be added
   */
  abstract addUserToProjectAsRole(opts: { projectId: string; userId: string; role: ProjectRole }): Promise<void>;

  /**
   * Revokes a users role permission
   * for a given project.
   *
   * This essentially removes the user from that role
   * for that particular project
   *
   * @param opts
   * @throws {NotAMemberException} - The user is not part of the project
   * @throws - The user could not be removed from the project
   */
  abstract revokeProjectRolePermissionForUser(opts: { projectId: string; userId: string; role: ProjectRole }): Promise<void>;

  /**
   * Returns editors and viewers of a given project
   *
   * @param opts
   * @throws - The list of editors & viewers could not be acquired
   */
  abstract getProjectMembers(opts: { projectId: string }): Promise<IGroupedMembers>;
}
