import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import DataLoader from 'dataloader';
import { DateTime } from 'luxon';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { IProject, ProjectRole } from 'src/app/model/user-management/project';
import { IUser } from 'src/app/model/user-management/user';
import { DefaultConfig } from 'src/assets/default.config';
import { UserService } from '../../user/user.service';
import { IGroupedMembers, IProjectChanges, IProjectCreationDTO, ProjectService } from '../project.service';

interface IProjectDTO extends Omit<IProject, 'createdAt' | 'team'> {
  createdAt: string;
  teamID: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectServiceImpl implements ProjectService {
  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  async createProject(project: IProjectCreationDTO): Promise<IProject> {
    return this.convertDtoToEntity(
      (await lastValueFrom(this.http.post<IProjectDTO>(DefaultConfig.projectManagement.project, project)))!
    );
  }

  getProjectByIdDataLoader = new DataLoader((projects) =>
    Promise.all(projects.map(projectId =>
      firstValueFrom(this.http.get<IProjectDTO>(`${DefaultConfig.projectManagement.project}/${encodeURIComponent(projectId)}`))))
      .then(data => {
        this.getProjectByIdDataLoader.clearAll();
        return data.map(project => this.convertDtoToEntity(project));
      }), {
    cacheKeyFn: (projectId: string) => projectId,
    batchScheduleFn: (cb) => setTimeout(() => cb(), 100)
  });
  async getProjectById(opts: { projectId: string }): Promise<IProject> {
    return this.getProjectByIdDataLoader.load(opts.projectId);
  }

  async getProjectsByTeamId(opts: { teamId: string }): Promise<IProject[]> {
    const projectList = await this.getAllProjects();

    return projectList.filter(p => p.teamID === opts.teamId);
  }

  async updateProject(opts: { projectId: string; changes: IProjectChanges }): Promise<void> {
    const uri = `${DefaultConfig.projectManagement.project}/${encodeURIComponent(opts.projectId)}`;

    (await lastValueFrom(this.http.put(uri, opts.changes)))!;
  }

  async addUserToProjectAsRole(opts: { projectId: string; userId: string; role: ProjectRole }): Promise<void> {
    let uri;
    if (opts.role === 'EDITOR') {
      uri = DefaultConfig.projectManagement.editor;
    } else {
      uri = DefaultConfig.projectManagement.viewer;
    }

    uri = uri
      .replace('{id}', encodeURIComponent(opts.projectId))
      .replace('{userId}', encodeURIComponent(opts.userId));

    (await lastValueFrom(this.http.post(uri, undefined)))!;
  }

  async revokeProjectRolePermissionForUser(opts: { projectId: string; userId: string; role: ProjectRole }): Promise<void> {
    let uri;

    if (opts.role === 'EDITOR') {
      uri = DefaultConfig.projectManagement.editor;
    } else {
      uri = DefaultConfig.projectManagement.viewer;
    }

    uri = uri
      .replace('{id}', encodeURIComponent(opts.projectId))
      .replace('{userId}', encodeURIComponent(opts.userId));

    (await lastValueFrom(this.http.delete(uri, undefined)))!;
  }

  async getProjectMembers(opts: { projectId: string }): Promise<IGroupedMembers> {
    const userList = await this.userService.getUserList();

    const editors: IUser[] = [];
    const viewers: IUser[] = [];

    // for each user in the list...
    for (const user of userList) {
      // ... acquire all the projects they are in...
      for (const project of Object.keys(user.projects)) {
        // ... and if those projects have the id of the specified project
        // (there can be only one, of course)...
        if (project === opts.projectId) {
          //... add the user to the respective list
          const role = user.projects[project];
          if (role === 'EDITOR') {
            editors.push(user);
          } else {
            viewers.push(user);
          }
        }
      }
    }

    return { editors, viewers };
  }

  async getAllProjects(): Promise<IProject[]> {
    return this.convertDtoListToEntityList(
      (await lastValueFrom(this.http.get<IProjectDTO[]>(DefaultConfig.projectManagement.project)))!
    );
  }

  async getProjectListByIds(ids: string): Promise<IProject[]> {
    const uri = DefaultConfig.projectManagement.projectsByIds.replace('{projectIDs}', encodeURIComponent(ids));

    const dtoList = (await lastValueFrom(this.http.get<IProjectDTO[]>(uri)))!;

    return this.convertDtoListToEntityList(dtoList);
  }

  private convertDtoToEntity(dto: IProjectDTO): IProject {
    return {
      _id: dto._id,
      archived: dto.archived,
      createdAt: DateTime.fromISO(dto.createdAt),
      creator: dto.creator,
      description: dto.description,
      teamID: dto.teamID,
      title: dto.title
    };
  }

  private convertDtoListToEntityList(dtoList: IProjectDTO[]): IProject[] {
    return dtoList.map(dto => this.convertDtoToEntity(dto));
  }
}
