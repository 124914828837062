import { Injectable } from '@angular/core';
import { ISearchOptions } from 'src/app/model/search/search-options';
import { SearchResult } from '../../model/search/search-result';
import { SearchServiceImpl } from './implementation/search.service';

@Injectable({
  providedIn: 'root',
  useClass: SearchServiceImpl
})
export abstract class SearchService {
  abstract search(options: ISearchOptions): Promise<SearchResult>;
}
