<div
  mat-dialog-title
  class="!flex items-center"
>
  <h3 class="!m-0">Manage Turbo templates</h3>
  <div class="flex-grow"></div>
  <button
    mat-dialog-close
    mat-icon-button
  >
    <ion-icon name="close-outline"></ion-icon>
  </button>
</div>
<mat-dialog-content>

  <ng-container *ngIf="_templates$ | async as templates">

    <!-- Dropdown with available and disabled user application templates -->
    <mat-form-field 
      matTooltip="Choose Turbo templates"
      class="removed-wrapper-padding w-full"
    >
      <mat-select
        [value]="undefined"
        (selectionChange)="_selectTurbo($event.value)"
        panelClass="f8fe61da-bac1c567175c-tselect"
        placeholder="Add templates"
        (openedChange)="searchBox.focus()"
        #select
      >
        <mat-option disabled>
          <div class="search-field">
            <ion-icon name="search"></ion-icon>
            <input type="text"
              placeholder="Type to search"
              [formControl]="_filterControl"
              #searchBox
            />
          </div>
        </mat-option>
  
        <mat-optgroup
          label="Available"
          matTooltip="Templates in this list can be used as Turbo Templates"
        >
          <mat-option 
            *ngFor="let option of templates.available"
            [value]="option"
          >
            <app-title-with-chips [title]="option.value.name"></app-title-with-chips>
          </mat-option>

          <mat-option
            *ngIf="templates.available.length === 0"
            disabled
          >
            <span>No available templates match your search</span>
          </mat-option>
        </mat-optgroup>

        <mat-optgroup
          *ngIf="templates.disabled.length > 0"
          label="Not available"
          matTooltip="Templates in this list are missing configuration or devices
           and can not be used as Turbo templates
          "
        >
          <mat-option 
            *ngFor="let option of templates.disabled"
            [disabled]="true"
          >
            <app-title-with-chips [title]="option.value.name"></app-title-with-chips>
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>


    <!-- Re-orderable list of Turbo Templates -->
    <div class="mt-[18px]">
      <h6
        class="turbos-list-heading"
        matTooltip="Drag templates on the handle to the left to customize your order"
      >
        <span>Selected</span>
        <ion-icon
          name="information-circle-outline"
          class="ml-1 w-4 h-4 align-middle info-icon"
        ></ion-icon>
      </h6>
        
      <ul
        *ngIf="templates.turbos.length > 0; else noTurbosMessage"
        class="list-none m-0 p-0"
        cdkDropList
        (cdkDropListDropped)="_reOrderElement($event)"
      >
        <li
          *ngFor="let template of templates.turbos"
          class="flex
          flex-nowrap
          items-center
          f8fe61da-bac1c567175c-drag-style
          py-1"
          cdkDrag
          (cdkDragStarted)="_setGrabbingCursor()"
          (cdkDragEnded)="_resetGrabbingCursor()"
        >
          <div
            cdkDragHandle
            class="hover:cursor-grab active:cursor-grabbing"
          >
            <ion-icon
              name="ellipsis-vertical"
              class="color-gray-300 align-middle"
            >
            </ion-icon>
          </div>

          <app-title-with-chips [title]="template.value.name" class="flex-1"></app-title-with-chips>

          <div
            class="ml-auto"
            (click)="_deselectTurbo(template)"
          >
            <button
              mat-icon-button
              class="w-unset h-unset leading-unset"
            >
              <ion-icon name="trash-outline"></ion-icon>
            </button>
          </div>

          <div
            *cdkDragPlaceholder
            class="w-full drag-placeholder-border-and-background h-[30px]"
          >
          </div>
        </li>
      </ul>

      <ng-template #noTurbosMessage>
        <p>No template selected.</p>
      </ng-template>
    </div>
    
  </ng-container>

</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-flat-button
    [mat-dialog-close]="undefined"
  >
    Cancel
  </button>

  <button
    mat-flat-button
    color="primary"
    (click)="_saveChanges()"
  >
    Save
  </button>
</mat-dialog-actions>
