import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { EMPTY, Observable, ReplaySubject, combineLatest, from, of } from 'rxjs';
import { catchError, concatMap, finalize, map, switchMap, tap } from 'rxjs/operators';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { IApplicationTemplate } from 'src/app/model/thingie/templates';
import { IThingie } from 'src/app/model/thingie/thingie';
import { IProject } from 'src/app/model/user-management/project';
import { IUserData } from 'src/app/model/userdata';
import { DeviceService } from 'src/app/services/device/device.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { ThingieCreationHelperService } from 'src/app/services/thingie-creation-helper/thingie-creation-helper.service';
import { ISetTargetStateEntry, ThingieService } from 'src/app/services/thingie/thingie-service.service';
import { UINotificationService } from 'src/app/services/ui-notification/uinotification.service';
import { deepCopy } from 'src/app/utility/deep-copy';
import { AssignedDeviceInfo, getDeviceConfigurationInfoForThingies } from 'src/app/utility/thingie/get-device-configuration-info';
import { createExperimentNameTakenValidator } from 'src/app/utility/validators/experiment-name-taken.validator';
import { SubSink } from 'subsink';

type TemplateType = IUserData<IApplicationTemplate>;

export type DialogData = {
  turboTemplate: TemplateType;
};

export type DialogResult = undefined | null;

interface IUiState {

  /**
   * List of available projects the experiment can be created in
   */
  projects: IProject[];

  /**
   * The user needs to choose a project
   */
  projectSelectionRequired: boolean;

  /**
   * Creatable
   * - mixed with some lis drives, blocked or not blocked
   *
   * CreatableWithAction
   * - not blocked, (only Lis drives or no LIS drives at all)
   * - A secondary action can automatically be executed
   * - Action set in {@link possibleAction}
   *
   *
   * NoThingies
   * - special state to change the UI to show a message
   *   which explains that there are no objects/thingies
   *   in the chosen app template
   *
   * Loading
   * - special state used to display a loading UI
   */
  type: 'creatable'
  | 'creatableWithAction'
  | 'noThingies'
  | 'loading';

  /**
   * Start
   * - not blocked, no lis drives
   *
   * Upload
   * - not blocked, only lis drives
   *
   * Unavailable
   * - no secondary action available
   */
  possibleAction: 'start' | 'upload' | 'unavailable';

  issues: {
    processIndex: number;
    taskIndex: number;
    inputKey: string;
    deviceAddress: string;
    problemReason: 'noDevice' // No device was assigned to input
    | 'deviceBlocked'         // The device is blocked
    | 'wrongDevice'           // Mismatch between device type and input's device type
    | 'deviceNotFound';       // Device not in list
  } [];
}

@Component({
  selector: 'app-turbo-template-start-dialog',
  templateUrl: './turbo-template-start-dialog.component.html',
  styleUrls: ['./turbo-template-start-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurboTemplateStartDialogComponent implements OnInit, OnDestroy {

  /**
   * Are we in the process of creating the experiment?
   */
  _tryingCreation = false;

  _experimentName: FormControl<string | null>;

  _nameTooltip = `
The software-suggested experiment name was based on the following naming scheme:
'[Current date & time] [template's experiment name]'
  
E.g. "01/01/2021_12:00:00 myExperiment"

We use the following naming scheme for created objects/replicates:
'[Experiment name]_[number of replicate]'
  
Example:
- 01/01/2021_12:00:00 myExperiment_1
- 01/01/2021_12:00:00 myExperiment_2
  `;

  _selectedProjectId: string | null = null;


  private subsink = new SubSink();
  private deviceList$ = new ReplaySubject<IPojoDevice[]>(1);
  private projectList$ = new ReplaySubject<IProject[]>(1);

  constructor(
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<TurboTemplateStartDialogComponent, DialogResult>,
    private log: LogService,
    private projectService: ProjectService,
    private router: Router,
    private thingieService: ThingieService,
    private thingieCreationHelper: ThingieCreationHelperService,
    private uiNotications: UINotificationService,
  ) {
    this._experimentName = new FormControl<string | null>(
      null,
      [ Validators.required ],
      [
        createExperimentNameTakenValidator(
          undefined,
          this.thingieService
        )
      ]
    );
  }

  async ngOnInit() {
    this.autoRefreshDeviceList();

    const projectList = await this.projectService.getAllProjects();
    this.projectList$.next(projectList);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  get _numberOfObjects(): number {
    return this.data.turboTemplate.value.thingies?.length ?? 1;
  }

  get _templateName(): string {
    return this.data.turboTemplate.value.name;
  }

  get _errorDescription(): string {
    const result = '';

    if (this._experimentName.hasError('required')) {
      return 'Experiment name is required';
    }

    if (this._experimentName.hasError('experimentNameTaken')) {
      return this._experimentName.getError('experimentNameTaken');
    }

    return result;
  }

  get _errorDescription$(): Observable<string> {
    return this._experimentName
      .statusChanges
      .pipe(
        map(status => {
          if (status !== 'INVALID') {
            return 'Valid value';
          }

          const result = 'Invalid value';

          if (this._experimentName.hasError('required')) {
            return 'Experiment name is required';
          }

          if (this._experimentName.hasError('experimentNameTaken')) {
            return this._experimentName.getError('experimentNameTaken');
          }

          return result;
        })
      );
  }

  /**
   * Create experiment from template and optionally run post-
   * creation actions
   */
  _create(): any;
  _create(option: 'runAfterCreation', status: IUiState): any;
  _create(option?: 'runAfterCreation', status?: IUiState): any {
    if (this._selectedProjectId === null) {
      throw new Error('No project selected');
    }

    // Show spinner & disable button
    this._tryingCreation = true;

    // Disable backdrop escape
    this.dialogRef.disableClose = true;

    // Startable:
    // - not blocked, no lis drives
    //
    // Uploadable
    // - not blocked, only lis drives
    //
    // Creatable
    // - lis drives, blocked or not blocked

    this.subsink.sink = from(Promise.all([
      deepCopy(this.data.turboTemplate.value.thingies!),
      deepCopy(this.data.turboTemplate.value.templateThingie)
    ]))
      .pipe(
        map(([thingies, templateThingie]) => {
          // Set the chosen base name and generate
          // thingie names based on that

          const baseName = this._experimentName.value as string;

          thingies.forEach((thingie, index) => {
            thingie.baseName = baseName;
            thingie.name     = `${baseName}_${index + 1}`;
            thingie.project  = this._selectedProjectId!;
          });

          return { thingies, templateThingie };
        }),
        switchMap(
          ({ thingies, templateThingie }) => {
            this.thingieCreationHelper.resetThingies([templateThingie]);
            this.thingieCreationHelper.resetThingies(thingies);
            return this.thingieCreationHelper
              .preProcessAndCreate(thingies, templateThingie);
          }
        ),
        switchMap(thingies => {
          if (!option) {
            return of(thingies);
          }

          let targetState = '';
          switch (status!.possibleAction) {
            case 'start':
              targetState = 'RUNNING';
              break;
            case 'upload':
              targetState = 'UPLOAD_DONE';
              break;
            default:
              // In theory we should never be able to reach this point
              throw new Error('Invalid target state');
          }

          // Set task state for all tasks in process 0
          const setTargetStateRequests = thingies.flatMap<ISetTargetStateEntry>(thingie =>
            thingie.processes[0].tasks
              .flatMap((task, taskIndex) => {
                if (targetState === 'RUNNING' || (targetState === 'UPLOAD_DONE' && task.stateType === 'lisexperiment')) {
                  return [{
                    process: 0,
                    task: taskIndex,
                    targetState,
                    thingieId: thingie._id!
                  }];
                } else {
                  return [];
                }
              }));

          return this.thingieService.batchSetTargetStates(setTargetStateRequests)
            .pipe(
              map(() => thingies)
            );
        }),
        tap(thingies => {
          // Close dialog...
          this.dialogRef.close(undefined);

          // And forward to thingie list or thingie detail page
          if (thingies.length > 1) {
            this.redirectToNewThingies(true, option === 'runAfterCreation' ? 'running' : 'planned');
          } else {
            this.redirectToNewThingies(false, thingies[0]._id!);
          }
        }),
        catchError((err: unknown) => {
          this.log.error('Failed to create experiment', err);

          this.uiNotications.displaySnackbar({
            message: 'Failed to create experiment',
            actionButtons: [{
              actionsToExecute: [],
              text: 'Dismiss'
            }],
            durationMilliseconds: Infinity
          });

          return EMPTY;
        }),
        finalize(() => {
          this._tryingCreation = false;
          this.dialogRef.disableClose = false;
          this.changeDetector.markForCheck();
        })
      )
      .subscribe();
  }


  readonly _status$ = new Observable<IUiState>(observer => {
    const thingies = this.data.turboTemplate.value.thingies;

    if (!thingies || thingies?.length === 0) {
      // Display special interface if no objects exist
      // whithin the selected app template
      observer.next({
        issues: [],
        type: 'noThingies',
        possibleAction: 'unavailable',
        projects: [],
        projectSelectionRequired: false,
      });

      observer.complete();
      return () => null;
    } else {
      // Display loading interface
      observer.next({
        issues: [],
        type: 'loading',
        possibleAction: 'unavailable',
        projects: [],
        projectSelectionRequired: false,
      });
    }

    const devicesInfo$ = this.getDevicesInformation(thingies);

    // Get device & project info to determine the target state
    // the experiment can be created in
    // and set the UI state accordingly
    const sub = combineLatest([devicesInfo$, this.projectList$])
      .subscribe(([devices, projects]) => {
        //// Determine if the project selection is required

        let projectSelectionRequired = false;

        const projectId = this.data.turboTemplate.value.thingies![0].project;
        if (!projects.find(p => p._id === projectId)) {
          projectSelectionRequired = true;
          this._selectedProjectId = null;
        } else {
          this._selectedProjectId = projectId;
        }

        //// Determine if the experiment can be set to started/uploaded

        if (devices.find(d => d.status === 'wrongDevice')) {
          throw new Error('Encountered: invalid device assigned to input');
        }

        let hasBlockedDevices = false;
        if (devices.find(d => d.status === 'deviceBlocked')) {
          hasBlockedDevices = true;
        }

        let isUploadable = true;
        if (devices.find(d => d.type !== 'AquilaLisDrive')) {
          isUploadable = false;
        }

        let hasNonReadyDevices = false;
        if (devices.find(d => d.status === 'notReady')) {
          hasNonReadyDevices = true;
        }

        const issues: IUiState['issues'] = [];
        const hasIssues = issues.length > 0;

        const possibleAction: IUiState['possibleAction'] = isUploadable ? 'upload' : 'start';

        //// Update UI state

        if (!hasBlockedDevices && !hasIssues && !hasNonReadyDevices) {
          observer.next({
            issues,
            type: 'creatableWithAction',
            possibleAction,
            projects,
            projectSelectionRequired
          });

          return;
        }

        observer.next({
          issues,
          type: 'creatable',
          possibleAction,
          projects,
          projectSelectionRequired
        });
      });

    return () => sub.unsubscribe();
  });

  private getDevicesInformation(thingies: IThingie[]) {
    return this.deviceList$
      .pipe(
        map(this.getInfoForAssignedDevices(thingies)),
        concatMap((devices, index) => {
          if (index === 0) {
            setTimeout(
              () => {
                // Touched, so a possible error message would be shown right-away
                this._experimentName.markAsTouched();

                // Set the default name and with that trigger initial validation
                this._experimentName.setValue(this.createDefaultName());
              },
              0
            );
          }

          return of(devices);
        })
      );
  }

  /**
   * Returns a mapping function that maps the devices assigned
   * to thingies/objects in `thingies` to a device info structure.
   *
   * @param thingies
   * @returns Mapping function
   */
  private getInfoForAssignedDevices(
    thingies: IThingie[]
  ): (value: IPojoDevice[], index: number) => AssignedDeviceInfo[] {
    return deviceList =>
      // Filter out devices not set up within the app template
      // And flag those that are blocked.
      getDeviceConfigurationInfoForThingies(thingies, deviceList)
    ;
  }

  /**
   * Create a base name for the new experiment
   * based on the template's base name prefixed
   * with the current date and time down to the
   * second.
   *
   * @returns new base name
   */
  private createDefaultName(): string {
    let base = this.data.turboTemplate.value.templateThingie.baseName;
    if (base.match(/^\d{2}\/\d{2}\/\d{4}_\d{2}:\d{2}:\d{2} /)) {
      base = base.substring(20);
    }
    const prefix = DateTime.now().toFormat('dd/MM/yyyy_HH:mm:ss');

    return prefix + ' ' + base;
  }

  /**
   * Obtain the device list and keep
   * it up-to-date.
   */
  private autoRefreshDeviceList() {
    this.subsink.sink = this.deviceService.getDevices()
      .subscribe(this.deviceList$);
  }

  /**
   * Send the user to the dashboard if multiple
   * objects were created, otherwise to the thingie-
   * detail page.
   *
   * @param multi If multiple thingies were created
   */
  private redirectToNewThingies(multi: true, targetTab: string): void;
  private redirectToNewThingies(multi: false, id: string): void;
  private redirectToNewThingies(multi: boolean, id?: string): void {
    if (multi) {
      void this.router.navigate([`/dashboard/cards`], { queryParams: { tab: id }, queryParamsHandling: 'merge' });
    } else {
      void this.router.navigate(['thingies', 'thingie', id!]);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class TurboTemplateStartDialogService {
  constructor(private dialog: MatDialog) {}

  public open(data: DialogData) {
    return this.dialog.open<
      TurboTemplateStartDialogComponent,
      DialogData,
      DialogResult
    >(
      TurboTemplateStartDialogComponent,
      {
        data,
        panelClass: 'fdecedc0-39b079e04796-turbo'
      }
    );
  }
}
