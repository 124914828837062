export type NotUndefined<T> = T extends undefined ? never : T;
export type NotNull<T> = T extends null ? never : T;

type NonNullableObj<T, Ks extends keyof T> = T & {
  [K in Ks]: NotNull<T[K]>;
};

export function isNotUndefined<T>(x: T): x is NotUndefined<T> {
  return x !== undefined;
}

export function isNotNull<T>(x: T): x is NotNull<T> {
  return x !== null;
}

export function propertyIsNotNull<T, K extends keyof T>(obj: T, prop: K): obj is NonNullableObj<T, K> {
  return obj[prop] !== null;
}

export function propertyIsNotNullFun<T, K extends keyof T>(prop: K) {
  const fun = (obj: T): obj is NonNullableObj<T, K> => obj[prop] !== null;

  return fun;
}

/**
 * Like `Required<T>` but allows to specify which properties need to be required.
 */
export type WithRequired<T, Keys extends keyof T> = T & {
  [P in Keys]-?: T[P]
};
