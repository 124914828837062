import { ITask } from 'src/app/model/thingie/task';
import { deepCopySync } from '../deep-copy';

/**
 * Creates a pure copy of a task
 *
 * A pure copy of task is a task that can be started
 * even if the original task is in a state that would
 * prevent running it.
 *
 * @param task The task of which to create a purified copy
 * @returns A purified copy of the task provided
 */
export function pureCopyTask(task: ITask): ITask {
  const copy = deepCopySync(task);

  purifyTask(copy);

  return copy;
}

/**
 * Changes the task, so that it behaves like a new task.
 *
 * Note: modifies the task in place.
 *
 * @param task the task to purify
 * @returns the task provided after purification
 */
export function purifyTask(task: ITask): ITask {
  task.started = undefined;
  task.stopped = undefined;
  task.stateDescription = undefined;
  task.currentState = 'NEW';
  task.targetState = 'NEW';
  task.pausedTimeRanges = [];

  return task;
}

