/**
 * Returns the number of digits of a given number.
 *
 * @param num The number to get the number of digits of.
 * @returns The number of digits of the given number.
 */
export function numDigitsOf(num: number): number {
  if (num === 0) {
    return 1;
  }

  return Math.floor(Math.log10(Math.abs(num))) + 1;
}
