import { IEvent } from './base-event';

export class ChartViewAreaResizedEvent implements IEvent {
  type = 'ChartViewAreaResizedEvent';

  constructor(public newSize: { height: number; width: number }) { }
}

export class SearchTagsEvent implements IEvent {
  type = 'SearchTagsEvent';

  constructor(public searchItem: string) { }
}
