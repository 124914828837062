import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INotification } from '../../model/notification/notification';
import { NotificationServiceImpl } from './implementation/notification.service';

@Injectable({
  providedIn: 'root',
  useClass: NotificationServiceImpl
})
export abstract class NotificationService {
  abstract getNotification(id: string): Observable<INotification>;
  abstract getNotifications(id: string, pageSize: number): Observable<INotification[]>;
  abstract getUnreadNotifications(id: string, pageSize: number): Observable<INotification[]>;
  abstract getUnreadNotificationsCount(): Observable<number>;
  abstract dismiss(id: string): Promise<void>;
  abstract undismiss(id: string): Promise<void>;
  abstract delete(id: string): Promise<void>;
  abstract subscribeUpdates(): Observable<INotification>;
  abstract checkSoftwareUpdate(): Promise<boolean>;
}
