import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ISearchOptions } from 'src/app/model/search/search-options';
import { DefaultConfig } from 'src/assets/default.config';
import { SearchResult } from '../../../model/search/search-result';
import { LogService } from '../../loggers/logger.service';
import { SearchService } from '../search.service';

@Injectable()
export class SearchServiceImpl implements SearchService {
  constructor(
    private httpClient: HttpClient,
    private log: LogService
  ) {}

  async search({ term, devicesStatuses, objectsStatuses, sort, types, project, limit, tags }: ISearchOptions): Promise<SearchResult> {
    let endpoint = DefaultConfig.uris.search;

    let queryParams = '';

    if (sort !== '') {
      queryParams += `&sort=${sort}`;
    }

    queryParams += `&limit=${limit}`;

    if (project !== '') {
      queryParams += `&project=${project}`;
    }

    objectsStatuses.forEach(t => {
      queryParams +=  `&objectsStatuses=${t}`;
    });

    devicesStatuses.forEach(t => {
      queryParams +=  `&devicesStatuses=${t}`;
    });

    tags.forEach(t => {
      queryParams +=  `&tags=${t}`;
    });

    // apply the type filter to the url
    if (types.length === 1 ) {
      if (types[0].toLowerCase() === 'objects') {
        endpoint = `${endpoint}/thingies`;
      } else {
        endpoint = `${endpoint}/devices`;
      }
    }

    return lastValueFrom(this.httpClient.get<SearchResult>(`${endpoint}/?${queryParams}&q=${term}`)
      .pipe(tap(next => this.log.debug('Search result received', next))));
  }
}
