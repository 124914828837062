import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipEvent as MatChipEvent, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';

export interface ChippedAutocompleteItem<T> {
  itemDisplayText: string;
  itemDisplayDescription?: string;
  value: T;
}

@Component({
  selector: 'app-chipped-autocomplete',
  templateUrl: './chipped-autocomplete.component.html',
  styleUrls: ['./chipped-autocomplete.component.scss']
})
export class ChippedAutocompleteComponent implements OnInit {
  @ViewChild('searchInput')
  searchInput!: ElementRef<HTMLInputElement>;

  @ViewChild('chipList')
  chipList!: MatChipList;

  _items: ChippedAutocompleteItem<any>[] = [];

  @Input()
  set items(il: ChippedAutocompleteItem<any>[]) {
    this._items = il;
    this.searchFormControl.reset('');
  }

  get items() {
    return this._items;
  }

  // custom items => items typed into the input field
  private _allowCustomItems = false;

  @Input()
  set allowCustomItems(value: boolean) {
    this._allowCustomItems = value;
  }

  possibleSelections = new Observable<ChippedAutocompleteItem<any>[]>();
  // -- Mat Chip List config properties

  @Input()
  set selectedItems(newItems: ChippedAutocompleteItem<any>[]) {
    // shallow copy the items list
    this._selectedItems = newItems.map(item => item);
    this.searchFormControl.reset('');
  }
  get selectedItems(): ChippedAutocompleteItem<any>[] {
    return this._selectedItems;
  }
  _selectedItems: ChippedAutocompleteItem<any>[] = [];

  @Output()
  selectedItemsChange = new EventEmitter<ChippedAutocompleteItem<any>[]>();

  separatorKeys = [];

  searchFormControl = new FormControl();

  constructor() { }

  ngOnInit(): void {
    this.possibleSelections = this.searchFormControl.valueChanges.pipe(
      startWith(''),
      map(searchString => this.filterItems(searchString))
    );
  }

  autoCompleteDisplayFn(item: ChippedAutocompleteItem<any>) {
    // Always show an empty value after selection,
    // because the selection is represented by chips
    UNUSED_ARGS(item);
    return '';
  }

  /**
   * Handler, that triggers when the Enter/Return key was pressed
   *
   * If [allowCustomItems] resolves to true, this will take whatever
   * is put into the search input and creates a new element with it,
   * where itemDisplayText contains the value entered.
   */
  onEnterKeyUp() {
    if (!this._allowCustomItems) {
      return;
    }

    // Don't allow empty elements

    const value = this.searchInput.nativeElement.value.trim();
    if (!value) {
      return;
    }

    // Take the value typed in, and create a new tag from it

    this.selectedItems.push({
      itemDisplayText: value,
      value
    });

    this.searchFormControl.setValue('');
    this.selectedItemsChange.emit(this.selectedItems);
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    const selectedItem = $event.option.value;

    if (!this.selectedItems.find(value => value === selectedItem)) {
      this.selectedItems.push(selectedItem);
      this.selectedItemsChange.emit(this.selectedItems);
    }

    // Required to trigger the filter for exclusion
    // of the newly added item from the list displayed
    this.blurAndReset();
  }

  isAlreadySelected(itemDisplayText: string) {
    if (this.selectedItems.find(item => item.itemDisplayText === itemDisplayText)) {
      return true;
    } else {
      return false;
    }
  }

  onChipRemoval($event: MatChipEvent) {
    const itemForRemoval = $event.chip.value;
    this.selectedItems.splice(
      this.selectedItems.findIndex(selectedItem => selectedItem === itemForRemoval),
      1
    );

    this.selectedItemsChange.emit(this.selectedItems);

    this.blurAndReset();
  }

  reset() {
    this.selectedItems = [];
  }

  private filterItems(searchString: string | object) {
    let filterValue = '';

    if (typeof searchString === 'object') {
      filterValue = '';
    } else {
      filterValue = searchString.toLowerCase();
    }

    return this.items
      .filter(value => !this.selectedItems.includes(value))
      .filter((value) =>
        value.itemDisplayText.toLowerCase().includes(filterValue)
          || value.itemDisplayDescription?.toLowerCase().includes(filterValue));
  }

  private blurAndReset() {
    this.searchFormControl.reset('');
    this.searchInput.nativeElement.blur();
  }
}
