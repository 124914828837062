import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { IPageInfo, VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap, throttleTime } from 'rxjs/operators';
import { INotification, IUnreadNotificationInformation } from 'src/app/model/notification/notification';
import { NotificationEntry } from 'src/app/model/notification/notification-entry';
import { LogService } from 'src/app/services/loggers/logger.service';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { UINotificationService } from 'src/app/services/ui-notification/uinotification.service';
import { Notification2EntryMapper } from 'src/app/utility/notification-2-entry-mapper';
import { isNotNull } from 'src/app/utility/types';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';

const notificationLastOccurrenceSort = (a: NotificationEntry, b: NotificationEntry) => {
  const lastA = DateTime.fromISO(a.notification.lastOccurrence);
  const lastB = DateTime.fromISO(b.notification.lastOccurrence);

  return lastA.toMillis() > lastB.toMillis() ? -1 : 1;
};

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  numberOfUnreadNotifications$ = new Subject<void>();
  getNotification$ = new Subject<string>();
  fetchMoreNotifications$ = new Subject<void>();
  showNotificationSnackbar$ = new Subject<void>();

  // to avoid extra rendering of the menu each time that system notification is fetched
  isNotificationMenuOpened = false;
  isBackgroundNotification = false;

  private subscriptions = new SubSink();

  private readonly allowedFilterValues = ['ALL', 'UNREAD'];
  notificationFilterControl = new FormControl('UNREAD');
  notificationsUnavailable = false;


  private notifications$ = new BehaviorSubject<NotificationEntry[]>([]);

  // the notifications that will be saved directly from api
  private pureNotifications$ = new BehaviorSubject<INotification[]>([]);
  private pureNotifications: INotification[] = [];
  notificationsToDisplay$ = new BehaviorSubject<NotificationEntry[]>([]);

  numberOfUnreadNotificationsLabel = '';
  numberOfUnreadNotificationsCount = 0;

  notificationSearchInput = new FormControl('');

  private pageSize = 20;
  private lastNotificationId = '';
  private lastRequestedNotificationId = '';
  private isLoading = false;

  @Input()
  currentUserId!: string;

  @ViewChild('scroll') scroller!: VirtualScrollerComponent;

  constructor(
    private log: LogService,
    private notificationService: NotificationService,
    private uiNotifications: UINotificationService
  ) {
  }

  async ngOnInit() {
    this.initializeNotificationHandling();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initializeNotificationHandling() {
    // check the number of new notifications
    this.subscriptions.sink = this.numberOfUnreadNotifications$.pipe(
      debounceTime(500),
      switchMap(() => this.notificationService.getUnreadNotificationsCount()),
      tap(numberOfUnread => {
        this.updateNumberOfUnreadUI(numberOfUnread);
      })
    )
      .subscribe();

    // to avoid new notification snackbar at the same time,
    // we show the first one and wait until it fades out then
    // if there were new notification again, we can show a new
    // snackbar
    this.subscriptions.sink = this.showNotificationSnackbar$.pipe(
      throttleTime(5000),
    ).subscribe(() => {
      // show the snackbar for new notification
      this.uiNotifications.displaySnackbar({
        message: `You have a new notification!`,
        defaultUI: false,
        closeButton: true,
        durationMilliseconds: 5000
      });
    });

    // connect the socket and listen to the updates
    const notificationUpdates$ = this.notificationService.subscribeUpdates().pipe(startWith(undefined));
    const userNotificationUpdates$ = new BehaviorSubject<INotification | undefined>(undefined);

    this.subscriptions.sink = notificationUpdates$.pipe(
      tap((updatedNotification) => {
        this.isBackgroundNotification = false;

        if (updatedNotification) {
          if (updatedNotification.type === 'SystemNotification') {
            this.isBackgroundNotification = true;
            switch (updatedNotification.key.heading) {
              case 'UNREAD_NOTIFICATION':
                try {
                  const unreadData = JSON.parse(updatedNotification.message) as IUnreadNotificationInformation;

                  if (unreadData.receiver === this.currentUserId) {
                    this.updateNumberOfUnreadUI(unreadData.unreadNotifications);
                  }
                } catch (error) {
                  // just continue, perhaps the data is not complete for updating the unread count
                  this.log.error(`Unread count data: ${error}`);
                }
                break;

              default:
                break;
            }
          } else {
            // for the user notification show the snackbar
            if (updatedNotification.dismissed === false) {
              this.showNotificationSnackbar$.next();
            } else {
              this.numberOfUnreadNotifications$.next();
            }

            // update the menu
            userNotificationUpdates$.next(updatedNotification);
          }
        }
      }),
    ).subscribe();

    // to get the next pagination with ID
    this.subscriptions.sink = combineLatest([this.pureNotifications$, userNotificationUpdates$]).pipe(
      // update only if the menu is opened, otherwise we can ignore it
      filter(() => this.isNotificationMenuOpened === true),
      map(([list, updatedNotification]) => {
        // Integrate the updated notification in the list
        if (updatedNotification && updatedNotification.type !== 'SystemNotification') {
          const idx = list.findIndex(n => n._id === updatedNotification._id);
          if (idx === -1) {
            list.unshift(updatedNotification);
          } else {
            list[idx] = updatedNotification;
          }
        }

        return list;
      }),
      map(list => list.filter(n => n.type !== 'SystemNotification')),
      map(list => Notification2EntryMapper.map(list)),
      map(list => list.sort(notificationLastOccurrenceSort))
    )
      .subscribe(this.notifications$);

    this.subscriptions.sink = this.fetchMoreNotifications$.pipe(
      debounceTime(300)
    )
      .subscribe(() => {
        this.isLoading = true;
        this.getNotification$.next(this.lastNotificationId);
      });

    const notificationSearchTerm$ = this.notificationSearchInput.valueChanges.pipe(
      startWith(''),
      debounceTime(DefaultConfig.general.userInputDefaultDebounce_Milliseconds),
      distinctUntilChanged()
    );

    const notificationFilterValue$ = this.notificationFilterControl.valueChanges.pipe(
      startWith('UNREAD'),
      filter(isNotNull),
      distinctUntilChanged(),
      tap(() => {
        this.getNotification$.next('');
      }),
      map(value => this.allowedFilterValues.includes(value) ? value : 'UNREAD')
    );

    const filteredAndSortedNotifications$ = combineLatest(
      [this.notifications$, notificationSearchTerm$, notificationFilterValue$]
    ).pipe(
      map(([list, searchTerm, filter]) => list.filter(entry => {
        const showUnreadOnly = filter === 'UNREAD';
        const searchTermLower = (searchTerm ?? '').toLowerCase();

        return ((showUnreadOnly && !entry.dismissed) || !showUnreadOnly)
              && (entry.message.toLowerCase().includes(searchTermLower)
                || entry.heading.toLowerCase().includes(searchTermLower)
              );
      })),
      tap(() => {
        this.isLoading = false;

        if (this.scroller !== undefined) {
          this.scroller.refresh();
        }
      })
    );

    this.subscriptions.sink = filteredAndSortedNotifications$.subscribe(this.notificationsToDisplay$);

    // connect the refresh event to the get api
    this.subscriptions.sink =  this.getNotification$.pipe(
      switchMap((notificationId) => {
        this.isLoading = true;
        this.lastRequestedNotificationId = notificationId;

        if (this.notificationFilterControl.value === 'UNREAD') {
          return this.notificationService.getUnreadNotifications(notificationId, this.pageSize);
        } else {
          return this.notificationService.getNotifications(notificationId, this.pageSize);
        }
      }),
      tap(() => {
        this.notificationsUnavailable = false;
      }),
      catchError(() => {
        this.notificationsUnavailable = true;
        return of([]);
      }),
      startWith([]),
      map((list) => {
        const userList = list.filter(n => n.type !== 'SystemNotification');
        if (userList.length > 0) {
          this.lastNotificationId = userList[userList.length - 1]._id;
        }

        if (this.lastNotificationId.length > 0) {
          this.pureNotifications = [...list, ...this.pureNotifications];
        } else {
          this.pureNotifications = [...list];
        }

        return this.pureNotifications;
      })
    )
      .subscribe(this.pureNotifications$);
  }


  notificationMenuOpened() {
    this.lastNotificationId = '';
    this.pureNotifications = [];
    this.isNotificationMenuOpened = true;
    // just update the unread notifications
    this.numberOfUnreadNotifications$.next();

    this.getNotification$.next(this.lastNotificationId);
  }
  notificationMenuClosed() {
    this.isNotificationMenuOpened = false;
  }

  changeNotificationsFilter($event: MatLegacySlideToggleChange) {
    this.lastNotificationId = '';
    this.pureNotifications = [];

    if ($event.checked === true) {
      this.notificationFilterControl.patchValue('UNREAD');
    } else {
      this.notificationFilterControl.patchValue('ALL');
    }
  }

  dismissAllNotifications() {
    this.notifications$.value.filter(n => n.notification.type !== 'SystemNotification').forEach(
      n => void this.notificationService.dismiss(n.notification._id)
    );
  }

  toggleDismissNotification(entry: NotificationEntry) {
    const id = entry.notification._id;
    if (entry.notification.dismissed) {
      void this.notificationService.undismiss(id);
      entry.notification.dismissed = false;
      this.log.debug(`Notification ${id} (id) undismissed`);
    } else {
      void this.notificationService.dismiss(id);
      entry.notification.dismissed = true;
      this.log.debug(`Notification ${id} (id) dismissed`);
    }
  }

  reload($event: Event) {
    $event.preventDefault();
    location.reload();
  }

  private updateNumberOfUnreadUI(numberOfUnread: number) {
    this.numberOfUnreadNotificationsCount = numberOfUnread;

    if (numberOfUnread > 99) {
      this.numberOfUnreadNotificationsLabel = '99+';
    } else {
      this.numberOfUnreadNotificationsLabel = `${numberOfUnread}`;
    }
  }


  fetchMoreNotifications(event: IPageInfo) {
    if (this.isLoading) {
      return;
    }

    // check if the endIndex from the VirtualScroller is equal to the size of the visible notifications
    let visibleMenuLength = 0;
    if (this.notificationFilterControl.value === 'UNREAD') {
      visibleMenuLength = this.pureNotifications.filter(
        (n) => (n.dismissed === false && n.type !== 'SystemNotification')
      ).length;
    } else {
      visibleMenuLength = this.pureNotifications.filter(
        (n) => n.type !== 'SystemNotification'
      ).length;
    }

    // check if the last item is rendered or not
    // return if not
    if (event.endIndex !== visibleMenuLength - 1) {
      return;
    }

    // this means, there no more notifications
    if (this.lastRequestedNotificationId === this.lastNotificationId) {
      return;
    }

    this.fetchMoreNotifications$.next();
  }
}
