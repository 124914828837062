import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { CustomPipesModule } from '../../utility/pipes/custom-pipes.module';
import { ChippedAutocompleteInlineComponent } from './chipped-autocomplete-inline.component';
import { ChippedAutocompleteComponent } from './chipped-autocomplete.component';

@NgModule({
  declarations: [ChippedAutocompleteComponent, ChippedAutocompleteInlineComponent],
  exports: [ChippedAutocompleteComponent, ChippedAutocompleteInlineComponent],
  imports: [
    CommonModule,
    MaterialImportsModule,
    ReactiveFormsModule,
    IonicImports,
    CustomPipesModule
  ]
})
export class ChippedAutocompleteModule {}
