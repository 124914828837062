import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'boldEachWord',
  pure: true
})
export class BoldPipeByEachWord implements PipeTransform {
  transform(textValue: string, subTextValue: string): string {
    const subTextValues = subTextValue.split(' ');
    let boldString = textValue;

    subTextValues.forEach(subText => {
      const regex = new RegExp(`${subText}`, 'gi');
      boldString = boldString.replace(regex, w => `<b>${w}</b>`);
    });

    return boldString;
  }
}


@Pipe({
  name: 'boldWord',
  pure: true
})
export class BoldPipeByWord implements PipeTransform {
  transform(textValue: string, subTextValue: string): string {
    const regex = new RegExp(`${subTextValue}`, 'gi');
    const boldString = textValue.replace(regex, w => `<b>${w}</b>`);
    return boldString;
  }
}


@Pipe({
  name: 'boldChar',
  pure: true
})
export class BoldPipeByChar implements PipeTransform {
  transform(textValue: string, subTextValue: string): string {
    const regex = new RegExp(`[${subTextValue}]`, 'gi');
    const boldString = textValue.replace(regex, char => `<b>${char}</b>`);
    return boldString;
  }
}
