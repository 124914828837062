import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appKeyboardNavigator]'
})
export class KeyboardNavigatorDirective {
  @Output() arrowKeyPress = new EventEmitter<string>();
  @Output() arrowKeyPressTriggered = new EventEmitter<void>();
  @Output() mouseOver = new EventEmitter<void>();
  @Output() mouseLeave = new EventEmitter<void>();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    // Get the arrow key from the event
    const key = this.getKeyFromEvent(event);

    // If a valid arrow key is obtained, emit it through the arrowKeyPress event
    if (key) {
      this.arrowKeyPress.emit(key);
      this.arrowKeyPressTriggered.emit();
    }
  }

  @HostListener('mouseover')
  handleMouseOver(): void {
    this.mouseOver.emit();
  }

  @HostListener('mouseleave')
  handleMouseLeave(): void {
    this.mouseLeave.emit();
  }

  private getKeyFromEvent(event: KeyboardEvent): string | undefined {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Enter':
        return event.key;
      default:
        return undefined;
    }
  }
}
