import { SearchResult, SearchResultType } from '../model/search/search-result';
import { SearchResultEntry } from '../model/search/search-result-entry';

export class SearchResult2EntryMapper {
  static map(input: SearchResult): SearchResultEntry[] {
    return input.items.map(item => {
      const meta = item.meta;
      const resultEntry: SearchResultEntry = {
        icon: '',
        isIconSrc: false,
        route: '',
        item
      };

      switch (meta.type) {
        case SearchResultType.Device:
          resultEntry.icon  = 'hardware-chip-outline';
          resultEntry.route = `/devices/device/${item._id}`;
          break;
        case SearchResultType.Thingie:
          resultEntry.icon  = 'flask-outline';
          resultEntry.route = `/thingies/thingie/${item._id}`;
          break;
        default:
          resultEntry.icon      = '/assets/custom-icons/devices-other-outline.svg';
          resultEntry.isIconSrc = true;
          resultEntry.route     = 'unknown';
      }

      return resultEntry;
    });
  }
}
