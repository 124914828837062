import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounce, debounceTime, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

/**
 * 'number' in seconds
 * 'null' means open start/end
 */
export type Value = [number | null, number | null];

@Component({
  selector: 'app-time-period-picker',
  templateUrl: './time-period-picker.component.html',
  styleUrls: ['./time-period-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePeriodPickerComponent implements AfterViewInit, OnDestroy {
  @HostListener('document:keydown')
  _onKeyDown() {
    this.isKeyPressed$.next(true);
  }

  @HostListener('document:keyup')
  _onKeyUp() {
    this.isKeyPressed$.next(false);
  }

  /**
   * Values in seconds
   */
  @Output()
  valueChange = new EventEmitter<Value>();

  /**
   * Values in seconds
   */
  @Input()
  set value(val: Value) {
    this._valueFromExtern$.next(val);
  }

  /**
   * Specifies if the day input should be visible
   * (default: false)
   */
  @Input()
  set hasDay(val: boolean) {
    this._hasDay$.next(val);
  }

  /**
   * Specifies if the seconds input should be visible
   * (default: true)
   */
  @Input()
  set hasSeconds(val: boolean) {
    this._hasSeconds$.next(val);
  }

  @Input()
  set disabled(dissed: boolean) {
    if (dissed) {
      this._controls.disable();
    } else {
      this._controls.enable();
    }
  }

  _hasDay$ = new BehaviorSubject<boolean>(false);
  _hasSeconds$ = new BehaviorSubject<boolean>(true);
  _valueFromExtern$ = new BehaviorSubject<Value>([null, null]);


  _controls = new FormGroup({
    // from...
    f_dd: new FormControl<number | null>(null, [ Validators.required, Validators.min(0)]),
    f_hh: new FormControl<number | null>(null, [ Validators.required, Validators.min(0), Validators.max(23)]),
    f_mm: new FormControl<number | null>(null, [ Validators.required, Validators.min(0), Validators.max(59)]),
    f_ss: new FormControl<number | null>(null, [ Validators.required, Validators.min(0), Validators.max(59)]),

    // to...
    t_dd: new FormControl<number | null>(null, [ Validators.required, Validators.min(0)]),
    t_hh: new FormControl<number | null>(null, [ Validators.required, Validators.min(0), Validators.max(23)]),
    t_mm: new FormControl<number | null>(null, [ Validators.required, Validators.min(0), Validators.max(59)]),
    t_ss: new FormControl<number | null>(null, [ Validators.required, Validators.min(0), Validators.max(59)]),
  });
  _hasValues = false;
  _isOverlayVisible = false;

  private inputs: HTMLInputElement[] = [];
  private isKeyPressed$ = new BehaviorSubject(false);
  private subsink = new SubSink();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private ref: ElementRef<HTMLElement>
  ) { }


  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  ngAfterViewInit() {
    const wrapper = this
      .ref
      .nativeElement
      .querySelector('.input-wrapper')!;
    this.inputs = Array.from(wrapper.querySelectorAll('input'));


    wrapper.addEventListener(
      'click',
      ev => {
        if (ev.target) {
          if (this.inputs?.every(input => ev.target !== input)) {
            this.inputs[0].focus();
          }
        }
      }
    );

    this.subsink.sink = this
      ._controls
      .valueChanges
      .pipe(
        debounce(() => this.isKeyPressed$.pipe(filter(pressed => !pressed))),
        debounceTime(250)
      )
      .subscribe(
        (x) => this.fromGroupValueChanged(x)
      );

    // Handle input changes & default initialization
    this.subsink.sink = combineLatest([this._valueFromExtern$, this._hasDay$])
      .subscribe(([value, hasDay]) => {
        const from = value[0];
        const to = value[1];

        let d = null;
        let h = null;
        let m = null;
        let s = null;

        if (from !== null) {
          let remainder = from;
          if (hasDay) {
            d = Math.floor(from / 86400);
            remainder = from % 86400;
          }

          h = Math.floor(remainder / 3600);
          m = Math.floor(remainder % 3600 / 60);
          s = Math.ceil(remainder % 3600 % 60);
        }
        this._controls.get('f_dd')?.setValue(d, { emitEvent: false });
        this._controls.get('f_hh')?.setValue(h, { emitEvent: false });
        this._controls.get('f_mm')?.setValue(m, { emitEvent: false });
        this._controls.get('f_ss')?.setValue(s, { emitEvent: false });

        d = null;
        h = null;
        m = null;
        s = null;

        if (to !== null) {
          let remainder = to;
          if (hasDay) {
            d = Math.floor(to / 86400);
            remainder = to % 86400;
          }

          h = Math.floor(remainder / 3600);
          m = Math.floor(remainder % 3600 / 60);
          s = Math.ceil(remainder % 3600 % 60);
        }
        this._controls.get('t_dd')?.setValue(d, { emitEvent: false });
        this._controls.get('t_hh')?.setValue(h, { emitEvent: false });
        this._controls.get('t_mm')?.setValue(m, { emitEvent: false });
        this._controls.get('t_ss')?.setValue(s, { emitEvent: false });

        if (from !== null || to !== null) {
          this._hasValues = true;
          this.changeDetector.detectChanges();
        }
      });
  }

  clear() {
    for (const control of Object.values(this._controls.controls)) {
      control.setValue(null, { emitEvent: false });
      control.setErrors(null);
    }

    this.valueChange.emit([null, null]);
  }

  private fromGroupValueChanged(values: TimePeriodPickerComponent['_controls']['value']) {
    const patch: typeof values = {};
    let hasPatch = false;
    // let valuesCount = 0;

    let fromTupleHasValues = false;
    let toTupleHasValues = false;

    let key: keyof typeof values;
    for (key of Object.keys(values) as (keyof typeof values)[]) {
      const val = values[key];
      if (val === null || val === undefined) {
        continue;
      }

      // Determine which tuple has values for later auto-filling
      if (key.indexOf('f_') !== -1) {
        fromTupleHasValues = true;
      } else {
        toTupleHasValues = true;
      }

      this._hasValues = true;
      // ++valuesCount;

      if (val < 0) {
        patch[key] = 0;
        hasPatch = true;
      } else {
        if ((key === 'f_hh' || key === 't_hh') && val > 23) {
          patch[key] = 23;
          hasPatch = true;
        } else if (val > 59) {
          patch[key] = 59;
          hasPatch = true;
        }
      }
    }

    // Auto-fill the rest of a tuple of inputs (one for "from", one for "to")
    if (fromTupleHasValues || toTupleHasValues) {
      for (key of Object.keys(values) as (keyof typeof values)[]) {
        const val = values[key];
        if ((val === undefined || val === null) && key.includes('f_') && fromTupleHasValues) {
          patch[key] = 0;
          hasPatch = true;
        } else if ((val === undefined || val === null) && key.includes('t_') && toTupleHasValues) {
          patch[key] = 0;
          hasPatch = true;
        }
      }
    }

    let message = values;

    if (hasPatch) {
      message = {
        ...values,
        ...patch
      };

      this._controls.patchValue(message, { emitEvent: false });
    }

    this.valueChange.emit(this.formGroupValueToSecondsOrNull(message));
  }

  private formGroupValueToSecondsOrNull(gv: any): Value {
    let from;
    if (
      (gv.f_dd === '' || gv.f_dd === null)
      && (gv.f_hh === '' || gv.f_hh === null)
      && (gv.f_mm === '' || gv.f_mm === null)
      && (gv.f_ss === '' || gv.f_ss === null)
    ) {
      from = null;
    } else {
      from = +gv.f_dd * 86400 + +gv.f_hh * 3600 + +gv.f_mm * 60 + +gv.f_ss;
    }


    let to;
    if (
      (gv.t_dd === '' || gv.t_dd === null)
      && (gv.t_hh === '' || gv.t_hh === null)
      && (gv.t_mm === '' || gv.t_mm === null)
      && (gv.t_ss === '' || gv.t_ss === null)
    ) {
      to = null;
    } else {
      to = +gv.t_dd * 86400 + +gv.t_hh * 3600 + +gv.t_mm * 60 + +gv.t_ss;
    }
    return [from, to];
  }
}
