<div
  class="w-fit flex items-center gap-0.5"
  [matTooltip]="info.description ?? ''"
  [matTooltipDisabled]="info.description === undefined"
  (click)="showErrors()"
>
  <span
    *ngIf="info.status !== undefined"
    [class]="'state-chip color-' + info.color"
  >
    {{ info.status }}
  </span>
  <ion-icon *ngIf="info.hasError" class="error-icon" name="warning-outline"></ion-icon>
</div>
