import { Injectable } from '@angular/core';
import { VersionUpdateCheckServiceImpl } from './implementation/version-update-check.service';

@Injectable({
  providedIn: 'root',
  useClass: VersionUpdateCheckServiceImpl
})
export abstract class VersionUpdateCheckService {
  abstract openChangeLogs(): boolean;
  abstract skipUpdate(): Promise<boolean>;
  abstract remindUpdateLater(): boolean;
  abstract runSoftwareUpdateCheck(): Promise<boolean>;
}
