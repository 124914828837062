<mat-form-field
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  [id]="_formFieldID"
>
  <mat-label *ngIf="labelContent">
    <ng-container [ngTemplateOutlet]="labelContent.template"></ng-container>
  </mat-label>
  <input
    matInput
    type="text"
    pattern="{{pattern}}"
    [formControl]="userInputControl"
    (focus)="showing"
  />

  <button
    [ngClass]="{
      disabled
    }"
    matPrefix
    class="icon-button rdtp8860-ab04d5dd0414-icon"
    [disabled]="disabled"
  >
    <ion-icon src="/assets/custom-icons/date-time-outline.svg"></ion-icon>
  </button>

  <mat-hint class="user-input-hint">
    {{ formatHint }}
  </mat-hint>
</mat-form-field>

<ng-template
  #overlay
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="showing && !disabled"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayScrollStrategy]="overlayScrollStrategy"
  [cdkConnectedOverlayPositions]="overlayPositions"
>
  <div 
    [id]="_wrapperID"
    class="wrapper"
    *ngIf="showing"
  >
    <div
      mwlFlatpickr
      [monthSelectorType]="'dropdown'"
      [allowInput]="true"
      dateFormat="Z"
      [mode]="mode"
      [inline]="true"
      [formControl]="flatpickrControl"
      [dateFormat]="'d-m-Y'"
      (flatpickrValueUpdate)="onFlatpickrValueUpdate($event)"
    >
    </div>

    <div class="horizontal-separator"></div>

    <div class="time-area">
      <div>
        <label for="from">
          <span #fromRef><ng-content select="[from]"></ng-content></span>
          <ng-container *ngIf="!fromRef.hasChildNodes()">Start time</ng-container>
          (<a
            [class.disabled]="fromTime.disabled"
            [attr.href]="fromTime.disabled ? null : '#'"
            (click)="!fromTime.disabled && setToNow('start');
            $event.preventDefault();"
           >now</a>)
        </label>
        <app-time-picker
          id="from"
          [formControl]="fromTime"
        ></app-time-picker>
        <div *ngIf="allowAutoStart" class="auto-checkbox-container">
          <mat-checkbox
           [formControl]="autoStartControl"
           [matTooltipDisabled]="!autoStartTooltip"
           [matTooltip]="$any(autoStartTooltip)"
          >
            Auto beginning
          </mat-checkbox>
        </div>
        <!-- q'n'd-placeholder to keep elements aligned -->
        <div *ngIf="allowAutoEnd && !allowAutoStart" class="checkbox-placeholder"></div>
      </div>
      <div *ngIf="mode === 'range'">
        <label for="to">
          <span #toRef><ng-content select="[to]"></ng-content></span>
          <ng-container *ngIf="!toRef.hasChildNodes()">End time</ng-container>
          (<a
            [class.disabled]="toTime.disabled"
            [attr.href]="toTime.disabled ? null : '#'"
            (click)="!toTime.disabled && setToNow('end');
            $event.preventDefault();"
           >now</a>)
        </label>
        <app-time-picker
          id="to"
          [formControl]="toTime"
        ></app-time-picker>
        <div *ngIf="allowAutoEnd" class="auto-checkbox-container">
          <mat-checkbox
            [formControl]="autoEndControl"
            [matTooltipDisabled]="!autoEndTooltip"
            [matTooltip]="$any(autoEndTooltip)"
          >
            Auto end
          </mat-checkbox>
        </div>
        <!-- q'n'd-placeholder to keep elements aligned -->
        <div *ngIf="allowAutoStart && !allowAutoEnd" class="checkbox-placeholder"></div>
      </div>
    </div>
    <div class="footer">
      <button mat-flat-button (click)="showing = false; touchFn();">Close</button>
      <button
        [disabled]="userInputControl.invalid"
        mat-flat-button
        color="primary"
        (click)="onSave()"
      >
        Save
      </button>
    </div>
  </div>
</ng-template>
