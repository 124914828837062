import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Injectable, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { IApplicationTemplate } from 'src/app/model/thingie/templates';
import { IUserAppSettings } from 'src/app/model/user-app-settings';
import { IUserData } from 'src/app/model/userdata';
import { UserAppSettingsService } from 'src/app/services/app-settings/user-app-settings.service';
import { DeviceService } from 'src/app/services/device/device.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UINotificationService } from 'src/app/services/ui-notification/uinotification.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { canBeTurboTemplate } from 'src/app/utility/templates/can-be-turbo-template';
import { isNotNull } from 'src/app/utility/types';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';

type TemplateType = IUserData<IApplicationTemplate>;

export type DialogResult = undefined | null;

type TemplateListsObject = {
  turbos: TemplateType[];
  available: TemplateType[];
  disabled: TemplateType[];
};

@Component({
  selector: 'app-turbo-template-picker-dialog',
  templateUrl: './turbo-template-picker-dialog.component.html',
  styleUrls: ['./turbo-template-picker-dialog.component.scss']
})
export class TurboTemplatePickerDialogComponent implements OnDestroy {
  @ViewChild('select')
  _select?: MatSelect;

  _filterControl = new FormControl('');

  _templates$ = new BehaviorSubject<
  {
    turbos: TemplateType[];
    available: TemplateType[];
    disabled: TemplateType[];
  } | null
  >(null);

  private deviceListCache$ = new ReplaySubject<IPojoDevice[]>(1);

  private reOrderEvent$ = new Subject<{ currentIndex: number; previousIndex: number }>();

  private subsink = new SubSink();

  private turboChanges$ = new Subject<{
    type: 'add' | 'remove';
    turbo: TemplateType;
  }>();

  constructor(
    private appSettings: UserAppSettingsService,
    deviceService: DeviceService,
    private dialogRef: MatDialogRef<TurboTemplatePickerDialogComponent, undefined>,
    private templateService: TemplateService,
    private uiNotifcations: UINotificationService,
    private userDataService: UserDataService
  ) {
    // Eagerly load the device list into the cache
    this.subsink.sink = deviceService.getDevices()
      .pipe(first())
      .subscribe(this.deviceListCache$);

    this.initializeTemplates();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  _setGrabbingCursor() {
    document.body.style.cursor = 'grabbing';
  }

  _resetGrabbingCursor() {
    document.body.style.cursor = 'unset';
  }

  private initializeTemplates() {
    const templatesOrdered$ = this.initFilteredAndOrderedTemplates();

    this.subsink.sink = templatesOrdered$.subscribe(this._templates$);

    // Re-order templates after drag'n'drop
    this.trackReOrdering(templatesOrdered$);

    // Additions/Removals
    this.trackAddMovals(templatesOrdered$);
  }

  /**
   * Track re-ordering of the Turbo list
   *
   * @param modifiableSource$ BehaviorSubject containing the lists which
   *    will be worked on. The subject will have the changes pushed as
   *    it's next value.
   */
  private trackReOrdering(modifiableSource$: BehaviorSubject<TemplateListsObject>) {
    // Here we can't just combineLatest with templatesOrdered$,
    // because if a settings refresh will trigger the chain again,
    // we would then re-order a wrong or potentially non-existing element.
    this.subsink.sink = this.reOrderEvent$.pipe(
      map(reOrderEvent => {
        const templates = modifiableSource$.value;

        moveItemInArray(
          templates.turbos,
          reOrderEvent.previousIndex,
          reOrderEvent?.currentIndex
        );

        return templates;
      })
    ).subscribe(modifiableSource$);
  }

  private initFilteredAndOrderedTemplates() {
    const lowerCaseFilter$ = this._filterControl
      .valueChanges
      .pipe(
        filter(isNotNull),
        startWith(''),
        distinctUntilChanged(),
        debounceTime(DefaultConfig.general.userInputDefaultDebounce_Milliseconds),
        map<string, string>(val => val.toLowerCase())
      );

    const allTemplates$ = this.userDataService
      .getAllUserData('APPLICATION_TEMPLATE')
      .pipe(first());

    const turboTemplates$ = this.templateService.loadTurboTemplates()
      .pipe(first());

    const templatesOrdered$ = new BehaviorSubject<TemplateListsObject>({
      available: [],
      disabled: [],
      turbos: []
    });

    this.subsink.sink = combineLatest([
      allTemplates$,
      lowerCaseFilter$,
      turboTemplates$,
      this.deviceListCache$
    ])
      .pipe(
        map(([allTemplates, filter, turboTemplates, devices]) => {
          // Split application templates into
          // pickable-as-turbos (available)
          // and unavailable templates,
          // then filter them by the `filter` term

          const available: TemplateType[] = [];
          const disabled: TemplateType[]  = [];

          for (const t of allTemplates) {
            // Exclude turbo templates from being added to
            // `available` or `disabled` templates
            const idx = turboTemplates.findIndex(turbo => turbo._id === t._id);
            if (idx !== -1) {
              continue;
            }

            // Templates in the drop-down are filtered
            // depending on user search input
            if (t.value.name.toLowerCase().includes(filter)) {
              if (this.canBeTurbo(t, devices)) {
                available.push(t);
              } else {
                disabled.push(t);
              }
            }
          }

          return {
            turbos: turboTemplates,
            available: available.sort((a, b) => a.value.name.localeCompare(b.value.name)),
            disabled: disabled.sort((a, b) => a.value.name.localeCompare(b.value.name))
          };
        })
      )
      .subscribe(templatesOrdered$);

    return templatesOrdered$;
  }

  /**
   * Track additions and removals
   *
   * @param modifiableSource$ Behavior subject containing the lists which
   *    will be worked on. The subject will have the changes pushed as
   *    it's next value.
   */
  private trackAddMovals(modifiableSource$: BehaviorSubject<TemplateListsObject>) {
    this.subsink.sink = this.turboChanges$.pipe(
      map(changeset => {
        const { available, turbos } = modifiableSource$.value;
        const turbo = changeset.turbo;

        if (changeset.type === 'add') {
          turbo.value.isTurboTemplate = true;

          const idx = available.findIndex(a => a._id === turbo._id);
          available.splice(idx, 1);

          turbos.push(turbo);
        } else {
          turbo.value.isTurboTemplate = undefined;

          const idx = turbos.findIndex(a => a._id === turbo._id);
          turbos.splice(idx, 1);

          available.push(turbo);
        }

        // Reset the value state of the select element
        // for being able to select the same element again
        // after it was the last-removed element.
        this._select!.value = undefined;

        return modifiableSource$.value;
      })
    ).subscribe(modifiableSource$);
  }

  _selectTurbo(turbo: TemplateType) {
    turbo.value.isTurboTemplate = true;

    this.turboChanges$.next({
      type: 'add',
      turbo
    });
  }

  _deselectTurbo(turbo: TemplateType) {
    turbo.value.isTurboTemplate = undefined;

    this.turboChanges$.next({
      type: 'remove',
      turbo
    });
  }

  _reOrderElement(event: CdkDragDrop<TemplateType>) {
    this.reOrderEvent$.next({
      currentIndex: event.currentIndex,
      previousIndex: event.previousIndex
    });
  }

  async _saveChanges() {
    // Store list of turbo templates in user settings

    const turboList = this._templates$.value!.turbos;

    this.subsink.sink = this.appSettings
      .loadUserAppSettings()
      .pipe(
        first(),
        switchMap(settings => {
          const updatedSettings: IUserAppSettings = {
            ...settings,
            turboTemplates: turboList.map(t => t._id!)
          };

          return this.appSettings.storeUserAppSettings(updatedSettings);
        }),
        tap(() => this.uiNotifcations.displaySnackbar({
          message: 'Turbo Template configuration saved'
        }))
      )
      .subscribe(() => this.dialogRef.close(undefined));
  }

  /**
   * Determine if a template can be used as a turbo template
   * based on it's device setup
   *
   * @param template The template to check
   * @param devices A list of all devices
   * @returns `true` if the device can be used as a turbo template,
   *  `false` otherwise
   */
  private canBeTurbo(template: TemplateType, devices: IPojoDevice[]) {
    return canBeTurboTemplate(template, devices);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TurboTemplatePickerDialogService {
  constructor(private dialog: MatDialog) {}

  public open() {
    return this.dialog.open<
      TurboTemplatePickerDialogComponent,
      undefined,
      DialogResult
    >(
      TurboTemplatePickerDialogComponent,
      {
        panelClass: '5042440b-25d0fe8d5cab-turbopicker'
      }
    );
  }
}

