import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialImportsModule } from 'src/app/material-imports.module';
import { CustomPipesModule } from 'src/app/utility/pipes/custom-pipes.module';
import { RangeDateTimePickerModule } from '../range-date-time-picker/range-date-time-picker.module';
import { AnnotationFileDragDropComponent } from './annotation-file-drag-drop/annotation-file-drag-drop.component';

@NgModule({
  declarations: [
    AnnotationFileDragDropComponent
  ],
  exports: [
    AnnotationFileDragDropComponent
  ],
  imports: [
    CommonModule,
    MaterialImportsModule,
    FormsModule,
    ReactiveFormsModule,
    RangeDateTimePickerModule,
    CustomPipesModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AnnotationViewModule { }
