<div
  class="input-wrapper flex flex-nowrap rounded-sm items-center px-2 h-[46px] gap-1 cursor-text"
  [class.disabled-opacity]="disabled"
>
  <div class="flex items-center justify-center">
    <ion-icon
      name="time-outline"
      class="align-middle"
    ></ion-icon>
  </div>

  <div class="flex">
    <div>
      <input
        [disabled]="disabled"
        name="hours"
        type="number"
        min="0"
        max="23"
        size="1"
        placeholder="hh"
        [value]="_value['hours']" />
    </div>
    <div>:</div>
    <div>
      <input 
        [disabled]="disabled"
        name="minutes"
        type="number"
        min="0"
        max="59"
        size="1"
        placeholder="mm"
        [value]="_value['minutes']" />
    </div>
    <div>:</div>
    <div>
      <input 
        [disabled]="disabled"
        name="seconds"
        type="number"
        min="0"
        max="59"
        size="1"
        placeholder="ss"
        [value]="_value['seconds']" />
    </div>
  </div>

  <div class="h-6 w-6 flex items-center justify-center">
    <button
      [disabled]="disabled"      
      *ngIf="_hasValues"
      mat-icon-button
      id="clear-button"
      class="clear-icon-button-background clear-icon-button-flex-styling"
      (click)="clear()"
    >
      <ion-icon
        name="close-circle-outline"
      ></ion-icon>
    </button>
  </div>

</div>
