const colorDict = {
  orange: [
    'MPS',
    'CGQ',
    'LIS',
    'LIS + MPS',
    'LIS + CGQ',
    'BioR',
    'BioR Pro',
    'pH reader',
    'DO reader',
    'pHDO reader',
    'DOTS pH',
    'DOTS DO',
    'DOTS pHDO',
    'Shaker',
  ],
  blue: [
    'Microbial',
    'Cell culture',
  ],
  indigo: [
    'Shake flask',
    'Bottle',
    'Flow loop',
    'Environment',
    'Fridge',
    'Freezer',
    'Bioreactor'
  ],
  azure: [],
};
const order = { azure: 0, blue: 1, orange: 2, indigo: 3 };
const colorMap = new Map<string, keyof typeof colorDict>(Object.entries(colorDict)
  .flatMap(([color, chips]) => chips
    .map(chip => ([chip, color as keyof typeof colorDict])) ));

export function extractTemplateChipsFromName(name: string) {
  const parts = /(.*?)((\s*\[[^\]]+\])+)([^[\]]*)$/.exec(name);
  if (parts === null) {
    return ({ name, chips: [], fullName: name });
  }
  let basename = parts[1];
  if (parts[4].length > 0) {
    basename += ' ' + parts[4];
  }
  parts[2] = parts[2].trim();
  const chips = parts[2].substring(1, parts[2].length - 1).split(/\]\s*\[/)
    .map(part => part.trim())
    .map(chip => ({ name: chip, color: colorMap.get(chip) ?? 'azure' }))
    .sort(({ color: color1 }, { color: color2 }) => order[color1] - order[color2]);

  return { name: basename, chips, fullName: basename + ' ' + chips.map(({ name }) => `[${name}]`).join(' ') };
}
