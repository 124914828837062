<!-- dd:hh:mm:ss - dd:hh:mm:ss -->
<div
  class="input-wrapper rounded-sm flex flex-nowrap items-center px-2 h-[46px] gap-1 cursor-text"
>
  <div class="c-icon-button color-gray-5">
    <ion-icon
      name="time-outline"
      class="align-middle"
    ></ion-icon>
  </div>

  <form 
    class="self-stretch flex flex-nowrap items-center"
    [formGroup]="_controls"
  >
    <!-- dd -->
    <ng-container *ngIf="_hasDay$ | async">
      <input
        type="number"
        min="0"
        max="99"
        maxlength="2"
        placeholder="dd"
        formControlName="f_dd"
      />
      <div>:</div>
    </ng-container>
  
    <!-- hh -->
    <input
      type="number"
      min="0"
      max="23"
      placeholder="hh"
      formControlName="f_hh"
    />
    <div>:</div>
  
    <!-- mm -->
    <input
      type="number"
      min="0"
      max="59"
      placeholder="mm"
      formControlName="f_mm"
    />
    
    <!-- ss -->
    <ng-container *ngIf="_hasSeconds$ | async">
      <div>:</div>
      <input
        type="number" 
        min="0"
        max="59"
        placeholder="ss"
        formControlName="f_ss"
      />
    </ng-container>
  
    <div>&nbsp;-&nbsp;</div>
  
    <!-- dd -->
    <ng-container *ngIf="_hasDay$ | async">
      <input
        type="number"
        min="0"
        max="99"
        maxlength="2"
        placeholder="dd"
        formControlName="t_dd"
      />
      <div>:</div>
    </ng-container>
  
    <!-- hh -->
    <input
      type="number"
      min="0"
      max="23"
      placeholder="hh"
      formControlName="t_hh"
    />
    <div>:</div>
  
    <!-- mm -->
    <input
      type="number"
      min="0"
      max="59"
      placeholder="mm"
      formControlName="t_mm"
    />

    <!-- ss -->
    <ng-container *ngIf="_hasSeconds$ | async">
      <div>:</div>
      <input
        type="number" 
        min="0"
        max="59"
        placeholder="ss"
        formControlName="t_ss"
      />
    </ng-container>
  </form>

  <div class="h-24 w-24">
    <button
      *ngIf="_hasValues"
      mat-icon-button
      id="clear-button"
      class="c-icon-button"
      (click)="clear()"
    >
      <ion-icon name="close-circle-outline"></ion-icon>
    </button>
  </div>

</div>
