import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { IApplicationTemplate } from 'src/app/model/thingie/templates';
import { IUserData } from 'src/app/model/userdata';
import { getDeviceConfigurationInfoForThingies } from '../thingie/get-device-configuration-info';

/**
 * Determine if a template can be used as a turbo template
 * based on it's device setup
 *
 * @param template The template to check
 * @param devices A list of all devices
 * @returns `true` if the device can be used as a turbo template,
 *  `false` otherwise
 */
export function canBeTurboTemplate(
  template: IUserData<IApplicationTemplate>,
  devices: IPojoDevice[]
) {
  const appTemplate = template.value;
  if (!appTemplate.thingies || appTemplate.thingies.length === 0) {
    return false;
  }

  const info = getDeviceConfigurationInfoForThingies(appTemplate.thingies, devices);
  if (info.find(d => d.status === 'wrongDevice')) {
    return false;
  }

  return true;
}
