import { NgModule } from '@angular/core';
import { DeviceByIdPipe, DeviceNamePipe } from './devices-name';
import { PureFunctionCallPipe } from './pure-member-call/pure-member-call.pipe';
import { RelativeDatePipe } from './relative-date/relative-date-pipe';
import { ShortcutPipe } from './shortcut.pipe';
import { ThingiePipe } from './thingie-pipe';
import { BoldPipeByChar, BoldPipeByEachWord, BoldPipeByWord } from './bold-pipes';

@NgModule({
  declarations: [
    RelativeDatePipe,
    PureFunctionCallPipe,
    DeviceNamePipe,
    BoldPipeByChar,
    BoldPipeByWord,
    BoldPipeByEachWord,
    ThingiePipe,
    DeviceByIdPipe,
    ShortcutPipe,
  ],
  exports: [
    RelativeDatePipe,
    PureFunctionCallPipe,
    BoldPipeByChar,
    BoldPipeByWord,
    BoldPipeByEachWord,
    DeviceNamePipe,
    ThingiePipe,
    DeviceByIdPipe,
    ShortcutPipe,
  ]
})
export class CustomPipesModule {}
