import { Directive } from '@angular/core';
import { MatLegacyTooltip } from '@angular/material/legacy-tooltip';

@Directive({
  selector: '[appMatTooltipNoninteractive]'
})
export class MatTooltipNoninteractiveDirective {
  constructor(private tooltip: MatLegacyTooltip) {
    const _tooltip = tooltip as any;
    _tooltip._defaultOptions = Object.assign({}, _tooltip._defaultOptions);

    _tooltip._defaultOptions.disableTooltipInteractivity = true;
  }
}
