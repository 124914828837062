<mat-form-field class="mat-dropdown">
  <mat-select
    (selectionChange)="filterThingieByProject($event)"
    placeholder="Filter by project"
    [(value)]="projectFilter"
  >
    <mat-select-trigger>
      {{projectFilterLabel}}
    </mat-select-trigger>
    <mat-option
      [value]="'all'">
      <ion-icon class="project-icon" name="file-tray-stacked-outline"></ion-icon>
      All projects
    </mat-option>
    <mat-option
      [ngClass]="{
        'disabled': !project.projectNotEmpty
      }"
      *ngFor="let project of projectInfo"
      [value]="project.projectId"
    >
      <ion-icon class="project-icon" *ngIf="project.projectNotEmpty" name="file-tray-full-outline"></ion-icon>
      <ion-icon
        class="project-icon"
        *ngIf="!project.projectNotEmpty"
        color="medium"
        name="file-tray-outline"
      >
      </ion-icon>
      {{ project.projectName }}
      <span
        *ngIf="!project.projectNotEmpty"
        class="hidden screen-reader-accessible"
      >
        (Project contains no configured graphs, yet)
      </span>
    </mat-option>
  </mat-select>
</mat-form-field>
