<div class="input-wrapper flex flex-nowrap rounded-sm items-center px-2 h-[46px] gap-1 cursor-text">
  <div class="flex no-wrap items-center justify-center">
    <input
      [disabled]="disabled"
      type="number"
      placeholder="no limit"
      class="text-right"
      [(ngModel)]="_min"
      (ngModelChange)="_emit()" />

    <span>&nbsp;to&nbsp;</span>

    <input
      [disabled]="disabled"
      type="number"
      placeholder="no limit"
      class="text-left"
      [(ngModel)]="_max"
      (ngModelChange)="_emit()" />
  </div>

  <div class="h-6 w-6 flex items-center justify-center">
    <button
      [disabled]="disabled"
      *ngIf="_hasValues"
      mat-icon-button
      id="clear-button"
      class="c-icon-button ml-auto"
      (click)="clear()"
    >
      <ion-icon
        name="close-circle-outline"
        class="align-middle"
      ></ion-icon>
    </button>
  </div>
</div>
