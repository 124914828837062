import { PojoDevice } from 'src/app/model/backend';

const values_byDeviceType: { [key: string]: { [deviceType in (PojoDevice['deviceInputTypes'][0] | '_unknown_')]: string } } = {
  icons: {
    _unknown_: '/assets/device-icons/unknown-device.svg',
    AquilaLisDrive: '/assets/device-icons/LIS-drive.svg',
    AquilaLisCoordinator: '/assets/device-icons/lis-coordinator.svg',
    AquilaCGQSensorPlate: '/assets/device-icons/CGQ-sensor.svg',
    AquilaCGQBaseStation16: '/assets/device-icons/CGQ-hub-16.svg',
    AquilaCGQBaseStation: '/assets/device-icons/CGQ-hub-8.svg',
    AquilaBioRSensorPlate: '/assets/device-icons/bior-sensor.svg',
    AquilaDotsPHDO: '/assets/device-icons/pH-DO.svg',
    AquilaDotsPH: '/assets/device-icons/pH.svg',
    AquilaDotsDO: '/assets/device-icons/DO.svg',
    AquilaDotsMpsfs: '/assets/device-icons/MPSFS.svg',
    Unknown: '/assets/device-icons/unknown-device.svg',
    AquilaLora: '/assets/device-icons/unknown-device.svg',
    Chirpstack: '/assets/device-icons/unknown-device.svg',
    AquilaDotsMpsfsPHDO: '/assets/device-icons/MPSFS.svg',
    AquilaDotsMpsfsEnvironment: '/assets/device-icons/MPSFS.svg',
    AquilaDotsBiorPro: '/assets/device-icons/bioR-Pro.svg',
    KuhnerShakerLTX: '/assets/device-icons/shaker.svg',
  },

  illustrations: {
    _unknown_: '/assets/device-illustrations/other-device.svg',
    AquilaLisDrive: '/assets/device-illustrations/LIS-drive.svg',
    AquilaLisCoordinator: '/assets/device-illustrations/lis-coordinator.svg',
    AquilaCGQSensorPlate: '/assets/device-illustrations/CGQ-sensor.svg',
    AquilaDotsPHDO: '/assets/device-illustrations/DOTS-pH-DO.svg',
    AquilaDotsPH: '/assets/device-illustrations/DOTS-pH.svg',
    AquilaDotsDO: '/assets/device-illustrations/DOTS-DO.svg',
    AquilaDotsMpsfs: '/assets/device-illustrations/MPSFS.svg',
    AquilaDotsMpsfsPHDO: '/assets/device-illustrations/MPSFS.svg',
    AquilaBioRSensorPlate: '/assets/device-illustrations/bior-sensor.svg',
    AquilaCGQBaseStation: '/assets/device-illustrations/CGQ-hub-8.svg',
    AquilaCGQBaseStation16: '/assets/device-illustrations/CGQ-hub-16.svg',
    Unknown: '/assets/device-illustrations/other-device.svg',
    AquilaLora: '/assets/device-illustrations/other-device.svg',
    Chirpstack: '/assets/device-illustrations/other-device.svg',
    AquilaDotsMpsfsEnvironment: '/assets/device-illustrations/MPSFS.svg',
    AquilaDotsBiorPro: '/assets/device-illustrations/bioR-Pro.svg',
    KuhnerShakerLTX: '/assets/device-illustrations/generic-shaker.png'
  },

  deviceLabels: {
    _unknown_: 'Unknown device: {{type}}',
    AquilaLora: 'Multiparameter sensor',
    AquilaLisDrive: 'LIS drive',
    AquilaLisCoordinator: 'LIS coordinator',
    AquilaCGQSensorPlate: 'CGQ sensor plate',
    AquilaBioRSensorPlate: 'BioR sensor plate',
    AquilaCGQBaseStation: 'CGQ Hub (8 Ports)',
    AquilaCGQBaseStation16: 'CGQ Hub (16 Ports)',
    AquilaDotsPHDO: 'DOTS pH/DO sensor',
    AquilaDotsPH: 'DOTS pH sensor',
    AquilaDotsDO: 'DOTS DO sensor',
    AquilaDotsMpsfs: 'MPS',
    AquilaDotsMpsfsPHDO: 'MPS',
    AquilaDotsMpsfsEnvironment: 'MPS',
    AquilaDotsBiorPro: 'BioR Pro',
    Unknown: 'Unknown device',
    Chirpstack: '',
    KuhnerShakerLTX: 'Kuhner Shaker LTX'
  },

  deviceShortLabels: {
    _unknown_: 'Unknown device: {{type}}',
    AquilaLora: 'Multiparameter sensor',
    AquilaLisDrive: 'LIS',
    AquilaLisCoordinator: 'LIS coordinator',
    AquilaCGQSensorPlate: 'CGQ',
    AquilaBioRSensorPlate: 'BioR',
    AquilaCGQBaseStation: 'CGQ Hub (8 Ports)',
    AquilaCGQBaseStation16: 'CGQ Hub (16 Ports)',
    AquilaDotsPHDO: 'DOTS pH/DO',
    AquilaDotsPH: 'DOTS pH',
    AquilaDotsDO: 'DOTS DO',
    AquilaDotsMpsfs: 'MPS',
    AquilaDotsMpsfsPHDO: 'MPS',
    AquilaDotsMpsfsEnvironment: 'MPS',
    AquilaDotsBiorPro: 'BioR Pro',
    Unknown: 'Unknown device',
    Chirpstack: '',
    KuhnerShakerLTX: 'Shaker'
  },


};

export const i18nEnglish = {
  assets: {
    icons: {
      byDeviceType: values_byDeviceType.icons,
      byConnectionState: {
        Connected: '/assets/connection-icons/Connected-Icon.svg',
        Working: '/assets/connection-icons/Connected-Icon.svg',
        NotConnected: 'assets/connection-icons/NotConnected-Icon.svg',
        Error: 'assets/connection-icons/Error-Icon.svg',
      },
      bySignalStrength: {
        Excellent: '/assets/signal-strength-icons/very-good.svg',
        Great: '/assets/signal-strength-icons/good.svg',
        Good: '/assets/signal-strength-icons/ok.svg',
        Bad: '/assets/signal-strength-icons/bad.svg',
        Poor: '/assets/signal-strength-icons/no-signal.svg',
      }
    },

    illustrations: {
      thingie: {
        flask: '',
        bioreactor: '',
      },
      byDeviceType: values_byDeviceType.illustrations,
    },
  },

  deviceLabels: values_byDeviceType.deviceLabels,

  deviceShortLabels: values_byDeviceType.deviceShortLabels,

  wizard: {
    basicSettingsStep: {
      experimentNameTaken: 'Name is taken already. Please provide a different name.'
    }
  }
};
