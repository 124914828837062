
<!-- Dialog title row -->
<div class="mb-4 flex flex-nowrap items-center">
  <h2 mat-dialog-title class="!mb-0">
    Import experiment
  </h2>

  <!-- spacer -->
  <div class="ml-auto"></div>

  <button
    mat-icon-button
    matTooltip="Close"
    [mat-dialog-close]="undefined"
    class=" ml-2"
    [disabled]="_importInProgress"
  >
    <ion-icon name="close-outline" />
  </button>
</div>


<!-- Main content -->
<div
  mat-dialog-content
  class="m-0 !p-0 relative flex-auto flex flex-col"
>

  <mat-form-field class="w-full">
    <mat-label>Target team</mat-label>
    <mat-select [formControl]="_teamId">
      <mat-option
        *ngFor="let team of _teamList"
        [value]="team._id"
      >
        {{team.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br/>

  <mat-form-field class="w-full">
    <mat-label>Target Project</mat-label>
    <mat-select [formControl]="_projectId">
      <mat-option
        *ngFor="let project of _projectList"
        [value]="project._id"
      >
        {{project.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br/>

  <app-annotation-file-drag-drop
    (uploadedFiles)="_setFiles($event)"
    [filesizeLimitMB]="512"
    [fileExtensions]="'.dotsdata'"
     />

  <br />
</div>

<mat-dialog-actions>
  <button
    mat-stroked-button
    [mat-dialog-close]="undefined"
    [disabled]="_importInProgress"
  >
    Cancel
  </button>

  <span class="ml-4">
    <button
      mat-flat-button
      color="primary"
      (click)="_import()"
      matTooltip="Importing is only possible if you are assigned to a team/project. Please contact your DOTS admin/team leader."
      [matTooltipDisabled]="userCanImportFiles"
      [disabled]="_importInProgress || !userCanImportFiles"
    >
      Import
    </button>

    <ion-spinner *ngIf="_importInProgress" name="crescent" />
  </span>
</mat-dialog-actions>
