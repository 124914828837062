import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, interval, merge } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserAppSettings } from 'src/app/model/backend';
import { IUserAppSettings } from 'src/app/model/user-app-settings';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { LogService } from '../loggers/logger.service';

@Injectable({
  providedIn: 'root'
})
export class UserAppSettingsService implements OnDestroy {
  private cache$ = new ReplaySubject<IUserAppSettings>(1);

  private subsink = new SubSink();

  constructor(
    private http: HttpClient,
    log: LogService
  ) {
    const periodicUpdate$ = interval(60000).pipe(
      switchMap(() => this.loadUserAppSettingsHelper()),
    );

    const initialRequest$ = this.loadUserAppSettingsHelper();

    this.subsink.sink = merge(initialRequest$, periodicUpdate$)
      .pipe(tap(() => log.debug('App settings refreshed')))
      .subscribe(this.cache$);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  loadUserAppSettings(): Observable<IUserAppSettings> {
    return this.cache$.asObservable();
  }

  private loadUserAppSettingsHelper(): Observable<IUserAppSettings> {
    return this.http.get<UserAppSettings>(
      DefaultConfig.uris.userApplicationSettings
    ).pipe(
      map<UserAppSettings, IUserAppSettings>(settings => ({
        turboTemplates: settings.turboTemplates ?? [],
        thingieDetailAnnotationPanelOpen: settings.thingieDetailAnnotationPanelOpen,
        appTemplateFavorites: settings.appTemplateFavorites ?? []
      }))
    );
  }

  /**
   * Store new/updated user application settings
   *
   * Notifies subscribers of {@link loadUserAppSettings}
   *
   * @param settings The new/updated settings object
   * @returns Observable which finishes after the update has been made
   *   and all subscribers have been notified
   */
  storeUserAppSettings(settings: IUserAppSettings): Observable<void> {
    return this.http.put<void>(
      DefaultConfig.uris.userApplicationSettings,
      settings
    ).pipe(tap(() => this.cache$.next(settings)));
  }
}
