import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

function toDoubleDigitStr(val: number | string): string {
  const str = `${val}`;

  return '0'.repeat(2 - Math.min(str.length, 2)) + str;
}

export interface ITime {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-simple-time-input',
  templateUrl: './simple-time-input.component.html',
  styleUrls: ['./simple-time-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SimpleTimeInputComponent,
      multi: true
    }
  ]
})
export class SimpleTimeInputComponent implements AfterViewInit, OnDestroy {
  @Output()
  valueChange = new EventEmitter<ITime | null>();

  @Input()
  set value(v: ITime | undefined | null) {
    if (!v) {
      this.clear(false);
      return;
    }

    const entries = Object
      .entries(v)
      .map(([key, value]) => [key, toDoubleDigitStr(value)]);

    this._value = Object.fromEntries(entries);
  }

  @Input()
  disabled = false; // TODO: propagate

  /**
   * Allow negative values.
   *
   * Helpful for offsets.
   */
  @Input()
  allowNegatives = false;

  get _hasValues() {
    const res = !!this._value.hours
      || !!this._value.minutes
      || !!this._value.seconds;
    return res;
  }

  _value: { [x: string]: any } = {
    hours: '',
    minutes: '',
    seconds: ''
  };


  //-- ControlValueAccessor --

  private onChange?: (value: ITime | null) => void;
  private onTouched?: () => void;

  writeValue(value: ITime | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: ITime | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  //-- End ControlValueAccessor --


  private ref: HTMLElement;
  private listenerController = new AbortController();

  constructor(
    private changeDetector: ChangeDetectorRef,
    el: ElementRef<HTMLElement>
  ) {
    this.ref = el.nativeElement;
  }


  ngAfterViewInit(): void {
    this.ref
      .querySelectorAll('input')
      .forEach(input => {
        input.addEventListener(
          'input',
          (ev) => this.onInput(ev as InputEvent),
          { signal: this.listenerController.signal }
        );

        input.addEventListener(
          'blur',
          ev => this.onBlur(ev),
          { signal: this.listenerController.signal }
        );

        if (this.allowNegatives) {
          input.removeAttribute('min');
          input.removeAttribute('max');
        }
      });
  }

  ngOnDestroy(): void {
    this.listenerController.abort();
  }

  /**
   * Resets the input field
   */
  public clear(emit = true) {
    this._value = {
      hours: '',
      minutes: '',
      seconds: ''
    };

    if (emit) {
      this.emit();
    }
  }

  private onInput($event: Event) {
    const input = $event.currentTarget as HTMLInputElement;
    const num = +input.value;
    const min = +input.min;
    const max = +input.max;

    if (!this.allowNegatives) {
      if (num < min) {
        input.value = toDoubleDigitStr(input.min);
      } else if (num > max) {
        input.value = toDoubleDigitStr(input.max);
      }
    }

    this._value[input.name] = input.value;

    this.changeDetector.markForCheck();
    this.emit();
  }

  private onBlur($event: FocusEvent) {
    this.onTouched?.();

    const input = $event.target as HTMLInputElement;
    const isNegative = Math.sign(+input.value) < 0 ? true : false;

    // Enforce there are always 2 digits (e.g. '1' => '01')
    // (except when hitting the clear button)
    if (input.value === '' || input.value.length < 2) {
      input.value = '0'.repeat(2 - input.value.length) + input.value;
    } else if (input.value.length > 2 && (input.name !== 'hours' && this.allowNegatives)) {
      const tmp = input.value.slice(-2);
      input.value = isNegative ? '-' + tmp : tmp;
    }
    this._value[input.name] = input.value;
    this.changeDetector.markForCheck();
  }

  private emit() {
    if (
      this._value.hours === ''
      && this._value.minutes === ''
      && this._value.seconds === ''
    ) {
      this.valueChange.emit(null);
      this.onChange?.(null);
      return;
    }

    const result = {
      hours: this._value.hours !== '' ? +this._value.hours : 0,
      minutes: this._value.minutes !== '' ? +this._value.minutes : 0,
      seconds: this._value.seconds !== '' ? +this._value.seconds : 0
    };

    this.valueChange.emit(result);
    this.onChange?.(result);
  }
}
