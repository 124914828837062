import { NgModule } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { i18nEnglish } from 'src/assets/i18n/en';

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: () => ({
          getTranslation() {
            return of(i18nEnglish);
          },
        })
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: () => ({
          handle(params: MissingTranslationHandlerParams) {
            // if abc.xyz.blubb does not exist, try to translate abc.xyz._unknown_
            const keyParts = params.key.split('.');
            if (keyParts[keyParts.length - 1] === '_unknown_') {
              // abc.xyz._unknown_ does not exist, so nothing we can do.
              return throwError('not found');
            }
            keyParts[keyParts.length - 1] = '_unknown_';
            const unknownKey = keyParts.join('.');
            // get the unknown translation, if that fails just return the key
            const translation = params.translateService.get(unknownKey, params.interpolateParams)
              .pipe(catchError(() => of(params.key)));
            return translation;
          }
        })
      }
    })
  ],
  exports: [
    TranslatePipe,
  ]
})
export class NgxTranslateModule { }
