import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from 'src/app/directives/directive.module';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { SimpleTimeInputComponent } from './simple-time-input/simple-time-input.component';
import { TimePeriodPickerComponent } from './time-period-picker/time-period-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { YAxisLimitsInputComponent } from './yaxis-limits-input/yaxis-limits-input.component';

@NgModule({
  declarations: [
    SimpleTimeInputComponent,
    TimePeriodPickerComponent,
    YAxisLimitsInputComponent,
    TimePickerComponent
  ],
  imports: [
    CommonModule,
    IonicImports,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule,
    DirectiveModule,
    IonicImports,
  ],
  exports: [
    SimpleTimeInputComponent,
    TimePeriodPickerComponent,
    YAxisLimitsInputComponent,
    TimePickerComponent
  ]
})
export class CustomInputControlsModule {}
