import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector -- affect ng-template (???)
  selector: 'ng-template[app-context-type]'
})
export class ContextTypeDirective<T> {
  @Input('app-context-type')
  _instanceOfTypeOfTemplateContext!: T;

  constructor(private contentTemplate: TemplateRef<T>) {
  }

  static ngTemplateContextGuard<TypeToken>(dir: ContextTypeDirective<TypeToken>, ctx: unknown): ctx is TypeToken {
    return true;
  }
}
