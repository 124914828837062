import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { DefaultConfig } from 'src/assets/default.config';
import { IUpdateInfo, UpdatesService } from '../updates.service';

@Injectable({
  providedIn: 'root'
})

export class UpdatesServiceImpl implements UpdatesService {
  constructor(private http: HttpClient) { }

  async checkForSoftwareUpdate(): Promise<IUpdateInfo> {
    const remoteVersion = (await this.getRemoteDOTSVersion()).split('_')[1];
    const localVersion = await this.getLocalDOTSVersion();

    const result: IUpdateInfo = {
      currentVersion: localVersion,
      newVersion: remoteVersion,
      updateAvailable: localVersion !== remoteVersion ? true : false,
    };

    return result;
  }

  async getRemoteDOTSVersion(): Promise<string> {
    const versionText = (await lastValueFrom(this.http.get(
      DefaultConfig.uris.remoteCocoVersion,
      {
        responseType: 'text'
      }
    )))!;
    return versionText;
  }

  private async getLocalDOTSVersion(): Promise<string> {
    const versionText = (await lastValueFrom(this.http.get(
      DefaultConfig.uris.version.dataprocessing,
      {
        responseType: 'text'
      }
    )))!;

    return versionText;
  }
}
