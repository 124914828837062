<mat-toolbar class="menu-bar gap-x-6 gap-y-1">
  <div class="logoContainer">
    <a [routerLink]="'/dashboard/cards'" (click)="$event.stopImmediatePropagation(); dotsLogoClicked()">
      <img
        src="/assets/DOTS_Logo_Blue.svg" 
        class="logo"
      />
    </a>
  </div>

  <div *ngIf="searchResultsVisible" (click)="searchResultsVisible = false" class="search-backdrop" appKeyboardNavigator
    (mouseOver)="enableKeyboardHandler()"
    (mouseLeave)="disableKeyboardHandler()"></div>

  <div id="search" class="search flex-1" [ngClass]="{'search-front-z-index': searchResultsVisible}" 
    appKeyboardNavigator
    (mouseOver)="enableKeyboardHandler()"
    (mouseLeave)="disableKeyboardHandler()">
    <mat-form-field
      #searchInputContainer
      cdkOverlayOrigin
      #searchResultOrigin="cdkOverlayOrigin"
      class="!block"
    >
      <button mat-icon-button matPrefix>
        <ion-icon name="search-outline"></ion-icon>
      </button>

      <input
        matInput
        [formControl]="menuBarSearchInput"
        type="search"
        placeholder="Type to search"
        (focus)="initializeSearch()"
        (click)="searchResultsVisible = true"
        autocomplete="off"/>

      <button
        *ngIf="menuBarSearchInput.value?.length"
        mat-icon-button matSuffix
        (click)="clearMenuSearchBarInput()">
        <ion-icon name="close-circle-outline"></ion-icon>
      </button>
    </mat-form-field>

    <!-- Search result view -->
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="searchResultOrigin"
      [cdkConnectedOverlayOpen]="searchResultsVisible"
      [cdkConnectedOverlayOffsetY]="-10"
      [cdkConnectedOverlayMinWidth]="100"
      [cdkConnectedOverlayMinHeight]="40"
      (detach)="searchResultsVisible = false"
    >
      <div class="search__result-list-container">
        <!-- loading column -->
        <mat-list
          *ngIf="searchResultsDisplayLoading && !searchFewKeywordsIllusion"
          class="search__result-col overflow-hidden"
        >
          <ng-container *ngFor="let item of [].constructor(10); let i = index">
            <mat-list-item>
              <app-skeleton-loader width="24" height="24"></app-skeleton-loader>
              <app-skeleton-loader width="100" height="24" class="skeleton-name-full"></app-skeleton-loader>
            </mat-list-item>
          </ng-container>
        </mat-list>

        <ng-container *ngIf="searchFewKeywordsIllusion">
          <div class="search__result-col">
            <div class="search__result-row search__result-few-char-illusion">

              <div class="hint">
                <strong>Enter 3+ characters</strong> for results.
              </div>

              <img class="image" src="/assets/svg/search-illustration.svg" />
            </div>
          </div>
        </ng-container>


        <ng-template #singleThingie let-result="result">
          <a
              [routerLink]="result[0].route"
              class="list-item-anchor"
              (click)="searchResultsVisible = false; detachSearchCdkOverlay();"
            >
              <div class="search__result-content without-icon">
                <span [innerHTML]="result[0].item.name | boldEachWord:menuBarSearchInput.value?.length ? lastSearch! : ''"></span>
              </div>

              <ng-container *ngIf="(result[0].item | pureFnCall:castToIThingie) as thingie">
                <div class="experiment-information-wrapper">
                  <app-thingie-state-chip
                    [thingies]="(thingie | pureFnCall:getThingiesByIds | async) ?? []"
                  ></app-thingie-state-chip>
                </div>
              </ng-container>
              
            </a>
        </ng-template>
        <ng-template #stackThingie let-result="result">
            <mat-accordion class="stackThingieAccordion">
              <mat-expansion-panel togglePosition="before">
                <mat-expansion-panel-header>
                  <mat-panel-title class="experiment-title">
                    <span [innerHTML]="result[0].item?.baseName | boldEachWord:menuBarSearchInput.value?.length ? lastSearch! : ''"></span>
                  </mat-panel-title>

                  <mat-panel-description>
                    <ng-container *ngIf="(result[0].item | pureFnCall:castToIThingie) as thingie">
                      <div class="experiment-information-wrapper">
                        <ion-icon src="/assets/svg/replicates-cards.svg"></ion-icon>
                        <small class="mr-4">{{result.length}}</small>

                        <app-thingie-state-chip
                          [thingies]="(thingie | pureFnCall:getThingiesByIds | async) ?? []"
                          matTooltip="Combined object state"
                        ></app-thingie-state-chip>
                      </div>
                    </ng-container>
                  </mat-panel-description>
                </mat-expansion-panel-header>
            
                <ng-container *ngFor="let item of result">
                  <ng-container 
                        [ngTemplateOutlet]="singleThingie" 
                        [ngTemplateOutletContext]="{result:[item]}">
                    </ng-container>
                </ng-container>

        
              </mat-expansion-panel>
            </mat-accordion>
        </ng-template>

        <ng-container *ngIf="!searchResultsDisplayLoading && !searchFewKeywordsIllusion">
          <!-- column left -->
          <ng-container *ngIf="!searchFailed; else searchFailedTpl">
            <div class="search__result-col"
            appKeyboardNavigator
            (mouseOver)="enableKeyboardHandler()"
            (mouseLeave)="disableKeyboardHandler()">
              <div class="search__result-row">
                <span class="search__header">Objects</span>
                <!-- thingies/objects container -->
                <mat-list
                  *ngIf="objectsSearchResults.length"
                  class="search__result-list"
                >
                  <mat-list-item
                    #searchResultItem
                    appKeyboardNavigator
                    (arrowKeyPress)="handleArrowKeyPress($event)"
                    *ngFor="let experiment of groupByExperimentMap | keyvalue; index as index"
                    [ngClass]="{'focused-item': currentFocusedIndex === index && isKeyboardHandlerEnabled}"
                    (mouseOver)="updateFocusedIndex(index)"
                    class="mat-list-item item-866d-7781e6ae4708"
                  >
                    <ng-container 
                        [ngTemplateOutlet]="experiment.value.length === 1 ? singleThingie : stackThingie" 
                        [ngTemplateOutletContext]="{result:experiment.value}">
                    </ng-container>
                  </mat-list-item>
                </mat-list>

                <div
                  *ngIf=" !objectsSearchResults.length"
                  class="search__result-list"
                >
                  <div class="py-1 flex">
                    <div class="my-auto">
                      <span
                        class="device-illustration"
                      >
                        <img src="/assets/svg/no-results-icon.svg" />
                      </span>
                    </div>
                    <span class="ml-0.5 my-auto">No results.</span>
                  </div>
                </div>
                <!-- end of thingies/objects container -->
              </div>

            
              <div class="search__result-row">
                <span class="search__header">Devices</span>
                <!-- devices container -->
                <mat-list
                  *ngIf="!searchResultsDisplayLoading && devicesSearchResults.length"
                  class="search__result-list gap-2"
                >
                  <mat-list-item
                    #searchResultItem
                    appKeyboardNavigator 
                    (arrowKeyPress)="handleArrowKeyPress($event)"
                    *ngFor="let result of devicesSearchResults; index as index"
                    [ngClass]="{'focused-item': currentFocusedIndex === (index + groupByExperimentMap.size) && isKeyboardHandlerEnabled}"
                    (mouseOver)="updateFocusedIndex(index + groupByExperimentMap.size)"
                    class="mat-list-item item-866d-7781e6ae4708"
                    (click)="searchResultsVisible = false;"
                  >
                    <ng-container *ngIf="(result.item | pureFnCall:castToIPojoDevice) as device">
                      <a
                        [routerLink]="result.route"
                        class="list-item-anchor"
                      >
                        <span
                          class="device-illustration"
                        >
                          <img [src]="'assets.icons.byDeviceType.'+device.deviceInputTypes[0] | translate"/>
                        </span>
                        <div class="search__result-content">
                          <span [innerHTML]="result.item.name | boldEachWord:menuBarSearchInput.value?.length ? lastSearch! : ''"></span>
                        </div>

                        <div class="device-property-bubble-wrapper">
                            <ng-container *ngIf="(device | shortcut:'connectionStatus') as connectionStatus">
                              <ng-container *ngIf="connectionStatus === 'CONNECTED'">
                                <div
                                  *ngIf="!(result.item | pureFnCall:isDeviceInUse)"
                                  class="device-property-bubble green"
                                >
                                  <span>Free</span>
                                </div>
                                <div
                                  *ngIf="(result.item | pureFnCall:isDeviceInUse)"
                                  class="device-property-bubble blue"
                                >
                                  <span>In use</span>
                                </div>
                              </ng-container>


                              <div
                                *ngIf="connectionStatus === 'DISCONNECTED'"
                                class="device-property-bubble gray"
                              >
                                <span>Not connected</span>
                              </div>
                            </ng-container>
                        </div>
                      </a>
                    </ng-container>
                  </mat-list-item>
                </mat-list>
        
                <div
                  *ngIf="!searchResultsDisplayLoading && !devicesSearchResults.length"
                  class="search__result-list"
                >
                  <div class="py-1 flex">
                    <div class="my-auto">
                      <span
                        class="device-illustration"
                      >
                        <img src="/assets/svg/no-results-icon.svg" />
                      </span>
                    </div>
                    <span class="ml-0.5 my-auto">No results.</span>
                  </div>
                </div>
                <!-- end of devices container -->
              </div>
            </div>
          </ng-container>

          <ng-template #searchFailedTpl>
            <div class="search__result-list p-2">
              <h6>Search failed</h6>
              <p>Try again or contact your administrator.</p>
            </div>
          </ng-template>
        </ng-container>

        <!-- column right -->
        <div class="search__result-col"
          appKeyboardNavigator
          (mouseOver)="enableKeyboardHandler()"
          (mouseLeave)="disableKeyboardHandler()">
          <div class="search__result-row">
            <span class="search__header">Sort</span>
            <div class="flex flex-row flex-wrap py-1">
              <mat-radio-group>
                <mat-radio-button 
                *ngFor="let sort of sorts"
                [value]="sort.key"
                [checked]="sort.checked"
                (change)="sortChange($event)"
                >
                  {{sort.label}}
                </mat-radio-button>

              </mat-radio-group>
            </div>
          </div>

          <div class="search__result-row">
            <span class="search__header">Filter by project</span>
            <div class="py-1 mat-form-field-wrapper-pb-0">
              <app-filter-by-project
                [projectFilter]="projectFilter"
                (filterByProjectCallbackFunction)="projectChangeCallbackFunction($event)">

              </app-filter-by-project>
            </div>
          </div>

          <div class="search__result-row">
            <span class="search__header">Filter by type</span>
            <div class="flex flex-row flex-wrap gap-x-6 gap-y-1 py-1">
              <mat-checkbox
              *ngFor="let typeFilter of typeFilters"
              [checked]="typeFilter.checked"
              class="checkbox-child"
              (change)="typeFilterChange($event, typeFilter.key)"
              >
                {{typeFilter.label}}
              </mat-checkbox>
            </div>
          </div>

          <div class="search__result-row">
            <span class="search__header">Filter objects by status</span>
            <div class="flex flex-row flex-wrap gap-x-6 gap-y-1 py-1">
              <mat-checkbox
              *ngFor="let typeFilter of objectsByStatusFilters"
              [checked]="typeFilter.checked"
              [disabled]="typeFilter.disabled"
              class="checkbox-child"
              (change)="objectsByStatusFiltersChange($event, typeFilter.key)"
              >
                {{typeFilter.label}}
              </mat-checkbox>
            </div>
          </div>

          <div class="search__result-row">
            <span class="search__header">Filter devices by status</span>
            <div class="flex flex-row flex-wrap gap-x-6 gap-y-1 py-1">
              <mat-checkbox
              *ngFor="let typeFilter of devicesByStatusFilters"
              [checked]="typeFilter.checked"
              [disabled]="typeFilter.disabled"
              class="checkbox-child"
              (change)="devicesByStatusFiltersChange($event, typeFilter.key)"
              >
                {{typeFilter.label}}
              </mat-checkbox>

            </div>
          </div>

          <div class="search__result-row">
            <div class="mb-1">
              <hr />
            </div>
            <span class="search__header">Recent tags</span>
            <div class=" py-1">
              <app-chipped-autocomplete
                class="tag-chipped-autocomplete"
                [items]="recentTagsAutoCompleteList"
                [allowCustomItems]="true"
                (selectedItemsChange)="onRecentTagsSelected($event)">
              </app-chipped-autocomplete>
            </div>
            
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="flex items-center gap-2 ml-auto">
    <!-- Version check (for admins) -->
    <button
      *ngIf="isAdminView"
      mat-flat-button
      class="flex-button-wrapper"
      color="primary"
      (click)="checkForUpdate()"
      [matTooltip]="'Check for new software version'"
      [disabled]="isCheckingForSoftwareUpdate"
    >
      <ion-icon name="help-circle-outline"></ion-icon>
      <span>&nbsp;Check Version</span>
    </button>

    <!-- Create-button -->
    <app-split-button (click)="_openWizard()">
      <span buttonContent>Create</span>
      <ng-container *ngIf="_turboTemplates$ | async as turbos;">
        <ng-container *ngIf="turbos.length > 0; else noTurbos;">
          <div
            *ngFor="let template of turbos"
            mat-menu-item
            (click)="_openTurboTemplateDialog(template)"
          >
            <app-title-with-chips [title]="template.value.name" class="flex-1"></app-title-with-chips>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noTurbos>
        <div 
          *ngIf="_hasTurboTemplateCandidates$ | async as hasCandidates; else noCandidates;"
          mat-menu-item
          (click)="_openTurboTemplatePicker()"
          matTooltip="For templates to appear in this list, they need to be selected first. Click to manage turbo templates..."
          appMatTooltipNoninteractive
        >
          No templates selected
        </div>

        <ng-template #noCandidates>
          <div 
            mat-menu-item
            matTooltip="Only fully-configured templates with assigned devices can be used as turbo templates. Click to go to wizard..."
            appMatTooltipNoninteractive
            (click)="_openWizard()"
          >
            No templates available
          </div>
        </ng-template>
      </ng-template>

      <div
        mat-menu-item
        class="flex justify-center"
        (click)="_openTurboTemplatePicker()"
      >
        <span class="turbo-template__edit-favorites-color">
          <small>Manage Turbo templates</small>
        </span>
      </div>
    </app-split-button>
  </div>

  <h6>
    {{ currentDate | date: 'HH:mm:ss' }}
  </h6>

  <app-notification [currentUserId]="currentUserId"></app-notification>

  <div>
    <button class="avatar-btn" mat-icon-button [matMenuTriggerFor]="avatarMenu" 
      (menuOpened)="onMenuOpen()" (menuClosed)="onMenuClose()" [ngClass]="{'active': isMenuOpen}">
      <!-- should be replaced by avatar later -->
      {{ userInitials }}
    </button>
    <mat-menu #avatarMenu class="avatar-menu">
      <div class="flex flex-row gap-2 p-2">
        <div class="avatar-btn rounded-full flex border border-gray-50">
          <span class="m-auto">
            {{ userInitials }}
          </span>
        </div>

        <div class="flex flex-col gap-y-1 avatar-menu-user-info">
          <span class="font-bold text-base text-gray-800 truncate">{{currentUser}}</span>
          <span class="font-normal text-xs text-gray-500 truncate">&#64;{{currentUsername}}</span>
        </div>
      </div>
      
      <a
        mat-menu-item
        *ngIf="isAdmin"
        [routerLink]="['/admin/users']">
        <ion-icon name="settings-outline"></ion-icon>
        Admin settings
      </a>

      <a
        mat-menu-item
        *ngIf="isTeamLeader && !isAdmin"
        [routerLink]="['/team-management/']">
        <ion-icon name="settings-outline"></ion-icon>
        Team management
      </a>

      <mat-divider> </mat-divider>

      <div
        mat-menu-item
        (click)="_showExperimentImport()"
      >
        <ion-icon name="download-outline"></ion-icon>
        Import experiment
      </div>

      <a
        mat-menu-item
        href="/assets/DOTS-Software-Userguide.pdf"
        target="_blank">
        <ion-icon name="help-outline"></ion-icon>
        User guide
      </a>


      <mat-divider> </mat-divider>

      <button
        mat-menu-item
        (click)="logout()">
        <ion-icon name="exit-outline"></ion-icon>
        Log out
      </button>

      <div class="p-2" (click)="$event.stopPropagation()">
        <div class="text-right text-xs font-normal text-gray-500">
          <small>Version: {{version}}</small>
        </div>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>

<div class="update-block" *ngIf="showNewSoftwareUpdateInfo">
  <h4>New software version available!</h4>
  <div class="update-body">
    <img src="../../../../assets/svg/software-update.svg"/>
    <span>
      Check your email and find out more about the new version.
      You can also talk to your admin or reach out to
      <a href="mailto:service@scientificbio.com">{{"service@scientificbio.com"}}</a>!
    </span>
  </div>
  <div class="update-btn-group">
    <button
      (click)="skipUpdate()"
      class="btn-margin-right"
      mat-button>Skip update</button>
    <button
      (click)="remindUpdateLater()"
      class="btn-margin-right"
      mat-stroked-button
      color="accent">Remind me later</button>
    <button
      (click)="openChangeLogs()"
      mat-raised-button
      color="primary">
      See what's new
    </button>
  </div>
</div>
