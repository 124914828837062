import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { IDevice, ITask } from 'src/app/model/thingie/task';
import { IThingie } from 'src/app/model/thingie/thingie';
import { resolveShortcut } from '../device-shortcut';

export type AssignedDeviceInfo = {

  /**
   * The address of the device
   */
  address: string;

  /**
   * The index of the process in its thingie
   *
   * If null, the process position was not provided
   * (e.g. when checking the device info for a task
   * and the index was not provided)
   */
  processIndex: number | null;

  /**
   * The index of the task in its process
   *
   * If null, the task position was not provided
   * (e.g. when checking the device info for a task
   * and the index was not provided)
   */
  taskIndex: number | null;

  /**
   * The key of the input in the task
   */
  inputKey: string;

  /**
   * The check-resulting status of the device
   */
  status: 'deviceBlocked' | 'wrongDevice' | 'notReady' | 'free';

  /**
   * The type of the device
   */
  type: IPojoDevice['devType'];
};


export function getDeviceConfigurationInfoForThingies(
  thingies: IThingie[],
  deviceList: IPojoDevice[]
): AssignedDeviceInfo[] {
  const devices: AssignedDeviceInfo[] = [];

  for (const thingie of thingies) {
    thingie.processes.forEach((process, processIndex) => {
      //
      process.tasks.forEach((task, taskIndex) => {
        //
        const deviceInfos = getDeviceConfigurationInfoForTask(
          task,
          deviceList,
          processIndex,
          taskIndex
        );

        devices.push(...deviceInfos);
      }); // iterate tasks
    }); // iterate processes
  } // iterate thingies

  return devices;
}

export function getDeviceConfigurationInfoForTask(
  task: ITask,
  deviceList: IPojoDevice[],
  processIndex?: number,
  taskIndex?: number,
) {
  const devices: AssignedDeviceInfo[] = [];

  Object.entries(task.inputs).forEach(([inputKey, input]) => {
    if (input.source !== 'device') {
      return;
    }

    const deviceInput = input as IDevice;

    const deviceInfo: AssignedDeviceInfo = {
      address: deviceInput.deviceAddress ?? 'unknown',
      inputKey,
      processIndex: processIndex ?? null,
      status: 'wrongDevice',
      taskIndex: taskIndex ?? null,
      type: 'unknown'
    };

    // Find device for the device input
    const device = deviceList.find(d => d.address === deviceInput.deviceAddress);
    if (!device) {
      deviceInfo.status = 'wrongDevice';
    } else {
      deviceInfo.type = device.devType;

      if (device.deviceInputTypes.includes(deviceInput.deviceType)) {
        if (device.blockedBy.length > 0) {
          deviceInfo.status = 'deviceBlocked';
        } else {
          if (!resolveShortcut(device, 'ready', false)) {
            deviceInfo.status = 'notReady';
          } else {
            deviceInfo.status = 'free';
          }
        }
      }
    }

    devices.push(deviceInfo);
  });

  return devices;
}
