import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CalibrationFilePostProcessing } from 'src/app/model/backend';
import { ICalibrationFile } from 'src/app/model/calibration-file';
import { IGraphConfig } from 'src/app/model/graph-configs/graph-config';
import { IApplicationTemplate, IUserTemplate } from 'src/app/model/thingie/templates';
import { IThingie } from 'src/app/model/thingie/thingie';
import { IUserData } from 'src/app/model/userdata';
import { UserDataServiceImpl } from './implementation/user-data.service';

export type IncludeTemplates = {

  /**
   * Include disabled templates
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Include templates from previous versions
   *
   * @default false
   */
  fromPreviousVersion?: boolean;
};


export type UserDataTypeMapping = {
  GRAPHCONFIG: IGraphConfig;
  GRAPHCONFIG_GROUP: IGraphConfig;
  PH_CALIBRATION: ICalibrationFile;
  DO_CALIBRATION: ICalibrationFile;
  CALIBRATION_POSTPROCESSING: CalibrationFilePostProcessing;
  APPLICATION_TEMPLATE: IApplicationTemplate;
  LIS_P_VALUES: IUserTemplate;
  LIS_PROFILE: IUserTemplate;
  TURBO_TEMPLATE: IApplicationTemplate;
  VERSION_UPDATE: { timestamp: string; skippedVersion: string };
  SHAKER_PROFILE: IUserTemplate;
};

@Injectable({
  providedIn: 'root',
  useClass: UserDataServiceImpl
})
export abstract class UserDataService {
  public static readonly customTemplateIndicator = '1ca570a6-f8eb-4be5-90e9-f20db96d4edd';

  constructor() { }

  abstract getAllUserData<T extends keyof UserDataTypeMapping>(type: T): Observable<IUserData<UserDataTypeMapping[T]>[]>;
  abstract getAllThingieUserData<T extends keyof UserDataTypeMapping>(
    type: T,
    userId: string,
    thingieId: string
  ): Observable<IUserData<UserDataTypeMapping[T]>[]>;
  abstract getUserData<T extends keyof UserDataTypeMapping>(
    type: T,
    userId: string,
    id: string
  ): Observable<IUserData<UserDataTypeMapping[T]>>;
  abstract createUserData<T extends keyof UserDataTypeMapping>(
    type: T,
    userId: string,
    data: UserDataTypeMapping[T],
    visibility?: IUserData<any>['visibility']
  ): Observable<IUserData<UserDataTypeMapping[T]>>;
  abstract updateUserData<T extends keyof UserDataTypeMapping>(
    type: T,
    userId: string,
    id: string,
    data: UserDataTypeMapping[T]
  ): Observable<IUserData<UserDataTypeMapping[T]>>;
  abstract deleteUserData(type: keyof UserDataTypeMapping, userId: string, id: string): Observable<void>;
  abstract getUserApplicationTemplates(): Promise<IApplicationTemplate[]>;

  /**
   * Get all user templates (user & meta)
   *
   * @param includeDisabled (optional) Include disabled templates
   * @returns Promise, containing a list of user {@link IUserTemplate}
   */
  abstract getAllTemplates(include?: IncludeTemplates): Promise<IUserTemplate[]>;
  abstract getTemplate(userTemplateName: string): Observable<IUserTemplate>;
  abstract saveTemplate(userTemplate: IUserTemplate): Observable<IUserTemplate>;
  abstract deleteTemplate(userTemplateName: string): Observable<void>;

  abstract createFakeTemplate(thingie: IThingie): IApplicationTemplate;

  abstract createUserApplicationTemplate(
    userId: string, thingies: IThingie[],
    templateThingie: IThingie,
    templateName: string
  ): Promise<IUserData<IApplicationTemplate>>;
}
