import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import {
  catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap
} from 'rxjs/operators';
import { ExperimentImportDialogService } from 'src/app/components/dialogs/thingie-import-dialog/thingie-import-dialog.component';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { SearchTagsEvent } from 'src/app/model/events/thingie-detail-page-events';
import { SearchResultEntry } from 'src/app/model/search/search-result-entry';
import { IApplicationTemplate } from 'src/app/model/thingie/templates';
import { IThingie } from 'src/app/model/thingie/thingie';
import { IUserData } from 'src/app/model/userdata';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DeviceService } from 'src/app/services/device/device.service';
import { EventService } from 'src/app/services/events/event.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { SearchService } from 'src/app/services/search/search.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { ThingieService } from 'src/app/services/thingie/thingie-service.service';
import { UpdatesService } from 'src/app/services/updates/updates.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { VersionUpdateCheckService } from 'src/app/services/version-update-check/version-update-check.service';
import { getLastSearch, updateLastSearch } from 'src/app/utility/search-helper';
import { SearchResult2EntryMapper } from 'src/app/utility/search-result-2-entry-mapper';
import { canBeTurboTemplate } from 'src/app/utility/templates/can-be-turbo-template';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { ChippedAutocompleteItem } from '../chipped-autocomplete/chipped-autocomplete.component';
import {
  TurboTemplatePickerDialogService
} from '../dialogs/turbo-templates/turbo-template-picker-dialog/turbo-template-picker-dialog.component';
import {
  TurboTemplateStartDialogService
} from '../dialogs/turbo-templates/turbo-template-start-dialog/turbo-template-start-dialog.component';
import { UpdateAlertComponent } from '../dialogs/update-alert/update-alert.component';


type AppTemplateUserData = IUserData<IApplicationTemplate>;

interface IMenuFilter {
  checked: boolean;
  disabled: boolean;
  label: string;
  queryKey: string;
  key: string;
}

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  viewTitle = '';

  get isAdminView(): boolean {
    return this.router.url.includes('/admin');
  }

  @ViewChild('searchInputContainer')
  private searchInputContainer!: MatFormField;
  private searchContainer!: HTMLDivElement;
  private searchInputSubscription!: Subscription;

  searchResultsVisible = false;
  searchFewKeywordsIllusion = false;
  searchResults: SearchResultEntry[] = [];
  devicesSearchResults: SearchResultEntry[] = [];
  objectsSearchResults: SearchResultEntry[] = [];
  groupByExperimentMap =  new Map();

  searchResultsDisplayLoading = true;
  searchFailed = false;

  @ViewChild(CdkConnectedOverlay) connectedOverlay: CdkConnectedOverlay | undefined;

  private clockUpdateTimerHandle: any;
  currentDate: Date = new Date();

  menuBarSearchInput = new FormControl('');

  isAdmin = false;
  isTeamLeader = false;

  version?: string;

  currentUsername!: string;
  currentUser!: string;
  currentUserId!: string;
  userInitials!: string | undefined;

  isCheckingForSoftwareUpdate = false;

  showNewSoftwareUpdateInfo = false;

  // Recent tags
  lastSearchIsRunning = false;
  recentTagsAutoCompleteList: ChippedAutocompleteItem<string>[] = [];
  selectedRecentTagsFilter: string[] = [];

  projectFilter = 'all';
  lastSearch: string | null = '';
  cacheLastSearch: string | null = '';
  sorts: IMenuFilter[] = [
    {
      checked: true,
      disabled: true,
      label: 'Newest',
      key: 'newest',
      queryKey: 'sort',
    },
    {
      checked: false,
      disabled: false,
      key: 'alphabetical',
      label: 'Alphabetical',
      queryKey: 'sort',
    },
    {
      checked: false,
      disabled: false,
      label: 'Relevance',
      key: 'relevance',
      queryKey: 'sort',
    }
  ];
  typeFilters:  IMenuFilter[] = [
    {
      checked: false,
      disabled: false,
      label: 'Objects',
      key: 'objects',
      queryKey: 'filterByType',
    }, {
      checked: false,
      disabled: false,
      label: 'Devices',
      key: 'devices',
      queryKey: 'filterByType',
    }
  ];
  objectsByStatusFilters: IMenuFilter[] = [
    {
      checked: false,
      disabled: false,
      label: 'Planned',
      key: 'planned',
      queryKey: 'filterObjectsByStatus',
    },
    {
      checked: false,
      disabled: false,
      label: 'Running',
      key: 'running',
      queryKey: 'filterObjectsByStatus',
    },
    {
      checked: false,
      disabled: false,
      label: 'Finished',
      key: 'finished',
      queryKey: 'filterObjectsByStatus',
    },
    {
      checked: false,
      disabled: false,
      label: 'Archived',
      key: 'archived',
      queryKey: 'filterObjectsByStatus',
    }
  ];
  devicesByStatusFilters: IMenuFilter[] = [
    {
      checked: false,
      disabled: false,
      label: 'Free',
      key: 'free',
      queryKey: 'filterDevicesByStatus',
    },
    {
      checked: false,
      disabled: false,
      label: 'Disconnected',
      key: 'disconnected',
      queryKey: 'filterDevicesByStatus',
    },
    {
      checked: false,
      disabled: false,
      label: 'In use',
      key: 'inUse',
      queryKey: 'filterDevicesByStatus',
    }
  ];
  private doSearch$ = new Subject<string>();

  private subscriptions = new SubSink();

  private turboTemplateRefreshTrigger$ = new Subject<void>();

  public handleClick(event: MouseEvent): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      if (element.className === 'routerLink') {
        // Prevent notification expansion when not intended
        event.stopPropagation();

        if (!(event.ctrlKey || event.metaKey)) {
          event.preventDefault();
          // Don't access elemet.href, because this would be the resolved
          // route (e.g. https://localhost...)
          const route = element.getAttribute('href');
          if (route) {
            void this.router.navigate([route]);
          }
        }
      }
    }
  }

  _turboTemplates$?: Observable<AppTemplateUserData[]>;

  /**
   * Observable that emits `true` if there are potential turbo templates,
   * `false` otherwise.
   */
  _hasTurboTemplateCandidates$?: Observable<boolean>;

  // Observe the the first level items in our search list result
  @ViewChildren('searchResultItem', { read: ElementRef })
  itemElements!: QueryList<ElementRef>;

  // using subject to avoid multiple event handling at the same time
  private keyboard$ = new Subject<string>();

  // keep tracking of the focused item
  currentFocusedIndex = 0;

  // this help up to ignore the keyboard event when any dropdown or any overlay is opened
  isKeyboardHandlerEnabled = true;


  // to add/remove css-class to the user-menu
  isMenuOpen = false;

  onMenuOpen() {
    this.isMenuOpen = true;
  }

  onMenuClose() {
    this.isMenuOpen = false;
  }

  constructor(
    private auth: AuthService,
    private deviceService: DeviceService,
    private dialogService: MatDialog,
    private eventService: EventService,
    private experimentImport: ExperimentImportDialogService,
    private keycloak: KeycloakService,
    private log: LogService,
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private thingieService: ThingieService,
    private turboTemplateDialog: TurboTemplateStartDialogService,
    private turboTemplatePicker: TurboTemplatePickerDialogService,
    private updateService: UpdatesService,
    private userDataService: UserDataService,
    private userService: UserService,
    private templateService: TemplateService,
    private versionUpdateCheck: VersionUpdateCheckService
  ) {
  }

  async ngOnInit() {
    this.setUpClock();
    this.handleClickEvents();
    void this.initializeProfileCard();
    this.updateCurrentUser();
    this.initializeTurboTemplates();
    this.showNewSoftwareUpdateInfo = await this.versionUpdateCheck.runSoftwareUpdateCheck();
  }

  ngAfterViewInit() {
    this.setUpEventHandlers();
  }

  ngOnDestroy(): void {
    this.stopHandlingClickEvents();
    clearInterval(this.clockUpdateTimerHandle);

    if (this.searchInputSubscription !== undefined) {
      this.searchInputSubscription.unsubscribe();
    }
    this.subscriptions.unsubscribe();
  }

  logout() {
    void this.keycloak.logout();
  }

  async initializeSearch() {
    // fetch the all the tags from server side
    this.subscriptions.sink = this.thingieService.getTags().subscribe(tags => {
      this.recentTagsAutoCompleteList = tags.map(t =>  ({ itemDisplayText: t, value: t }));
    });

    // Make the search results container visible
    this.searchResultsVisible = true;

    if (this.searchResults.length === 0) {
      this.menuBarSearchInput.setValue('');
    } else {
      this.menuBarSearchInput.setValue(this.menuBarSearchInput.value);
    }

    this.doSearch();
  }

  private updateCurrentUser() {
    void this.userService.getCurrentUser().then(user => {
      // displayName was added to IUser interface
      this.currentUsername = user.username;
      this.currentUser = user.displayName;
      this.currentUserId = user._id;
      const splitName = this.currentUser.split(' ');
      const lastName = splitName[1]?.charAt(0)?.toUpperCase();
      if (lastName) {
        this.userInitials = splitName.shift()?.charAt(0)
          .toUpperCase() + lastName;
      } else {
        this.userInitials = splitName.shift()?.charAt(0)
          .toUpperCase();
      }
    });
  }

  private async initializeProfileCard() {
    this.subscriptions.sink = this.thingieService.getVersion().subscribe(versionText => {
      this.version = versionText;
    });

    this.isAdmin = await this.auth.isAdmin();
    this.isTeamLeader = await this.auth.isTeamLeader();
  }

  async checkForUpdate() {
    if (this.isCheckingForSoftwareUpdate) {
      return;
    }

    this.isCheckingForSoftwareUpdate = true;

    try {
      const updateStatus = await this.updateService.checkForSoftwareUpdate();
      this.dialogService.open(
        UpdateAlertComponent,
        {
          data: updateStatus
        }
      );
    } finally {
      this.isCheckingForSoftwareUpdate = false;
    }
  }


  private onCurrentDateInterval() {
    this.currentDate = new Date();
  }

  private setUpEventHandlers() {
    //// Add search term change handling
    this.cacheLastSearch = this.lastSearch;

    // having a debounceTime to avoid same time events
    this.subscriptions.sink = this.keyboard$.pipe(
      filter(() => this.isKeyboardHandlerEnabled === true),
      debounceTime(10)
    )
      .subscribe((key) => {
        switch (key) {
          case 'ArrowUp':
          case 'ArrowLeft':
            this.currentFocusedIndex--;
            break;
          case 'ArrowDown':
          case 'ArrowRight':
            this.currentFocusedIndex++;
            break;
        }

        // check if the pointer is at the end, then move it to the first
        // location if user likes to continue going down
        if (this.currentFocusedIndex >= this.itemElements.toArray().length) {
          this.currentFocusedIndex = 0;
        }

        // check if the pointer is on the top, then move it to the bottom if
        // user likes to continue going up
        if (this.currentFocusedIndex < 0) {
          this.currentFocusedIndex = this.itemElements.toArray().length - 1;
        }


        if (key === 'Enter') {
          // remove last clicked by mouse to avoid any conflicts
          this.itemElements.toArray().forEach((el, index) => {
            if (this.currentFocusedIndex !== index) {
              const tempEl = el.nativeElement.querySelector('.mat-expansion-panel-header');
              if (tempEl?.classList.contains('cdk-focused')) {
                // if you call blur() only, it makes the last focused (click by mouse) accordion toggle.
                // then we need to apply click() once to cancel that behavior
                tempEl.blur();
                tempEl.click();
              }
            }
          });

          // click the one that is highlighted
          const focusedElement  = this.itemElements.toArray()[this.currentFocusedIndex];
          const header = focusedElement.nativeElement.querySelector('.mat-expansion-panel-header');
          if (header) {
            header.click();
          } else {
            const anchor = focusedElement.nativeElement.querySelector('.list-item-anchor');
            anchor.click();
          }
        }
      });



    this.subscriptions.sink =  this.doSearch$.pipe(
      tap(() => {
        this.searchFailed = false;
      }),
      debounceTime(DefaultConfig.general.userInputDefaultDebounce_Milliseconds),
      switchMap((searchQuery) => {
        const sq = searchQuery.split(' ').filter(word => word !== '')
          .join(' ');
        return this.performSearch(sq);
      }),
      catchError((e: unknown) => {
        // resetting the search value to avid the UI from being freezed with error message
        if (this.lastSearch !== this.cacheLastSearch) {
          updateLastSearch(this.currentUserId, this.cacheLastSearch!);
        } else {
          updateLastSearch(this.currentUserId, '');
        }

        this.log.error('Failed to perform search', e);

        return of(undefined);
      }),
    ).subscribe((searchResults: undefined | SearchResultEntry[]) => {
      this.applySearchResult(searchResults);
    });

    this.searchInputSubscription = this.menuBarSearchInput
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(async (s) => s?.trim()),
        tap((s) => {
          this.searchResultsVisible = true;

          // Show skeleton elements while loading
          this.searchResultsDisplayLoading = true;
          this.searchFailed = false;

          if (s!.length < 1) {
            this.repeatLastSearch();
          } else if (s!.length < 3) {
            this.searchFewKeywordsIllusion = true;
          } else {
            this.searchFewKeywordsIllusion = false;
          }
        }),
        filter((s) => s!.length > 2),
        tap(() => this.doSearch())
      )
      .subscribe();


    //// Handle tag-search event
    this.subscriptions.sink = this.eventService.subscribe(
      SearchTagsEvent,
      (event) => {
        this.menuBarSearchInput.setValue(event.searchItem);
      }
    );
  }

  private async performSearch(
    searchString: string
  ) {
    // save the search query
    updateLastSearch(this.currentUserId, searchString);
    this.lastSearch = searchString;

    // apply sort
    const selectedSort = this.sorts.find(s => s.checked === true);
    const sort = selectedSort !== undefined ? selectedSort.key : '';

    // apply type filters
    const _typeFilters: string[] = [];
    this.typeFilters.forEach(t => {
      if (t.checked === true) {
        _typeFilters.push(t.key.toLowerCase());
      }
    });


    // apply the statuses filter related to objects
    let _objectsByStatusFilters: string[] = [];
    this.objectsByStatusFilters.forEach(t => {
      if (t.checked === true) {
        _objectsByStatusFilters.push(t.key.toLowerCase());
      }
    });

    // check if all of them are selected, which means no filter
    if (this.objectsByStatusFilters.length === _objectsByStatusFilters.length) {
      _objectsByStatusFilters = [];
    }

    // apply the statuses filter related to devices
    const _devicesByStatusFilters: string[] = [];
    this.devicesByStatusFilters.forEach(t => {
      if (t.checked === true) {
        _devicesByStatusFilters.push(t.key.toLowerCase());
      }
    });

    // limiting the search to 7 and 20
    // 20: when user changes the filters
    // 10: when user reopen the dialog and apply as default search query
    // Note: 10 is the minimum limitation
    const searchLimit = this.lastSearchIsRunning ? 10 : 20;

    // reset the search limit
    this.lastSearchIsRunning = false;

    return SearchResult2EntryMapper.map(
      await this.searchService.search({
        project: this.projectFilter.toLowerCase(),
        term: searchString,
        sort,
        types: _typeFilters,
        tags: this.selectedRecentTagsFilter,
        objectsStatuses: _objectsByStatusFilters,
        devicesStatuses: _devicesByStatusFilters,
        limit: searchLimit
      })
    );
  }

  private applySearchResult(searchResults: undefined | SearchResultEntry[]) {
    this.searchResultsDisplayLoading = false;

    // after fetching the new result we need to reset the point
    this.currentFocusedIndex = 0;
    this.isKeyboardHandlerEnabled = true;

    if (searchResults) {
      this.searchResults = searchResults;
    } else {
      this.searchResults = [];
      this.searchFailed = true;
    }

    this.devicesSearchResults = this.searchResults.filter(
      (s) => s.item.meta.type.toLocaleLowerCase() === 'device'
    );
    this.objectsSearchResults = this.searchResults.filter(
      (s) =>  s.item.meta.type.toLocaleLowerCase() === 'thingie'
    );

    this.groupByExperimentMap.clear();
    this.objectsSearchResults.forEach( (t) => {
      const collection = this.groupByExperimentMap.get(t.item.baseName);
      if (!collection) {
        this.groupByExperimentMap.set(t.item.baseName, [t]);
      } else {
        collection.push(t);
      }
    });
  }

  private setUpClock() {
    this.clockUpdateTimerHandle = setInterval(
      () => this.onCurrentDateInterval(),
      DefaultConfig.menuBar.clockUpdateTimerInterval_Milliseconds
    );
  }


  private handleClickEvents() {
    this.handleClick = this.handleClick.bind(this);

    document.addEventListener(
      'click',
      this.handleClick,
      {
        capture: true
      }
    );
  }

  private stopHandlingClickEvents() {
    document.removeEventListener('click', this.handleClick);
  }


  clearMenuSearchBarInput() {
    this.menuBarSearchInput.setValue('');
  }

  _openWizard() {
    void this.router.navigate(['/thingies/create'], { queryParams: { mode: 'CREATE' } });
  }

  private initializeTurboTemplates() {
    // Set up the list of turbo templates which will be shown in the
    // turbo-template dropdown
    const turboRefresh$ = this.turboTemplateRefreshTrigger$.pipe(
      startWith(undefined),
      switchMap(() => this.templateService.loadTurboTemplates()),
    );

    this._turboTemplates$ = turboRefresh$;

    const allTemplates$ = this.userDataService
      .getAllUserData('APPLICATION_TEMPLATE')
      /* .pipe(first()) */;

    const allDevices$ = this.deviceService.getDevices()/* .pipe(first()) */;

    // If there are no turbos selected, but potential turbo templates exist
    // Show the information in the dropdown
    this._hasTurboTemplateCandidates$ = this._turboTemplates$.pipe(
      filter(turbos => turbos.length === 0),
      switchMap(() => combineLatest([allTemplates$, allDevices$])),
      map(([templates, devices]) => templates.some(t => canBeTurboTemplate(t, devices))),
    );
  }

  _openTurboTemplateDialog(turboTemplate: AppTemplateUserData) {
    this.turboTemplateDialog.open({ turboTemplate });
  }

  _openTurboTemplatePicker() {
    this.subscriptions.sink = this.turboTemplatePicker.open()
      .afterClosed()
      .pipe(
        tap(() => this.turboTemplateRefreshTrigger$.next(undefined))
      )
      .subscribe();
  }


  openChangeLogs() {
    this.showNewSoftwareUpdateInfo = this.versionUpdateCheck.openChangeLogs();
  }

  async skipUpdate() {
    this.showNewSoftwareUpdateInfo = await this.versionUpdateCheck.skipUpdate();
  }

  remindUpdateLater() {
    this.showNewSoftwareUpdateInfo = this.versionUpdateCheck.remindUpdateLater();
  }

  onRecentTagsSelected($event: ChippedAutocompleteItem<string>[]) {
    this.selectedRecentTagsFilter = $event.map(chipped => chipped.itemDisplayText);
    this.doSearch();
  }

  private prepareTags(thingies: IThingie[]): ChippedAutocompleteItem<string>[] {
    return [...new Set(thingies.flatMap(t => t.tags))].map(
      tag => ({ itemDisplayText: tag, value: tag })
    );
  }

  projectChangeCallbackFunction(projectFilter: string) {
    this.projectFilter = projectFilter;
    this.doSearch();
  }

  isDeviceInUse = (device: IPojoDevice): boolean => device.blockedBy.length ? true : false;

  castToIPojoDevice = (device: IPojoDevice): IPojoDevice => device;


  castToIThingie = (thingie: IThingie): IThingie => thingie;


  getThingiesByIds = (thingie: IThingie): Observable<IThingie[]> => {
    if (thingie.relations.length === 0) {
      return of([thingie]);
    }

    const ids = (thingie.relations.map(r => r.relatedThingie));
    if (thingie._id !== undefined) {
      ids.push(thingie._id);
    }
    return combineLatest(ids.map(id => this.thingieService.getThingieById(id)));
  };

  sortChange(event: MatRadioChange) {
    this.sorts.map(s => {
      s.checked = s.key === event.value ? true : false;
      return s;
    });

    this.doSearch();
  }

  typeFilterChange(event: MatLegacyCheckboxChange, value: string) {
    this.typeFilters.map(t => {
      if (t.key === value) {
        t.checked = event.checked;
      }

      return t;
    });

    this.updateFiltersUI();
    this.doSearch();
  }

  private updateFiltersUI() {
    // check if all the filters are checked or not
    const checkedTypeFilters = this.typeFilters.filter(tf => tf.checked === true);
    if (!checkedTypeFilters.length || checkedTypeFilters.length === this.typeFilters.length) {
      // enable all the filters
      this.objectsByStatusFilters.map(item => item.disabled = false);
      this.devicesByStatusFilters.map(item => item.disabled = false);
    } else {
      // disable the device filter if device type is not selected
      if (this.typeFilters.find(t => t.key === 'devices')?.checked === false) {
        this.devicesByStatusFilters.map(item => item.disabled = true);
      }

      // disable the object filter if object type is not selected
      if (this.typeFilters.find(t => t.key === 'objects')?.checked === false) {
        this.objectsByStatusFilters.map(item => item.disabled = true);
      }
    }
  }


  objectsByStatusFiltersChange(event: MatLegacyCheckboxChange, value: string) {
    this.objectsByStatusFilters.map(t => {
      if (t.key === value) {
        t.checked = event.checked;
      }

      return t;
    });

    this.doSearch();
  }

  devicesByStatusFiltersChange(event: MatLegacyCheckboxChange, value: string) {
    this.devicesByStatusFilters.map(t => {
      if (t.key === value) {
        t.checked = event.checked;
      }

      return t;
    });

    this.doSearch();
  }

  private doSearch() {
    if (this.menuBarSearchInput.value?.length !== undefined) {
      if (this.menuBarSearchInput.value?.length > 2) {
        this.doSearch$.next(this.menuBarSearchInput.value ?? '');
      } else if (this.menuBarSearchInput.value?.length < 1) {
        this.repeatLastSearch();
      }
    } else {
      this.repeatLastSearch();
    }
  }

  private repeatLastSearch() {
    // apply the search
    this.lastSearch = getLastSearch(this.currentUserId);

    if (this.lastSearch !== null) {
      this.searchFewKeywordsIllusion = false;
      this.lastSearchIsRunning = true;
      this.doSearch$.next(this.lastSearch);
    } else {
      this.searchFewKeywordsIllusion = true;
      this.searchResultsDisplayLoading = false;
    }
  }

  // Handle the arrow key event
  handleArrowKeyPress(key: string): void {
    this.keyboard$.next(key);
  }

  disableKeyboardHandler(): void {
    this.isKeyboardHandlerEnabled = false;
  }

  enableKeyboardHandler(): void {
    this.isKeyboardHandlerEnabled = true;
  }

  updateFocusedIndex(index: number): void {
    this.currentFocusedIndex = index;
  }

  /**
   * Show the experiment import dialog
   */
  async _showExperimentImport() {
    await this.experimentImport.open();
  }

  dotsLogoClicked() {
    // if we are on the dashboard cards already, refresh the page
    if (this.route.component === DashboardComponent && this.route.snapshot.params.id === 'cards') {
      window.location.reload();
    } else {
      void this.router.navigate(['/dashboard/cards']);
    }
    // return false to prevent navigation
    return false;
  }

  // to detach the search result dialog
  detachSearchCdkOverlay() {
    if (this.connectedOverlay !== undefined) {
      this.connectedOverlay.overlayRef.detach();
    }
  }
}
