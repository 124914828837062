import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DeviceService } from 'src/app/services/device/device.service';


@Pipe({ name: 'deviceNamePipe' })
export class DeviceNamePipe implements PipeTransform {
  constructor(private deviceService: DeviceService) {}
  transform(value: string) {
    return this.deviceService.getDevice(value).pipe(
      take(1),
      map(device => device.name),
    );
  }
}

@Pipe({ name: 'deviceById' })
export class DeviceByIdPipe implements PipeTransform {
  constructor(private deviceService: DeviceService) {}
  transform(value?: string) {
    if (value === undefined || value === '') {
      return EMPTY;
    }
    return this.deviceService.getDevice(value);
  }
}
