import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

interface FileUploadInfo {
  file: File;
  warning?: boolean;
  progress: number;
}

@Component({
  selector: 'app-annotation-file-drag-drop',
  templateUrl: './annotation-file-drag-drop.component.html',
  styleUrls: ['./annotation-file-drag-drop.component.scss']
})
export class AnnotationFileDragDropComponent {

  /**
   * @private
   */
  @ViewChild('fileDropRef')
  fileDropEl!: ElementRef<HTMLInputElement>;

  @Input()
  filesizeLimitMB = 50;

  @Input()
  showFilesizeLimitHint = true;

  @Input()
  fileExtensions: string | undefined;

  /**
   * If to show the changing state of files after
   * being dropped/selected
   */
  @Input()
  showDropState = true;

  /**
   * Emits selected file objects
   */
  @Output()
  uploadedFiles = new EventEmitter<File[]>();




  files: FileUploadInfo[] = [];
  isFileDropped = false;
  isFileLoading = true;
  fileUploadError = false;
  showAllFileList = true;
  allFilesUploading = false;
  fileErrorCount = 0;

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
    this.uploadedFiles.emit(this.files.map(fileInfo => fileInfo.file));
    this.setDashedContainer();
  }

  /**
   * handle file from browsing
   * Todo: change type
   */
  fileBrowseHandler(files: any) {
    this.prepareFilesList(files.target.files);
    this.uploadedFiles.emit(this.files.map(fileInfo => fileInfo.file));
    this.setDashedContainer();

    // Quick fix for being able to drop the same file again
    // after being removed from the list
    // TODO: handle de-duplication before stuff is removed
    // (inside this component only when `showDropState` is true)
    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * after file upload, change container style
   */
  setDashedContainer() {
    if (!this.showDropState) {
      return;
    }

    const dashedContainer = document.getElementById('drag-container');
    if (dashedContainer) {
      dashedContainer.classList.add('dashed-container');
    }
  }

  /**
   * Delete file from files list
   *
   * @param index (File index)
   */
  deleteFile(index: number) {
    // reset file upload error if the file with warning is deleted
    if (this.files[index].warning) {
      this.fileUploadError = false;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    this.isFileDropped = true;
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        if (!this.files[index]) {
          return;
        }
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            // limit file size to 50MB and show warning otherwise
            if (this.files[index].file.size > this.filesizeLimitMB * 1000000) {
              this.files[index].warning = true;
              this.fileUploadError = true;
            }
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
            this.isFileLoading = true;
            this.allFilesUploading = true;
            if (index === this.files.length - 1) {
              this.isFileLoading = false;
              this.allFilesUploading = false;
            }
          }
        }, 5);
        this.fileErrorCount = this.files.filter(file => file.warning).length;
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   *
   * @param files (Files List)
   */
  prepareFilesList(files: File[]) {
    for (const file of files) {
      if (file === undefined) {
        continue;
      }

      // check acceptable extensions
      if (this.fileExtensions !== undefined) {
        const extension = file.name.split('.').pop();
        if (extension === undefined || !this.fileExtensions.includes(extension)) {
          continue;
        }
      }

      const item = {
        file,
        progress: 0,
        warning: false
      };
      this.files.push(item);
    }
    if (files.length > 3) {
      this.showAllFileList = false;
    }
    this.uploadFilesSimulator(0);
  }

  showAllFiles() {
    this.showAllFileList = true;
  }
}
