import { Injectable } from '@angular/core';
import { UpdatesServiceImpl } from './implementation/updates.service-impl';

export interface IUpdateInfo {
  updateAvailable: boolean;
  currentVersion: string;
  newVersion?: string;
}

@Injectable({
  providedIn: 'root',
  useClass: UpdatesServiceImpl
})

export abstract class UpdatesService {
  abstract checkForSoftwareUpdate(): Promise<IUpdateInfo>;
  abstract getRemoteDOTSVersion(): Promise<string>;
}
