import { LocalStorageHelper } from './local-storage-helper';

export const LAST_SEARCH_STORAGE_KEY = 'last';

// read and write as JSON format
export const SEARCH_SETTINGS_STORAGE_KEY = 'search-setting';


export const getLastSearch = (currentUserID: string): string | null => LocalStorageHelper.getItem(SEARCH_SETTINGS_STORAGE_KEY, currentUserID, LAST_SEARCH_STORAGE_KEY);

export const updateLastSearch = (currentUserID: string, value: string) => {
  LocalStorageHelper.updateItem(SEARCH_SETTINGS_STORAGE_KEY, currentUserID, LAST_SEARCH_STORAGE_KEY, value);
};
